import React, {useEffect, useState} from 'react';
import './Footer.css'; // Import your CSS file here

const Footer = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update the current year in state
        const intervalId = setInterval(() => {
            setCurrentYear(new Date().getFullYear());
        }, 1000 * 60); // Update every minute (you can adjust the interval)

        // Clear the interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <footer>
            <p>&copy; {currentYear} Connaught Lodge #361. All rights reserved.</p>
            <p>DISCLAIMER: Connaught Lodge provides this website as a service to the public. Connaught Lodge is not
                responsible for, and expressly disclaims all liability for, damages of any kind arising from use,
                reference to, or reliance on any information contained within the website. Although the website may
                include links providing direct access to other Internet resources, Connaught Lodge is not responsible
                for the accuracy or content of information offered by these sites.</p>
        </footer>
    );
}

export default Footer;
