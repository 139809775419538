import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DocumentViewer.css"; // Add your styling here
import { logInteraction } from "../utils/logInteraction"; // Import the logging function
import Select from "react-select"; // Import react-select

function DocumentViewer({ user }) {
    useEffect(() => {
        logInteraction(
            "page_view",
            { viewport: { width: window.innerWidth, height: window.innerHeight } },
            user
        );
    }, [user]);

    const [documents, setDocuments] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [roles, setRoles] = useState([]);
    const [newRoles, setNewRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [documentCounts, setDocumentCounts] = useState(null); // Holds counts
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [pageSize] = useState(10); // Number of items per page
    const [totalDocuments, setTotalDocuments] = useState(0); // Total number of documents

    const [dropdowns, setDropdowns] = useState({
        constitutions: [],
        countries: [],
        orderCodes: [],
        lodges: [],
        eventTypes: [],
        documentTypes: [],
        fileTypes: [],
        ids: [],
        titles: [],
    });

    const [filterCriteria, setFilterCriteria] = useState({
        id: "",
        title: "",
        constitution: "",
        country: "",
        orderCode: "",
        lodge: "",
        eventType: "",
        documentType: "",
        fileType: "",
        date: "",
        version: "",
        addendum: "",
        author_initials: "",
        doi: "",
        filename: "",
        description: "",
    });

    // ------------------------------------------------------------
    // 1. FETCH DOCUMENT COUNTS
    // ------------------------------------------------------------
    const fetchDocumentCounts = async (filters = {}) => {
        try {
            const response = await axios.get("/api/document-counts", { params: filters });
            setDocumentCounts(response.data.counts);
        } catch (error) {
            console.error("Error fetching document counts:", error);
        }
    };

    useEffect(() => {
        fetchDocumentCounts(); // Load counts without filters on page load
    }, []);

    // ------------------------------------------------------------
    // 2. FETCH & CHANGE DOCUMENT ROLES (ADMIN/ARCHIVIST)
    // ------------------------------------------------------------
    const handleOpenModal = async (docId) => {
        try {
            const response = await axios.get(`/api/document-roles/${docId}`);
            setRoles(response.data.roles);
            setNewRoles(response.data.roles); // Prepopulate with current roles
            setSelectedDoc(docId);
            setShowModal(true);
        } catch (error) {
            console.error("Error fetching roles:", error);
        }
    };

    const handleRoleChange = (role) => {
        setNewRoles((prev) =>
            prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
        );
    };

    const handleSaveRoles = async () => {
        try {
            await axios.post(`/api/document-roles/${selectedDoc}`, { roles: newRoles });
            alert("Roles updated successfully!");
            setShowModal(false);
            handleSearch(); // Refresh document list
        } catch (error) {
            console.error("Error updating roles:", error);
        }
    };

    // ------------------------------------------------------------
    // 3. FETCH DROPDOWN DATA (IDs, Titles, Lodges, etc.)
    // ------------------------------------------------------------
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const [
                    constitutions,
                    countries,
                    orderCodes,
                    lodges,
                    eventTypes,
                    documentTypes,
                    fileTypes,
                    ids,
                    titles,
                ] = await Promise.all([
                    axios.get("/api/constitutions"),
                    axios.get("/api/countries"),
                    axios.get("/api/order-codes"),
                    axios.get("/api/lodges"),
                    axios.get("/api/event-types"),
                    axios.get("/api/document-types"),
                    axios.get("/api/file-types"),
                    axios.get("/api/document-ids"),
                    axios.get("/api/document-titles"),
                ]);

                setDropdowns({
                    constitutions: constitutions.data.map((item) => ({
                        value: item.code,
                        label: item.description,
                    })),
                    countries: countries.data,
                    orderCodes: orderCodes.data,
                    lodges: lodges.data,
                    eventTypes: eventTypes.data,
                    documentTypes: documentTypes.data,
                    fileTypes: fileTypes.data,
                    ids: ids.data,
                    titles: titles.data,
                });
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };

        fetchDropdownData();
    }, []);

    // ------------------------------------------------------------
    // 4. FETCH DOCUMENT ROLES (FOR CURRENT USER)
    // ------------------------------------------------------------
    const fetchDocumentRoles = async () => {
        try {
            const response = await axios.get("/api/document-roles", {
                params: { role: user.role }, // Use user.role directly
            });
            return response.data; // Array of { id, hasAccess }
        } catch (error) {
            console.error("Error fetching document roles:", error);
            return [];
        }
    };

    // ------------------------------------------------------------
    // 5. DELETE DOCUMENT
    // ------------------------------------------------------------
    const handleDeleteDocument = async (docId) => {
        const confirmed = window.confirm(
            "Are you sure you want to delete this document? This action cannot be undone."
        );
        if (!confirmed) return;

        try {
            await axios.delete(`/api/documents/${docId}`);
            alert("Document deleted successfully!");
            handleSearch(); // Refresh after deletion
        } catch (error) {
            console.error("Error deleting document:", error);
            alert("Failed to delete the document. Please try again.");
        }
    };

    // ------------------------------------------------------------
    // 6. HANDLE FILTER CRITERIA & SEARCH
    // ------------------------------------------------------------
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilterCriteria({ ...filterCriteria, [name]: value });
    };

    const handleDropdownChange = (field, value) => {
        setFilterCriteria((prev) => ({ ...prev, [field]: value }));
    };

    const handleSearch = async () => {
        try {
            const offset = (currentPage - 1) * pageSize;
            // fetch documents & roles concurrently
            const [documentsResponse, rolesResponse] = await Promise.all([
                axios.get("/api/documents", {
                    params: {
                        ...filterCriteria,
                        limit: pageSize,
                        offset,
                    },
                }),
                fetchDocumentRoles(),
            ]);

            const documentsWithAccess = documentsResponse.data.documents.map((doc) => {
                const accessData = rolesResponse.find((r) => r.id === doc.id);
                return { ...doc, hasAccess: accessData ? accessData.hasAccess : false };
            });

            setDocuments(documentsWithAccess);
            setTotalDocuments(documentsResponse.data.total_count);

            // fetch updated counts with current filters
            fetchDocumentCounts(filterCriteria);
        } catch (error) {
            console.error("Error fetching documents or roles:", error);
        }
    };

    // For date display
    const convertToSAST = (utcDate) => {
        const date = new Date(utcDate);
        date.setHours(date.getHours() + 2);
        return date.toISOString().split("T")[0];
    };

    // For copying text on hover or click
    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => alert(`Copied to clipboard: ${text}`))
            .catch((error) => {
                console.error("Failed to copy text to clipboard:", error);
            });
    };

    // Pagination triggers
    useEffect(() => {
        handleSearch();
    }, [currentPage, pageSize]);

    useEffect(() => {
        setCurrentPage(1); // Reset to first page on new filter
        handleSearch();
    }, [filterCriteria]);

    const serverPath = "/opt/websites/freemasonry2/express/documents/";

    return (
        <div className="container">
            <h2>Document Viewer</h2>

            {/* Document Counts */}
            <div className="counts-summary">
                {documentCounts ? (
                    <h3>Number of Documents: {documentCounts.total_count}</h3>
                ) : (
                    <p>Loading document counts...</p>
                )}
            </div>

            {/* Filter Form */}
            <div className="filter-form2">
                <div>
                    <label>ID:</label>
                    <Select
                        menuPortalTarget={document.body}
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.ids.map((id) => ({
                            value: id.value,
                            label: id.label,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange("id", selectedOption ? selectedOption.value : "")
                        }
                        value={
                            dropdowns.ids.find((option) => option.value === filterCriteria.id)
                                ? {
                                    value: filterCriteria.id,
                                    label: dropdowns.ids.find((id) => id.value === filterCriteria.id).label,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select ID"
                    />
                </div>
                <div>
                    <label>Title:</label>
                    <Select
                        options={dropdowns.titles.map((title) => ({
                            value: title.value,
                            label: title.label,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "title",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.titles.find((option) => option.value === filterCriteria.title)
                                ? {
                                    value: filterCriteria.title,
                                    label: dropdowns.titles.find(
                                        (t) => t.value === filterCriteria.title
                                    ).label,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Title"
                    />
                </div>
                {/* Constitution */}
                <div>
                    <label>Constitution:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.constitutions}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "constitution",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.constitutions.find(
                                (c) => c.value === filterCriteria.constitution
                            ) || null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Constitution"
                    />
                </div>
                {/* Country */}
                <div>
                    <label>Country:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.countries.map((country) => ({
                            value: country.code,
                            label: country.name,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "country",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.countries.find(
                                (cnt) => cnt.code === filterCriteria.country
                            )
                                ? {
                                    value: filterCriteria.country,
                                    label: dropdowns.countries.find(
                                        (cnt) => cnt.code === filterCriteria.country
                                    ).name,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Country"
                    />
                </div>
                {/* Order Code */}
                <div>
                    <label>Order Code:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.orderCodes.map((oc) => ({
                            value: oc.code,
                            label: oc.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "orderCode",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.orderCodes.find((oc) => oc.code === filterCriteria.orderCode)
                                ? {
                                    value: filterCriteria.orderCode,
                                    label: dropdowns.orderCodes.find(
                                        (o) => o.code === filterCriteria.orderCode
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Order Code"
                    />
                </div>
                {/* Lodge/Entity */}
                <div>
                    <label>Entity:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.lodges.map((lodge) => ({
                            value: lodge.code,
                            label: lodge.name,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "lodge",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.lodges.find((l) => l.code === filterCriteria.lodge)
                                ? {
                                    value: filterCriteria.lodge,
                                    label: dropdowns.lodges.find(
                                        (lg) => lg.code === filterCriteria.lodge
                                    ).name,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Entity"
                        menuPortalTarget={document.body}
                    />
                </div>
                {/* Event Type */}
                <div>
                    <label>Event Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.eventTypes.map((evt) => ({
                            value: evt.code,
                            label: evt.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "eventType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.eventTypes.find((e) => e.code === filterCriteria.eventType)
                                ? {
                                    value: filterCriteria.eventType,
                                    label: dropdowns.eventTypes.find(
                                        (et) => et.code === filterCriteria.eventType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Event Type"
                    />
                </div>
                {/* Document Type */}
                <div>
                    <label>Document Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.documentTypes.map((dt) => ({
                            value: dt.code,
                            label: dt.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "documentType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.documentTypes.find(
                                (d) => d.code === filterCriteria.documentType
                            )
                                ? {
                                    value: filterCriteria.documentType,
                                    label: dropdowns.documentTypes.find(
                                        (dt) => dt.code === filterCriteria.documentType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Document Type"
                    />
                </div>
                {/* File Type */}
                <div>
                    <label>File Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.fileTypes.map((ft) => ({
                            value: ft.code,
                            label: ft.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "fileType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.fileTypes.find((f) => f.code === filterCriteria.fileType)
                                ? {
                                    value: filterCriteria.fileType,
                                    label: dropdowns.fileTypes.find(
                                        (ft) => ft.code === filterCriteria.fileType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select File Type"
                    />
                </div>
                {/* Description */}
                <div>
                    <label>Description:</label>
                    <input
                        type="text"
                        name="description"
                        value={filterCriteria.description}
                        onChange={handleInputChange}
                        placeholder="Search by description"
                    />
                </div>
                {/* Date */}
                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={filterCriteria.date}
                        onChange={handleInputChange}
                    />
                </div>
                {/* Version */}
                <div>
                    <label>Version:</label>
                    <input
                        type="text"
                        name="version"
                        value={filterCriteria.version}
                        onChange={handleInputChange}
                        placeholder="Version"
                    />
                </div>
                {/* Addendum */}
                <div>
                    <label>Addendum:</label>
                    <input
                        type="text"
                        name="addendum"
                        value={filterCriteria.addendum}
                        onChange={handleInputChange}
                        placeholder="Addendum"
                    />
                </div>
                {/* Author(s) */}
                <div>
                    <label>Author(s):</label>
                    <input
                        type="text"
                        name="author_initials"
                        value={filterCriteria.author_initials}
                        onChange={handleInputChange}
                        placeholder="Author(s)"
                    />
                </div>
                {/* Filename */}
                <div>
                    <label>Filename:</label>
                    <input
                        type="text"
                        name="filename"
                        value={filterCriteria.filename}
                        onChange={handleInputChange}
                        placeholder="Filename"
                    />
                </div>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                >
                    Previous
                </button>
                <span>
          Page {currentPage} of {Math.ceil(totalDocuments / pageSize)}
        </span>
                <button
                    disabled={currentPage === Math.ceil(totalDocuments / pageSize)}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                    Next
                </button>
            </div>

            {/* Document List */}
            <div className="document-list">
                <h3>Documents</h3>

                {documents.length > 0 ? (
                    <div className="scroll-container">
                        <table>
                            <thead>
                            <tr>
                                {/* 1) Download/Actions Column */}
                                <th>Download</th>

                                {/* 2) Show Access column ONLY for Admin/Archivist */}
                                {user.role === "Admin" || user.role === "Archivist" ? (
                                    <th>Access</th>
                                ) : null}

                                {/*/!* 3) Show Delete column ONLY for Admin/Archivist *!/*/}
                                {/*{user.role === "Admin" || user.role === "Archivist" ? (*/}
                                {/*    <th>Delete</th>*/}
                                {/*) : null}*/}

                                {/*{ user.role === "Archivist" ? (*/}
                                {/*    <th>Access</th>*/}
                                {/*) : null}*/}

                                {/* 3) Show Delete column ONLY for Admin/Archivist */}
                                { user.role === "Archivist" ? (
                                    <th>Delete</th>
                                ) : null}

                                {/* 4) The rest of the columns */}
                                <th>ID</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Constitution</th>
                                <th>Country</th>
                                <th>Order Code</th>
                                <th>Lodge</th>
                                <th>Event Type</th>
                                <th>Document Type</th>
                                <th>File Type</th>
                                <th>Date</th>
                                <th>Version</th>
                                <th>Addendum</th>
                                <th>Author(s)</th>
                                <th>DOI</th>
                                <th>Filename</th>
                            </tr>
                            </thead>

                            <tbody>
                            {documents.map((doc) => {
                                const relativePath = doc.file_path.replace(serverPath, "");
                                return (
                                    <tr
                                        key={doc.id}
                                        className={!doc.hasAccess ? "no-access" : ""}
                                    >
                                        {/* Download Column */}
                                        <td>
                                            {doc.hasAccess ? (
                                                <a
                                                    href={`${window.location.origin}/documents/${relativePath}`}
                                                    download
                                                >
                                                    Download
                                                </a>
                                            ) : (
                                                <span style={{ color: "gray" }}>No Access</span>
                                            )}
                                        </td>

                                        {/* Access Column (admin/archivist only) */}
                                        {user.role === "Admin" || user.role === "Archivist" ? (
                                            <td>
                                                <button onClick={() => handleOpenModal(doc.id)}>
                                                    Change Access
                                                </button>
                                            </td>
                                        ) : null}

                                        {/* Delete Column (admin/archivist only) */}
                                        { user.role === "Archivist" ? (
                                            <td>
                                                <button
                                                    onClick={() => handleDeleteDocument(doc.id)}
                                                    style={{
                                                        marginLeft: "10px",
                                                        backgroundColor: "#dc3545",
                                                        color: "white",
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        ) : null}

                                        {/* Remaining columns */}
                                        <td>{doc.id}</td>
                                        <td>{doc.title}</td>
                                        <td>{doc.description}</td>
                                        <td>{doc.constitution}</td>
                                        <td>{doc.country}</td>
                                        <td>{doc.order_code_description}</td>
                                        <td>{doc.lodge}</td>
                                        <td>{doc.event_type_description}</td>
                                        <td>{doc.document_type_description}</td>
                                        <td>{doc.file_type_description}</td>
                                        <td>{convertToSAST(doc.date_created)}</td>
                                        <td>{doc.version}</td>
                                        <td>{doc.addendum}</td>
                                        <td>{doc.author_initials}</td>
                                        <td>{doc.doi}</td>
                                        <td>{doc.filename}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                        {/* Modal for changing roles */}
                        {showModal && (
                            <div className="modal">
                                <div className="modal-content">
                                    <h3>Change Access for Document {selectedDoc}</h3>
                                    <ul>
                                        {["Admin", "Archivist", "Member", "Researcher"].map(
                                            (role) => (
                                                <li key={role}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={newRoles.includes(role)}
                                                            onChange={() => handleRoleChange(role)}
                                                        />
                                                        {role}
                                                    </label>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    <button onClick={handleSaveRoles}>Save</button>
                                    <button onClick={() => setShowModal(false)}>Cancel</button>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>No documents found.</p>
                )}
            </div>
        </div>
    );
}

export default DocumentViewer;
