import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import Select from "react-select";
import Modal from "react-modal";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendarPage.css";

// We'll use enGB for dd/MM/yyyy
import { enGB } from "date-fns/locale";

/**
 * Helper to convert a date/time to SAST by adding +2 hours.
 * If SA ever has DST, you’d need a real timezone library.
 */
function toSAST(dateInput) {
    const dateObj = typeof dateInput === "string" ? new Date(dateInput) : dateInput;
    const offsetMs = 2 * 60 * 60 * 1000;
    return new Date(dateObj.getTime() + offsetMs);
}

Modal.setAppElement("#root");

// Setup react-big-calendar localizer
const locales = { "en-GB": enGB };
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

function CalendarPage({ user, openLoginModal }) {
    // Data from server
    const [lodges, setLodges] = useState([]);
    const [constitutions, setConstitutions] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [buildings, setBuildings] = useState([]); // For building addresses
    const [events, setEvents] = useState([]);

    // CREATE form state
    const [selectedLodge, setSelectedLodge] = useState(null);
    const [selectedConstitution, setSelectedConstitution] = useState(null);
    const [selectedEventType, setSelectedEventType] = useState(null);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [startDateTime, setStartDateTime] = useState("");
    const [endDateTime, setEndDateTime] = useState("");
    const [description, setDescription] = useState("");
    const [isRecurring, setIsRecurring] = useState(false); // monthly recurrence

    // EDIT form + modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingEvent, setEditingEvent] = useState(null);
    const [editLodge, setEditLodge] = useState(null);
    const [editConstitution, setEditConstitution] = useState(null);
    const [editEventType, setEditEventType] = useState(null);
    const [editBuilding, setEditBuilding] = useState(null);
    const [editStartDateTime, setEditStartDateTime] = useState("");
    const [editEndDateTime, setEditEndDateTime] = useState("");
    const [editDescription, setEditDescription] = useState("");

    // FILTER states (year, lodge, eventType)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedLodgeFilters, setSelectedLodgeFilters] = useState([]);
    const [selectedEventTypeFilters, setSelectedEventTypeFilters] = useState([]);

    // Example year options
    const yearOptions = [
        { value: 2024, label: "2024" },
        { value: 2025, label: "2025" },
        { value: 2026, label: "2026" },
    ];

    // 1) Fetch data on mount
    useEffect(() => {
        fetch("/api/v1/lodges")
            .then((res) => res.json())
            .then(setLodges)
            .catch((err) => console.error("Error fetching lodges:", err));

        fetch("/api/v1/constitutions")
            .then((res) => res.json())
            .then(setConstitutions)
            .catch((err) => console.error("Error fetching constitutions:", err));

        fetch("/api/v1/eventtypes")
            .then((res) => res.json())
            .then(setEventTypes)
            .catch((err) => console.error("Error fetching event types:", err));

        fetch("/api/v1/buildings")
            .then((res) => res.json())
            .then(setBuildings)
            .catch((err) => console.error("Error fetching buildings:", err));

        fetch("/api/v1/events")
            .then((res) => res.json())
            .then((data) => {
                if (Array.isArray(data)) setEvents(data);
                else {
                    console.error("Error fetching events:", data.error || data);
                    setEvents([]);
                }
            })
            .catch((err) => console.error("Error fetching events:", err));
    }, []);

    // Reload events helper
    const reloadEvents = () => {
        fetch("/api/v1/events")
            .then((res) => res.json())
            .then((updated) => {
                if (Array.isArray(updated)) setEvents(updated);
                else setEvents([]);
            })
            .catch((err) => console.error("Error fetching events:", err));
    };

    // 2) Transform data -> calendar events
    const transformedEvents = events.map((ev) => {
        const start = new Date(ev.event_start);
        const end = new Date(ev.event_end);

        let addressPart = "";
        if (ev.building_name) {
            addressPart = ` (${ev.building_name}: ${ev.building_street})`;
        }

        return {
            ...ev,
            start,
            end,
            title: `${ev.event_type} - ${ev.description}${addressPart}`,
        };
    });

    // Build dropdown options
    const lodgeOptions = lodges.map((l) => ({ label: l.name, value: l.id }));
    const buildingOptions = buildings.map((b) => ({ label: b.name, value: b.id }));

    // Used event types only
    const usedEventTypeIDs = Array.from(new Set(events.map((ev) => ev.event_type_id)));
    const usedEventTypes = eventTypes.filter((et) => usedEventTypeIDs.includes(et.id));
    const eventTypeFilterOptions = usedEventTypes.map((et) => ({ value: et.id, label: et.description }));

    // 3) Filter events
    const filteredEvents = transformedEvents.filter((ev) => {
        const eventYear = ev.start.getFullYear();
        if (eventYear !== selectedYear) return false;

        if (selectedLodgeFilters.length > 0) {
            const lodgeIDs = selectedLodgeFilters.map((o) => o.value);
            if (!lodgeIDs.includes(ev.lodge_id)) return false;
        }

        if (selectedEventTypeFilters.length > 0) {
            const typeIDs = selectedEventTypeFilters.map((o) => o.value);
            if (!typeIDs.includes(ev.event_type_id)) return false;
        }

        return true;
    });

    // 4) Recurring monthly logic
    function findNthWeekday(dateObj) {
        const dayOfWeek = dateObj.getDay();
        const month = dateObj.getMonth();
        const year = dateObj.getFullYear();
        const dateNum = dateObj.getDate();

        let nth = 0;
        const tempDate = new Date(year, month, 1);
        while (tempDate.getMonth() === month) {
            if (tempDate.getDay() === dayOfWeek) {
                nth++;
                if (tempDate.getDate() === dateNum) break;
            }
            tempDate.setDate(tempDate.getDate() + 1);
        }
        return { dayOfWeek, nth };
    }

    function getNthWeekdayOfMonth(year, month, dayOfWeek, nth) {
        const d = new Date(year, month, 1);
        let count = 0;
        while (d.getMonth() === month) {
            if (d.getDay() === dayOfWeek) {
                count++;
                if (count === nth) return d;
            }
            d.setDate(d.getDate() + 1);
        }
        return null;
    }

    async function createMonthlyEvents(baseEvent, startLocal, endLocal) {
        const { dayOfWeek, nth } = findNthWeekday(startLocal);
        const startMonth = startLocal.getMonth();
        const startYear = startLocal.getFullYear();
        const durationMs = endLocal - startLocal;
        const promises = [];

        for (let i = 0; i < 12; i++) {
            const newMonth = startMonth + i;
            const newYear = startYear + Math.floor(newMonth / 12);
            const monthIndex = newMonth % 12;

            const newDate = getNthWeekdayOfMonth(newYear, monthIndex, dayOfWeek, nth);
            if (!newDate) continue;
            newDate.setHours(startLocal.getHours());
            newDate.setMinutes(startLocal.getMinutes());

            const newEnd = new Date(newDate.getTime() + durationMs);

            const startUTC = toSAST(newDate);
            const endUTC = toSAST(newEnd);

            const monthlyEvent = {
                ...baseEvent,
                event_start: startUTC.toISOString(),
                event_end: endUTC.toISOString(),
            };

            const p = fetch("/api/v1/events", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(monthlyEvent),
            }).then((res) => res.json());
            promises.push(p);
        }
        await Promise.all(promises);
    }

    // 5) CREATE event
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            openLoginModal();
            return;
        }
        if (!selectedLodge || !selectedConstitution || !selectedEventType ||
            !startDateTime || !endDateTime || !description) {
            return alert("Missing required fields.");
        }

        const startLocal = new Date(startDateTime);
        const endLocal = new Date(endDateTime);
        const startUTC = toSAST(startLocal);
        const endUTC = toSAST(endLocal);

        const baseEvent = {
            lodge_id: selectedLodge.value,
            constitution_id: selectedConstitution.value,
            event_type_id: selectedEventType.value,
            building_id: selectedBuilding?.value || null,
            description,
        };

        if (!isRecurring) {
            // single
            const singleEvt = {
                ...baseEvent,
                event_start: startUTC.toISOString(),
                event_end: endUTC.toISOString(),
            };
            try {
                const res = await fetch("/api/v1/events", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(singleEvt),
                });
                const data = await res.json();
                if (data.error) console.error(data.error);
                else {
                    reloadEvents();
                    resetForm();
                }
            } catch (err) {
                console.error("Error creating event:", err);
            }
        } else {
            // monthly repeat
            try {
                await createMonthlyEvents(baseEvent, startLocal, endLocal);
                reloadEvents();
                resetForm();
            } catch (err) {
                console.error("Error creating recurring events:", err);
            }
        }
    };

    function resetForm() {
        setSelectedLodge(null);
        setSelectedConstitution(null);
        setSelectedEventType(null);
        setSelectedBuilding(null);
        setStartDateTime("");
        setEndDateTime("");
        setDescription("");
        setIsRecurring(false);
    }

    // 6) EDIT/DELETE
    const handleEventSelect = (evt) => {
        setEditingEvent(evt);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setEditingEvent(null);
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (editingEvent) {
            const lodgeOpt = lodges.find((l) => l.id === editingEvent.lodge_id);
            const constitutionOpt = constitutions.find((c) => c.id === editingEvent.constitution_id);
            const eventTypeOpt = eventTypes.find((et) => et.id === editingEvent.event_type_id);
            const buildingOpt = buildings.find((b) => b.id === editingEvent.building_id);

            setEditLodge(lodgeOpt ? { value: lodgeOpt.id, label: lodgeOpt.name } : null);
            setEditConstitution(
                constitutionOpt ? { value: constitutionOpt.id, label: constitutionOpt.description } : null
            );
            setEditEventType(
                eventTypeOpt ? { value: eventTypeOpt.id, label: eventTypeOpt.description } : null
            );
            setEditBuilding(buildingOpt ? { value: buildingOpt.id, label: buildingOpt.name } : null);

            const s = new Date(editingEvent.event_start);
            const e = new Date(editingEvent.event_end);
            setEditStartDateTime(format(s, "yyyy-MM-dd'T'HH:mm"));
            setEditEndDateTime(format(e, "yyyy-MM-dd'T'HH:mm"));
            setEditDescription(editingEvent.description);
        }
    }, [editingEvent, lodges, constitutions, eventTypes, buildings]);

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        if (!editingEvent) return;
        if (!user) {
            openLoginModal();
            return;
        }

        const sLocal = new Date(editStartDateTime);
        const eLocal = new Date(editEndDateTime);
        const sUTC = toSAST(sLocal);
        const eUTC = toSAST(eLocal);

        const updatedEvt = {
            lodge_id: editLodge?.value,
            constitution_id: editConstitution?.value,
            event_type_id: editEventType?.value,
            building_id: editBuilding?.value || null,
            event_start: sUTC.toISOString(),
            event_end: eUTC.toISOString(),
            description: editDescription,
        };

        try {
            const res = await fetch(`/api/v1/events/${editingEvent.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updatedEvt),
            });
            const data = await res.json();
            if (data.error) console.error(data.error);
            else {
                reloadEvents();
                closeModal();
            }
        } catch (err) {
            console.error("Error updating event:", err);
        }
    };

    const handleDeleteEvent = async () => {
        if (!editingEvent) return;
        if (!user) {
            openLoginModal();
            return;
        }
        if (!window.confirm("Are you sure you want to delete this event?")) return;

        try {
            const res = await fetch(`/api/v1/events/${editingEvent.id}`, { method: "DELETE" });
            const data = await res.json();
            if (data.error) console.error(data.error);
            else {
                reloadEvents();
                closeModal();
            }
        } catch (err) {
            console.error("Error deleting event:", err);
        }
    };

    /** 7) Export to PDF using jsPDF + jspdf-autotable */
    const handleExportPdf = () => {
        const doc = new jsPDF();

        // Title
        doc.setFontSize(16);
        doc.text("Calendar Agenda Export", 14, 16);

        // Build the table rows from the filtered events
        // Adjust columns to your needs (date/time, event type, lodge, building, etc.)
        const tableBody = filteredEvents.map((ev) => {
            return [
                // e.g. Date, Time, Event Type, Lodge, Building, Description
                format(ev.start, "dd/MM/yyyy"),
                format(ev.start, "HH:mm"),
                format(ev.end, "HH:mm"),
                ev.event_type || "",
                ev.lodge_name || "",
                ev.building_name ? `${ev.building_name}, ${ev.building_street}` : "",
                ev.description || ""
            ];
        });

        doc.autoTable({
            startY: 25,
            head: [["Date", "Start", "End", "Type", "Lodge", "Building", "Description"]],
            body: tableBody,
            theme: "grid",
            headStyles: { fillColor: [100, 100, 100] }, // a gray header
            bodyStyles: { fontSize: 10 },
        });

        // Save the PDF
        doc.save("calendar_agenda.pdf");
    };

    // Calendar date/time format overrides
    const formats = {
        dayFormat: (date, culture, localizer) => localizer.format(date, "dd/MM/yyyy", culture),
        agendaDateFormat: (date, culture, localizer) => localizer.format(date, "dd/MM/yyyy", culture),
        agendaTimeFormat: (date, culture, localizer) => localizer.format(date, "HH:mm", culture),
        agendaHeaderFormat: () => "",
    };

    const eventPropGetter = (ev) => {
        let style = {
            backgroundColor: "#6c757d",
            borderRadius: "5px",
            border: "none",
            color: "#fff",
            padding: "2px 5px",
            fontSize: "0.85rem",
            whiteSpace: "normal",
            wordWrap: "break-word",
            lineHeight: 1.2,
        };
        const lower = (ev.event_type || "").toLowerCase();
        if (lower.includes("regular")) style.backgroundColor = "#76c8dd";
        else if (lower.includes("installation")) style.backgroundColor = "#28a745";
        else if (lower.includes("royal arch")) style.backgroundColor = "#e83e8c";
        else if (lower.includes("knight masons")) style.backgroundColor = "#17a2b8";
        else if (lower.includes("knights templar")) style.backgroundColor = "#6610f2";
        else if (lower.includes("stated communication")) style.backgroundColor = "#fd7e14";
        else if (lower.includes("management")) style.backgroundColor = "#7a7a7a";
        else if (lower.includes("fundraising")) style.backgroundColor = "#ffc107";

        return { style };
    };

    return (
        <div className="calendar-page">
            <h1>Calendar / Events</h1>

            {/* EXPORT PDF BUTTON */}
            <button onClick={handleExportPdf} style={{ marginBottom: "1rem" }}>
                Export to PDF
            </button>

            {/* Filter UI */}
            <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
                {/* If you want a year filter... */}
                {/* LODGE FILTER */}
                <div style={{ flex: 1 }}>
                    <label style={{ display: "block", marginBottom: "0.5rem", fontWeight: "bold" }}>
                        Filter by Lodge(s):
                    </label>
                    <Select
                        options={lodgeOptions}
                        value={selectedLodgeFilters}
                        onChange={(opts) => setSelectedLodgeFilters(opts || [])}
                        placeholder="All Lodges (default)"
                        isMulti
                        isClearable
                    />
                </div>

                {/* EVENT TYPE FILTER */}
                <div style={{ flex: 1 }}>
                    <label style={{ display: "block", marginBottom: "0.5rem", fontWeight: "bold" }}>
                        Filter by Event Type(s):
                    </label>
                    <Select
                        options={eventTypeFilterOptions}
                        value={selectedEventTypeFilters}
                        onChange={(opts) => setSelectedEventTypeFilters(opts || [])}
                        placeholder="All Event Types (default)"
                        isMulti
                        isClearable
                    />
                </div>
            </div>

            {/* Big Calendar */}
            <div className="big-calendar-container">
                <Calendar
                    localizer={localizer}
                    events={filteredEvents}
                    startAccessor="start"
                    endAccessor="end"
                    views={["month", "week", "day", "agenda"]}
                    defaultView="month"
                    length={365}
                    style={{ height: 600, margin: "2rem 0" }}
                    eventPropGetter={eventPropGetter}
                    formats={formats}
                    onSelectEvent={handleEventSelect}
                    selectable={false}
                />
            </div>

            {/* CREATE FORM */}
            <section className="add-event-form">
                <h2>Add a New Event</h2>
                <form onSubmit={handleSubmit}>
                    {/* LODGE */}
                    <div className="form-group">
                        <label>Lodge:</label>
                        <Select
                            options={lodges.map((l) => ({ label: l.name, value: l.id }))}
                            value={selectedLodge}
                            onChange={(opt) => setSelectedLodge(opt)}
                            placeholder="Select Lodge"
                            isClearable
                        />
                    </div>

                    {/* CONSTITUTION */}
                    <div className="form-group">
                        <label>Constitution:</label>
                        <Select
                            options={constitutions.map((c) => ({ label: c.description, value: c.id }))}
                            value={selectedConstitution}
                            onChange={(opt) => setSelectedConstitution(opt)}
                            placeholder="Select Constitution"
                            isClearable
                        />
                    </div>

                    {/* EVENT TYPE */}
                    <div className="form-group">
                        <label>Event Type:</label>
                        <Select
                            options={eventTypes.map((et) => ({ label: et.description, value: et.id }))}
                            value={selectedEventType}
                            onChange={(opt) => setSelectedEventType(opt)}
                            placeholder="Select Event Type"
                            isClearable
                        />
                    </div>

                    {/* BUILDING */}
                    <div className="form-group">
                        <label>Building (Address):</label>
                        <Select
                            options={buildingOptions}
                            value={selectedBuilding}
                            onChange={(opt) => setSelectedBuilding(opt)}
                            placeholder="Select Building"
                            isClearable
                        />
                    </div>

                    {/* DATES */}
                    <div className="form-group">
                        <label htmlFor="start-datetime">Start Date & Time (SAST):</label>
                        <input
                            type="datetime-local"
                            id="start-datetime"
                            value={startDateTime}
                            onChange={(e) => setStartDateTime(e.target.value)}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="end-datetime">End Date & Time (SAST):</label>
                        <input
                            type="datetime-local"
                            id="end-datetime"
                            value={endDateTime}
                            onChange={(e) => setEndDateTime(e.target.value)}
                            required
                        />
                    </div>

                    {/* DESCRIPTION */}
                    <div className="form-group">
                        <label htmlFor="description">Description:</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows="3"
                            required
                        />
                    </div>

                    {/* RECURRING CHECKBOX */}
                    <div className="form-group">
                        <label>
                            <input
                                type="checkbox"
                                checked={isRecurring}
                                onChange={(e) => setIsRecurring(e.target.checked)}
                                style={{ marginRight: "0.5rem" }}
                            />
                            Repeat Monthly (on same nth weekday)?
                        </label>
                    </div>

                    <button type="submit">Add Event</button>
                </form>
            </section>

            {/* EDIT/DELETE Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Event Modal"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <h2>Edit / Delete Event</h2>
                {editingEvent && (
                    <form onSubmit={handleEditSubmit}>
                        {/* LODGE */}
                        <div className="form-group">
                            <label>Lodge:</label>
                            <Select
                                options={lodges.map((l) => ({ label: l.name, value: l.id }))}
                                value={editLodge}
                                onChange={(opt) => setEditLodge(opt)}
                                placeholder="Select Lodge"
                                isClearable
                            />
                        </div>

                        {/* CONSTITUTION */}
                        <div className="form-group">
                            <label>Constitution:</label>
                            <Select
                                options={constitutions.map((c) => ({ label: c.description, value: c.id }))}
                                value={editConstitution}
                                onChange={(opt) => setEditConstitution(opt)}
                                placeholder="Select Constitution"
                                isClearable
                            />
                        </div>

                        {/* EVENT TYPE */}
                        <div className="form-group">
                            <label>Event Type:</label>
                            <Select
                                options={eventTypes.map((et) => ({ label: et.description, value: et.id }))}
                                value={editEventType}
                                onChange={(opt) => setEditEventType(opt)}
                                placeholder="Select Event Type"
                                isClearable
                            />
                        </div>

                        {/* BUILDING */}
                        <div className="form-group">
                            <label>Building (Address):</label>
                            <Select
                                options={buildingOptions}
                                value={editBuilding}
                                onChange={(opt) => setEditBuilding(opt)}
                                placeholder="Select Building"
                                isClearable
                            />
                        </div>

                        {/* TIMES */}
                        <div className="form-group">
                            <label htmlFor="edit-start-datetime">Start Date & Time (SAST):</label>
                            <input
                                type="datetime-local"
                                id="edit-start-datetime"
                                value={editStartDateTime}
                                onChange={(e) => setEditStartDateTime(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="edit-end-datetime">End Date & Time (SAST):</label>
                            <input
                                type="datetime-local"
                                id="edit-end-datetime"
                                value={editEndDateTime}
                                onChange={(e) => setEditEndDateTime(e.target.value)}
                                required
                            />
                        </div>

                        {/* DESCRIPTION */}
                        <div className="form-group">
                            <label htmlFor="edit-description">Description:</label>
                            <textarea
                                id="edit-description"
                                value={editDescription}
                                onChange={(e) => setEditDescription(e.target.value)}
                                rows="3"
                                required
                            />
                        </div>

                        <button type="submit">Save Changes</button>
                        <button
                            type="button"
                            onClick={handleDeleteEvent}
                            className="delete-button"
                        >
                            Delete Event
                        </button>
                        <button type="button" onClick={closeModal}>
                            Cancel
                        </button>
                    </form>
                )}
            </Modal>
        </div>
    );
}

export default CalendarPage;
