import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "./HighlightsModal.css";

function HighlightsModal({ year, onClose }) {
    const [highlights, setHighlights] = useState([]);
    const [filteredHighlights, setFilteredHighlights] = useState([]);
    const [formData, setFormData] = useState({
        date: "",
        title: "",
        description: "",
        category: "",
        media_path: "",
    });
    const [filterYear, setFilterYear] = useState(""); // Filter for a specific year
    const [editingId, setEditingId] = useState(null);
    const [availableYears, setAvailableYears] = useState([]); // Available years for the dropdown

    useEffect(() => {
        fetchAllHighlights(); // Fetch all highlights initially
    }, []);

    // Fetch all highlights
    const fetchAllHighlights = async () => {
        try {
            const res = await axios.get(`/api/yearly-highlights`);
            const dataWithSAST = res.data.map((highlight) => ({
                ...highlight,
                date: toSAST(highlight.date),
            }));
            setHighlights(dataWithSAST);
            setFilteredHighlights(dataWithSAST); // Initially show all highlights
            extractAvailableYears(dataWithSAST); // Extract unique years
        } catch (error) {
            console.error("Error fetching highlights:", error);
        }
    };

    // Extract unique years from highlights
    const extractAvailableYears = (data) => {
        const years = [...new Set(data.map((h) => h.date.split("-")[0]))];
        setAvailableYears(
            years
                .sort((a, b) => b - a)
                .map((year) => ({ value: year, label: year })) // Format for react-select
        );
    };

    // Convert UTC date to SAST and format as YYYY-MM-DD
    const toSAST = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000; // UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = async () => {
        try {
            const payload = {
                ...formData,
                year: year, // Explicitly include the year
                date: formData.date, // Date should be sent as is; backend will handle timezones
            };

            if (editingId) {
                await axios.put(`/api/yearly-highlights/${editingId}`, payload);
            } else {
                await axios.post("/api/yearly-highlights", payload);
            }
            fetchAllHighlights();
            setFormData({ date: "", title: "", description: "", category: "", media_path: "" });
            setEditingId(null);
        } catch (error) {
            console.error("Error saving highlight:", error);
        }
    };

    const handleEdit = (highlight) => {
        setFormData(highlight);
        setEditingId(highlight.id);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/api/yearly-highlights/${id}`);
            fetchAllHighlights(); // Refresh highlights after deletion
        } catch (error) {
            console.error("Error deleting highlight:", error);
        }
    };

    const handleFilterChange = (selectedOption) => {
        const year = selectedOption ? selectedOption.value : "";
        setFilterYear(year);
        if (year) {
            setFilteredHighlights(highlights.filter((h) => h.date.startsWith(year)));
        } else {
            setFilteredHighlights(highlights); // Reset to show all
        }
    };

    return (
        <div className="highlights-modal">
            <div className="highlights-modal-content">
                <h2>Highlights</h2>
                <button className="close-btn" onClick={onClose}>
                    &times;
                </button>

                {/* Filter by Year */}
                <div className="filter-container">
                    <label htmlFor="filterYear">Filter by Year:</label>
                    <Select
                        id="filterYear"
                        value={availableYears.find((option) => option.value === filterYear)}
                        onChange={handleFilterChange}
                        options={[{ value: "", label: "All Years" }, ...availableYears]}
                        isClearable
                        placeholder="Select a year"
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>

                {/* Highlights List */}
                <div className="highlights-list">
                    {filteredHighlights.map((highlight) => (
                        <div key={highlight.id} className="highlight-item">
                            <h3>
                                {highlight.title} ({highlight.date})
                            </h3>
                            <p>{highlight.description}</p>
                            <small>Category: {highlight.category}</small>
                            <div className="highlight-actions">
                                <button onClick={() => handleEdit(highlight)}>Edit</button>
                                <button onClick={() => handleDelete(highlight.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Add/Edit Highlight Form */}
                <div className="highlights-form">
                    <h3>{editingId ? "Edit Highlight" : "Add New Highlight"}</h3>
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        placeholder="Date"
                    />
                    <input
                        type="text"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        placeholder="Title"
                    />
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                    />
                    <input
                        type="text"
                        name="category"
                        value={formData.category}
                        onChange={handleInputChange}
                        placeholder="Category"
                    />
                    <input
                        type="text"
                        name="media_path"
                        value={formData.media_path}
                        onChange={handleInputChange}
                        placeholder="Media Path"
                    />
                    <button onClick={handleSave}>
                        {editingId ? "Update Highlight" : "Add Highlight"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HighlightsModal;
