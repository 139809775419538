import React, { useState, useEffect } from "react"; // Removed useRef
import axios from "axios";
import Select from "react-select";
import ReactECharts from "echarts-for-react";
import "./MemberStatisticsModal.css";

function MemberStatisticsModal({ onClose }) {
    const [lodges, setLodges] = useState([]);
    const [membershipStatuses, setMembershipStatuses] = useState([]);
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [selectedLodge, setSelectedLodge] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [startDate, setStartDate] = useState("1880-01-01");
    const [endDate, setEndDate] = useState(new Date().toISOString().split("T")[0]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [maxDegrees, setMaxDegrees] = useState(0);

    const [totalMembers, setTotalMembers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 50;

    const [numTypes, setNumTypes] = useState(0);
    const [numAffiliates, setNumAffiliates] = useState(0);
    const [numActiveMembers, setNumActiveMembers] = useState(0);
    const [avgStartAge, setAvgStartAge] = useState(0);
    const [avgEndAge, setAvgEndAge] = useState(0);
    const [activeMembersPerDecade, setActiveMembersPerDecade] = useState({});
    const [struckOffMembersPerDecade, setStruckOffMembersPerDecade] = useState({});

    const [membershipTypesByYear, setMembershipTypesByYear] = useState({});
    const [viewMode, setViewMode] = useState("table"); // 'table' or 'chart'
    const [chartInstance, setChartInstance] = useState(null); // Store chart instance

    // Define a color palette
    const COLORS = [
        "#5470C6",
        "#91CC75",
        "#EE6666",
        "#FAC858",
        "#73C0DE",
        "#3BA272",
        "#FC8452",
        "#9A60B4",
        "#EA7CCC",
        "#A05195",
        // Add more colors as needed
    ];

    // Helper function to get color for a membership type
    const getColorForType = (typeLabel) => {
        const index = membershipTypes.findIndex((type) => type.label === typeLabel);
        return COLORS[index % COLORS.length] || "#000000"; // Default to black if not enough colors
    };

    // Function to export Membership Types by Year as CSV
    const exportToCSVMembershipTypes = () => {
        if (!Object.keys(membershipTypesByYear).length) {
            alert("No data available to export.");
            return;
        }

        const headers = ["Year", ...membershipTypes.map((type) => type.label)];

        const rows = Object.entries(membershipTypesByYear).map(([year, types]) => [
            year,
            ...membershipTypes.map((type) => types[type.label] || 0),
        ]);

        const csvContent = [
            headers.join(","),
            ...rows.map((row) => row.join(",")),
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "membership_types_by_year.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Function to save the chart as an image
    const saveChartAsImage = () => {
        if (!chartInstance) {
            console.error("Chart instance is not available.");
            return;
        }

        const dataURL = chartInstance.getDataURL({
            type: 'png',
            pixelRatio: 2,
            backgroundColor: '#fff'
        });

        const link = document.createElement("a");
        link.download = "membership_types_by_year_chart.png";
        link.href = dataURL;
        link.click();
    };

    // Fetch data on mount
    useEffect(() => {
        fetchLodges();
        fetchMembershipStatuses();
        fetchMembershipTypes();
        fetchStatistics(1, true); // Load the first page on mount
    }, []);

    // Fetch statistics and membership types when filters change
    useEffect(() => {
        setCurrentPage(1); // Reset to the first page
        fetchStatistics(1, true); // Fetch updated statistics
        fetchMembershipTypesByYear(); // Fetch membership types by year with the new view mode
    }, [selectedLodge, selectedStatus, selectedType, startDate, endDate]);

    // Fetch lodges
    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/member_stats/lodges");
            setLodges(res.data.map((lodge) => ({ value: lodge.id, label: lodge.name })));
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    // Fetch membership types by year (yearly view)
    const fetchMembershipTypesByYear = async () => {
        setLoading(true); // Show loading spinner
        try {
            const params = {
                lodge_id: selectedLodge || undefined,
                status_id: selectedStatus || undefined,
                type_id: selectedType || undefined,
                start_date: startDate || undefined,
                end_date: endDate || undefined,
            };

            const res = await axios.get("/api/member_stats/membership_types_by_year", { params });

            // Do NOT overwrite membershipTypes here
            // Directly set the membershipTypesByYear state
            setMembershipTypesByYear(res.data);
        } catch (error) {
            console.error("Error fetching membership types by year:", error);
            alert(error.response?.data?.error || "Failed to fetch membership types by year.");
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };

    // Render Membership Types by Year as Table
    const renderMembershipTypesByYearTable = () => {
        if (!Object.keys(membershipTypesByYear).length) {
            return <p>No data available for membership types by year.</p>;
        }

        return (
            <div className="member-stats-modal-scrollable-table-container">
                <table className="member-stats-modal-scrollable-table">
                    <thead>
                    <tr>
                        <th>Year</th>
                        {membershipTypes.map((type) => (
                            <th key={type.value}>{type.label}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(membershipTypesByYear).map(([year, types]) => (
                        <tr key={year}>
                            <td>{year}</td>
                            {membershipTypes.map((type) => (
                                <td key={type.value}>{types[type.label] || 0}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    };

    // Fetch membership statuses
    const fetchMembershipStatuses = async () => {
        try {
            const res = await axios.get("/api/member_stats/membership_statuses");
            setMembershipStatuses(res.data.map((status) => ({ value: status.id, label: status.name })));
        } catch (error) {
            console.error("Error fetching membership statuses:", error);
        }
    };

    // Fetch membership types
    const fetchMembershipTypes = async () => {
        try {
            const res = await axios.get("/api/member_stats/membership_types");
            setMembershipTypes(res.data.map((type) => ({ value: type.id, label: type.name })));
        } catch (error) {
            console.error("Error fetching membership types:", error);
        }
    };

    // Format date to South African Standard Time
    const formatDateToSAST = (date) => {
        if (!date) return "Present";
        const options = { timeZone: "Africa/Johannesburg", year: "numeric", month: "2-digit", day: "2-digit" };
        return new Date(date).toLocaleDateString("en-ZA", options);
    };

    // Fetch main statistics
    const fetchStatistics = async (page, reset = false) => {
        setLoading(true);
        try {
            const params = {
                lodge_id: selectedLodge || undefined,
                status_id: selectedStatus || undefined,
                type_id: selectedType || undefined,
                start_date: startDate || undefined,
                end_date: endDate || undefined,
                limit: itemsPerPage,
                offset: (page - 1) * itemsPerPage,
            };
            const res = await axios.get("/api/member_stats/statistics", { params });
            const newStatistics = res.data.tableData || [];
            setMaxDegrees(Math.max(maxDegrees, ...newStatistics.map((stat) => stat.degrees?.length || 0)));

            if (reset) {
                setStatistics(newStatistics);
            } else {
                setStatistics((prev) => [...prev, ...newStatistics]);
            }

            setTotalMembers(res.data.total); // Update total members
            setTotalPages(Math.ceil(res.data.total / itemsPerPage)); // Calculate total pages
            setHasMore(page < Math.ceil(res.data.total / itemsPerPage)); // Check if more pages exist

            // Calculate additional statistics
            const activeMembers = newStatistics.filter((stat) => stat.status_name === "Active").length;
            const affiliates = newStatistics.filter((stat) => stat.type_name === "Affiliate").length;
            const startAges = newStatistics.map((stat) => stat.age_at_start).filter((age) => age !== null);
            const endAges = newStatistics.map((stat) => stat.age_at_end).filter((age) => age !== null);

            const activeByDecade = {};
            const struckOffByDecade = {};

            newStatistics.forEach((stat) => {
                const startYear = new Date(stat.start_date).getFullYear();
                const endYear = stat.end_date ? new Date(stat.end_date).getFullYear() : null;

                const startDecade = Math.floor(startYear / 10) * 10;
                const endDecade = endYear ? Math.floor(endYear / 10) * 10 : null;

                // Count active members per decade
                if (stat.status_name === "Active") {
                    activeByDecade[startDecade] = (activeByDecade[startDecade] || 0) + 1;
                }

                // Count struck-off members per decade
                if (stat.status_name === "Struck Off" && endDecade !== null) {
                    struckOffByDecade[endDecade] = (struckOffByDecade[endDecade] || 0) + 1;
                }
            });

            setNumActiveMembers(activeMembers);
            setNumAffiliates(affiliates);
            setAvgStartAge(startAges.length ? (startAges.reduce((a, b) => a + b, 0) / startAges.length).toFixed(1) : 0);
            setAvgEndAge(endAges.length ? (endAges.reduce((a, b) => a + b, 0) / endAges.length).toFixed(1) : 0);
            setNumTypes([...new Set(newStatistics.map((stat) => stat.type_name))].length);
            setActiveMembersPerDecade(activeByDecade);
            setStruckOffMembersPerDecade(struckOffByDecade);
        } catch (error) {
            console.error("Error fetching statistics:", error);
        } finally {
            setLoading(false);
        }
    };

    // Load more statistics (pagination)
    const loadMore = () => {
        if (!hasMore || loading) return;
        fetchStatistics(currentPage + 1);
        setCurrentPage((prev) => prev + 1);
    };

    // Render degree columns in the main table
    const renderDegreeColumns = (degrees) => {
        const degreeCells = [];
        for (let i = 0; i < maxDegrees; i++) {
            const degree = degrees[i];
            degreeCells.push(
                <td key={i}>
                    {degree ? `${degree.degree_name} (${formatDateToSAST(degree.date_obtained)})` : "N/A"}
                </td>
            );
        }
        return degreeCells;
    };

    // Render Membership Types by Year as Chart
    const renderMembershipTypesByYearChart = () => {
        if (!Object.keys(membershipTypesByYear).length) {
            return <p>No data available for membership types by year.</p>;
        }

        // Transform data for ECharts
        const chartData = Object.entries(membershipTypesByYear).map(([year, types]) => {
            const dataPoint = { year };
            membershipTypes.forEach((type) => {
                dataPoint[type.label] = types[type.label] || 0;
            });
            return dataPoint;
        });

        // Prepare series for each membership type
        const series = membershipTypes.map((type) => ({
            name: type.label,
            type: "bar",
            stack: "Total",
            emphasis: {
                focus: "series",
            },
            data: chartData.map((data) => data[type.label]),
            itemStyle: {
                color: getColorForType(type.label),
            },
        }));

        // ECharts option
        const option = {
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow",
                },
            },
            legend: {
                data: membershipTypes.map((type) => type.label),
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: {
                type: "category",
                data: chartData.map((data) => data.year),
            },
            yAxis: {
                type: "value",
            },
            series: series,
        };

        return (
            <div className="member-stats-modal-chart-container">
                <ReactECharts
                    option={option}
                    style={{ height: "400px", width: "100%" }}
                    onChartReady={(echart) => setChartInstance(echart)}
                />
            </div>
        );
    };

    // Export main statistics as CSV
    const exportToCSV = () => {
        if (!statistics.length) {
            alert("No data available to export.");
            return;
        }

        const headers = [
            "Name",
            "Lodge",
            "Occupation",
            "Birthdate",
            "Age at Start",
            "Age at End",
            "Status",
            "Type",
            "Start Date",
            "End Date",
            ...Array.from({ length: maxDegrees }, (_, i) => `Degree ${i + 1}`)
        ];

        const rows = statistics.map((stat) => [
            `${stat.first_name} ${stat.last_name}`,
            stat.lodge_name || "Unknown",
            stat.occupation || "Not Provided",
            stat.birthdate ? formatDateToSAST(stat.birthdate) : "Unknown",
            stat.age_at_start !== null ? `${stat.age_at_start} years` : "N/A",
            stat.age_at_end !== null ? `${stat.age_at_end} years` : "N/A",
            stat.status_name,
            stat.type_name,
            formatDateToSAST(stat.start_date),
            formatDateToSAST(stat.end_date),
            ...stat.degrees.map((degree) =>
                degree ? `${degree.degree_name} (${formatDateToSAST(degree.date_obtained)})` : "N/A"
            )
        ]);

        const csvContent = [
            headers.join(","),
            ...rows.map((row) => row.join(",")),
        ].join("\n");

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "membership_statistics.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="member-stats-modal-overlay">
            <div className="member-stats-modal-content">
                <div className="member-stats-modal-header">
                    <h2>Membership Statistics</h2>
                    <button
                        className="member-stats-modal-close-button"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <div className="member-stats-modal-body">
                    <div className="member-stats-modal-filters">
                        {/* Lodge Selector */}
                        <Select
                            options={[{ value: "", label: "All Lodges" }, ...lodges]}
                            value={lodges.find((l) => l.value === selectedLodge) || { value: "", label: "All Lodges" }}
                            onChange={(option) => setSelectedLodge(option.value)}
                            placeholder="Filter by Lodge"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    minWidth: "200px",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />

                        {/* Membership Status Selector */}
                        <Select
                            options={[{ value: "", label: "All Statuses" }, ...membershipStatuses]}
                            value={membershipStatuses.find((s) => s.value === selectedStatus) || { value: "", label: "All Statuses" }}
                            onChange={(option) => setSelectedStatus(option.value)}
                            placeholder="Filter by Membership Status"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    minWidth: "200px",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />

                        {/* Membership Type Selector */}
                        <Select
                            options={[{ value: "", label: "All Types" }, ...membershipTypes]}
                            value={membershipTypes.find((t) => t.value === selectedType) || { value: "", label: "All Types" }}
                            onChange={(option) => setSelectedType(option.value)}
                            placeholder="Filter by Membership Type"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    minWidth: "200px",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />

                        {/* Date Range Inputs */}
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="member-stats-modal-date-input"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="member-stats-modal-date-input"
                        />
                    </div>

                    <div className="member-stats-modal-summary">
                        <h3>Statistics Summary</h3>
                        <p>Number of Types: {numTypes}</p>
                        <p>Number of Affiliates: {numAffiliates}</p>
                        <p>Number of Active Members: {numActiveMembers}</p>
                        <p>Average Start Age: {avgStartAge} years</p>
                        <p>Average End Age: {avgEndAge} years</p>

                        {/* Membership Types By Year with Toggle and Export Buttons */}
                        <div className="member-stats-modal-membership-types">
                            <div className="member-stats-modal-membership-types-header">
                                <h4>Membership Types Per Year</h4>
                                <div className="member-stats-modal-membership-types-controls">
                                    <button onClick={() => setViewMode(viewMode === "table" ? "chart" : "table")}>
                                        {viewMode === "table" ? "Show Chart" : "Show Table"}
                                    </button>
                                    <button onClick={exportToCSVMembershipTypes}>Save as CSV</button>
                                    {viewMode === "chart" && <button onClick={saveChartAsImage}>Save Chart</button>}
                                </div>
                            </div>
                            {loading ? (
                                <p>Loading membership types by year...</p>
                            ) : viewMode === "table" ? (
                                renderMembershipTypesByYearTable()
                            ) : (
                                renderMembershipTypesByYearChart()
                            )}
                        </div>

                        {/* Uncomment and implement if needed
                        <h4>Active Members Per Decade</h4>
                        {Object.entries(activeMembersPerDecade).length ? (
                            <ul>
                                {Object.entries(activeMembersPerDecade).map(([decade, count]) => (
                                    <li key={decade}>{decade}s: {count}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No active members per decade data available.</p>
                        )}

                        <h4>Struck Off Members Per Decade</h4>
                        {Object.entries(struckOffMembersPerDecade).length ? (
                            <ul>
                                {Object.entries(struckOffMembersPerDecade).map(([decade, count]) => (
                                    <li key={decade}>{decade}s: {count}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>No struck off members per decade data available.</p>
                        )}
                        */}
                    </div>

                    <div className="member-stats-modal-export-button">
                        <button onClick={exportToCSV}>Export as CSV</button>
                    </div>

                    <div className="member-stats-modal-pagination">
                        <p>Total Members: {totalMembers}</p>
                        <button
                            onClick={() => {
                                if (currentPage > 1) {
                                    fetchStatistics(currentPage - 1);
                                    setCurrentPage((prev) => prev - 1);
                                }
                            }}
                            disabled={currentPage === 1 || loading}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            onClick={() => {
                                if (currentPage < totalPages) {
                                    fetchStatistics(currentPage + 1);
                                    setCurrentPage((prev) => prev + 1);
                                }
                            }}
                            disabled={currentPage === totalPages || loading}
                        >
                            Next
                        </button>
                    </div>

                    {/* Main Statistics Table */}
                    <div className="member-stats-modal-table">
                        <table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Lodge</th>
                                <th>Occupation</th>
                                <th>Birthdate</th>
                                <th>Age at Start</th>
                                <th>Age at End</th>

                                <th>Status</th>
                                <th>Type</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                {Array.from({ length: maxDegrees }).map((_, i) => (
                                    <th key={i}>Degree {i + 1}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {statistics.map((stat, index) => (
                                <tr key={index}>
                                    <td>{`${stat.first_name} ${stat.last_name}`}</td>
                                    <td>{stat.lodge_name || "Unknown"}</td>
                                    <td>{stat.occupation || "Not Provided"}</td>
                                    <td>{stat.birthdate ? formatDateToSAST(stat.birthdate) : "Unknown"}</td>
                                    <td>{stat.age_at_start !== null ? (stat.age_at_start) : "N/A"}</td>
                                    <td>{stat.age_at_end !== null ? (stat.age_at_end) : "N/A"}</td>

                                    <td>{stat.status_name}</td>
                                    <td>{stat.type_name}</td>
                                    <td>{formatDateToSAST(stat.start_date)}</td>
                                    <td>{formatDateToSAST(stat.end_date)}</td>
                                    {renderDegreeColumns(stat.degrees)}
                                </tr>
                            ))}
                            {!statistics.length && (
                                <tr>
                                    <td colSpan={10 + maxDegrees}>No data available</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {hasMore && (
                            <button onClick={loadMore} disabled={loading}>
                                {loading ? "Loading..." : "Load More"}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MemberStatisticsModal;
