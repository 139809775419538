import React, { useState } from "react";
import axios from "axios";
import StatsMeetings from "./StatsMeetings"; // Meeting Stats modal
import StatsBuildingsAgeStatus from "./StatsBuildingsAgeStatus"; // NEW: Buildings Age/Status modal
import MemberStatisticsModal from "./MemberStatisticsModal"; // Membership Stats modal
import StatsLodges from "./StatsLodges"; // import the new component

import "./StatisticsModal.css";

function StatisticsModal({ onClose }) {
    const [statsData, setStatsData] = useState(null); // State for meeting stats data
    const [currentModal, setCurrentModal] = useState(null);

    // The list of stats sections to display in the main "statistics" menu
    const statsSections = [
        {
            name: "Buildings Age/Status Statistics",
            description: "",
            icon: "/images/buildingstats.png",
            action: () => {
                // Open the Buildings Age/Status modal
                setCurrentModal("buildingsAgeStatus");
            },
        },
        {
            name: "Lodge Statistics",
            icon: "/images/lodge_stats.png",
            action: () => setCurrentModal("lodgeStats")
        },
        {
            name: "Membership Statistics",
            description: "",
            icon: "/images/membership_stats.png",
            action: () => {
                // Open the Membership Statistics modal
                setCurrentModal("membershipStats");
            },
        },
        {
            name: "Meeting Statistics",
            description: "",
            icon: "/images/meetingstats.png",
            action: async () => {
                // Fetch data for meeting stats, then open the modal
                try {
                    const response = await axios.get("/api/stats/meetings");
                    setStatsData(response.data);
                    setCurrentModal("meetingStats");
                } catch (error) {
                    console.error("Error fetching meeting statistics:", error);
                    alert("Failed to fetch meeting statistics. Please try again.");
                }
            },
        },
        // Uncomment or add additional stats sections as needed
        // {
        //     name: "Other Useful Statistics",
        //     description: "",
        //     icon: "/images/other_stats.png",
        //     action: () => {
        //         setCurrentModal("otherStats");
        //     },
        // },
    ];

    const handleClose = () => {
        setStatsData(null);
        setCurrentModal(null);
        if (onClose) onClose();
    };

    return (
        <>
            {/* Step 1: Main overlay with stats menu (if no specific modal is open) */}
            {currentModal === null && (
                <div className="statistics-modal-overlay-unique">
                    <div className="statistics-modal-content-unique">
                        <div className="statistics-modal-header-unique">
                            <h2>Statistics</h2>
                            <button
                                className="statistics-modal-close-button-unique"
                                onClick={handleClose}
                                aria-label="Close Statistics Modal"
                            >
                                &times;
                            </button>
                        </div>
                        <div className="statistics-modal-body-unique">
                            <div className="statistics-grid-unique">
                                {statsSections.map((section, index) => (
                                    <div
                                        key={index}
                                        className="statistics-card-unique"
                                        onClick={section.action}
                                        role="button"
                                        tabIndex={0}
                                        aria-label={`Open ${section.name}`}
                                    >
                                        <img
                                            src={section.icon}
                                            alt={`${section.name} icon`}
                                            className="statistics-card-icon-unique"
                                        />
                                        <h3 className="statistics-card-title-unique">{section.name}</h3>
                                        <p className="statistics-card-description-unique">
                                            {section.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Step 2: Buildings Age/Status Statistics Modal */}
            {currentModal === "buildingsAgeStatus" && (
                <StatsBuildingsAgeStatus
                    onClose={() => {
                        setCurrentModal(null);
                    }}
                />
            )}

            {/* Step 3: Meeting Statistics Modal (only if we have fetched data) */}
            {currentModal === "meetingStats" && statsData && (
                <StatsMeetings
                    statsData={statsData}
                    onClose={() => {
                        handleClose();
                    }}
                />
            )}

            {currentModal === "lodgeStats" && (
                <StatsLodges onClose={() => setCurrentModal(null)} />
            )}

            {/* Step 4: Membership Statistics Modal */}
            {currentModal === "membershipStats" && (
                <MemberStatisticsModal
                    onClose={() => {
                        handleClose();
                    }}
                />
            )}

            {/* Example placeholder for "Other Stats" if you want to add them:
            {currentModal === "otherStats" && (
                <div className="statistics-modal-overlay-unique">
                    <div className="statistics-modal-content-unique">
                        <div className="statistics-modal-header-unique">
                            <h2>Other Useful Statistics</h2>
                            <button
                                className="statistics-modal-close-button-unique"
                                onClick={handleClose}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="statistics-modal-body-unique">
                            <p>Other useful statistics are currently under development.</p>
                        </div>
                    </div>
                </div>
            )} */}
        </>
    );
}

export default StatisticsModal;
