import React, {useState, useEffect} from "react";
import axios from "axios";
import "./AdminPanel.css";

function AdminPanel({user}) {
    const [activeTable, setActiveTable] = useState("constitutions");
    const [data, setData] = useState([]);
    const [formData, setFormData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [setRanks] = useState([]);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [isUsersView, setIsUsersView] = useState(false); // Flag for user view

    const tables = [
        {name: "constitutions", displayName: "Constitutions"},
        {name: "countries", displayName: "Countries"},
        {name: "ordercodes", displayName: "Order Codes"},
        {name: "lodges", displayName: "Entity"},
        {name: "eventtypes", displayName: "Event Types"},
        {name: "documenttypes", displayName: "Document Types"},
        {name: "filetypes", displayName: "File Types"},
        {name: "officers", displayName: "Officers"},
        {name: "degrees", displayName: "Degrees"},
        {name: "ranks", displayName: "Ranks"}, // Add ranks here
        {name: "membership_types", displayName: "Membership Types"}, // Add membership_types
        {name: "membership_statuses", displayName: "Membership Statuses"}, // Add membership_statuses
        {name: "users", displayName: "Users"}, // Add Users table

    ];

    const allowedColumns = {
        constitutions: ["code", "description"],
        countries: ["code", "name"],
        ordercodes: ["code", "description"],
        lodges: ["code", "name"],
        eventtypes: ["code", "description"],
        documenttypes: ["code", "description"],
        filetypes: ["code", "description"],
        officers: ["role_name", "description"],
        degrees: ["name", "description"],
        ranks: ["rank_name", "description"], // Add ranks here
        membership_types: ["name"], // membership_types have only name field
        membership_statuses: ["name"], // membership_statuses have only name field
        users: ["first_name", "last_name", "email", "username", "role"], // Columns for Users
    };


    useEffect(() => {
        if (activeTable === "ranks") {
            axios
                .get("/api/crud/ranks")
                .then((res) => setRanks(res.data))
                .catch((err) => {
                    console.error("Error fetching ranks:", err);
                    alert("Failed to fetch ranks.");
                });
        }
    }, [activeTable]);

    useEffect(() => {
        if (activeTable === "users") {
            setIsUsersView(true);
            fetchUsersAndRoles();
        } else {
            setIsUsersView(false);
            fetchData();
        }
    }, [activeTable]);

    const fetchData = () => {
        axios
            .get(`/api/crud/${activeTable}`)
            .then((res) => {
                const sortKey = allowedColumns[activeTable].includes("name")
                    ? "name"
                    : "description";
                const sortedData = res.data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                setData(sortedData);
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                alert("Failed to fetch data.");
            });
    };

    const fetchUsersAndRoles = async () => {
        try {
            const [usersResponse, rolesResponse] = await Promise.all([
                axios.get("/api/users"),
                axios.get("/api/roles"),
            ]);
            setUsers(usersResponse.data);
            setRoles(rolesResponse.data);
        } catch (error) {
            console.error("Error fetching users and roles:", error);
            alert("Failed to fetch users or roles.");
        }
    };


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});

        // Ensure role_id is updated correctly
        if (name === "role_id") {
            setFormData({...formData, role_id: parseInt(value)});
        }
    };

    const confirmProceed = (action) => {
        setConfirmAction(action);
        setShowConfirmModal(true);
    };

    const handleConfirmYes = () => {
        setShowConfirmModal(false);
        if (confirmAction === "save") {
            handleSaveConfirmed();
        } else if (confirmAction === "delete") {
            handleDeleteConfirmed();
        }
        setConfirmAction(null);
    };

    const handleConfirmNo = () => {
        setShowConfirmModal(false);
        setConfirmAction(null);
    };

    const handleSave = () => {
        confirmProceed("save");
    };

    const handleSaveConfirmed = () => {
        const url = isEditing
            ? activeTable === "users"
                ? `/api/users/${editingId}`
                : `/api/crud/${activeTable}/${editingId}`
            : activeTable === "users"
                ? `/api/users`
                : `/api/crud/${activeTable}`;
        const method = isEditing ? "put" : "post";

        axios[method](url, formData)
            .then(() => {
                setShowModal(false);
                setFormData({});
                setIsEditing(false);
                setEditingId(null);
                activeTable === "users" ? fetchUsersAndRoles() : fetchData();
            })
            .catch((err) => {
                console.error("Error saving data:", err.response?.data || err);
                alert(`Failed to save data: ${err.response?.data?.error || err.message}`);
            });
    };

    const handleEdit = (row) => {
        setFormData({...row, role_id: row.role_id || ""});
        setIsEditing(true);
        setEditingId(row.id);
        setShowModal(true);
    };

    const handleDelete = (id) => {
        setConfirmAction("delete");
        setEditingId(id);
        setShowConfirmModal(true);
    };

    const handleDeleteConfirmed = () => {
        axios
            .delete(activeTable === "users" ? `/api/users/${editingId}` : `/api/crud/${activeTable}/${editingId}`)
            .then(() => {
                setEditingId(null);
                activeTable === "users"
                    ? setUsers(users.filter((user) => user.id !== editingId))
                    : setData(data.filter((row) => row.id !== editingId));
            })
            .catch((err) => {
                console.error("Error deleting data:", err.response?.data || err);
                alert(`Failed to delete: ${err.response?.data?.error || err.message}`);
            });
    };

    const openAddModal = () => {
        const initialFormData = allowedColumns[activeTable].reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setFormData(initialFormData);
        setIsEditing(false);
        setEditingId(null);
        setShowModal(true);
    };

    return (
        <div className="admin-panel">
            <h1>Admin Panel</h1>
            <div className="dropdown-container">
                <select
                    className="table-dropdown"
                    value={activeTable}
                    onChange={(e) => setActiveTable(e.target.value)}
                >
                    {tables
                        .filter(
                            (table) =>
                                table.name !== "users" || // Show "Users" only for Archivists
                                user.role === "Archivist"
                        )
                        .map((table) => (
                            <option key={table.name} value={table.name}>
                                {table.displayName}
                            </option>
                        ))}
                </select>
            </div>
            <div className="table-container">
                <div className="table-header">
                    <h2>{tables.find((table) => table.name === activeTable)?.displayName}</h2>
                    <button className="add-button" onClick={openAddModal}>
                        Add New
                    </button>
                </div>
                <table className="data-table">
                    <thead>
                    <tr>
                        {allowedColumns[activeTable]?.map((key) => (
                            <th key={key}>{key}</th>
                        ))}
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(isUsersView ? users : data).map((row) => (
                        <tr key={row.id || row.code}>
                            {allowedColumns[activeTable]?.map((key) => (
                                <td key={key}>
                                    {key === "role" ? (
                                        <select
                                            value={row.role_id || ""}
                                            onChange={(e) => {
                                                const updatedRoleId = e.target.value;
                                                axios
                                                    .put(`/api/users/${row.id}`, {
                                                        ...row,
                                                        role_id: updatedRoleId,
                                                    })
                                                    .then(() => {
                                                        alert("Role updated successfully.");
                                                        fetchUsersAndRoles();
                                                    })
                                                    .catch((err) => {
                                                        console.error("Error updating role:", err);
                                                        alert("Failed to update role.");
                                                    });
                                            }}
                                        >
                                            <option value="">Select Role</option>
                                            {roles.map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.name}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        row[key]
                                    )}
                                </td>
                            ))}
                            <td>
                                <button className="edit-btn" onClick={() => handleEdit(row)}>
                                    Edit
                                </button>
                                <button className="delete-btn" onClick={() => handleDelete(row.id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    {(isUsersView ? users : data).length === 0 && (
                        <tr>
                            <td colSpan={(allowedColumns[activeTable]?.length || 0) + 1}>
                                No data available.
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{isEditing ? "Edit Entry" : "Add Entry"}</h3>
                        {allowedColumns[activeTable].map((key) => (
                            <div key={key} className="form-group">
                                <label htmlFor={key}>{key}</label>
                                {key === "role" ? (
                                    <select
                                        id={key}
                                        name="role_id"
                                        value={formData.role_id || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Role</option>
                                        {roles.map((role) => (
                                            <option key={role.id} value={role.id}>
                                                {role.name}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        id={key}
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                    />
                                )}
                            </div>
                        ))}
                        <div className="modal-actions">
                            <button className="btn btn-save" onClick={handleSave}>
                                Save
                            </button>
                            <button
                                className="btn btn-cancel"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {showConfirmModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>Confirmation</h3>
                        <p>Are you sure you want to proceed?</p>
                        <div className="modal-actions">
                            <button
                                className="btn btn-confirm-yes"
                                onClick={handleConfirmYes}
                            >
                                Yes
                            </button>
                            <button
                                className="btn btn-confirm-no"
                                onClick={handleConfirmNo}
                            >
                                No
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminPanel;
