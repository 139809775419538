import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import "./StatsBuildingsAgeStatus.css";

/**
 * This component fetches building age stats from:
 *   GET /api/v1/buildings/stats/age?groupBy=year or decade
 *
 * Then renders a stacked bar chart:
 *   x-axis = year or decade
 *   stacked by "Active" vs. "Not Active"
 *
 * Enhancements:
 * 1) Optional city filter (example).
 * 2) Export chart as PNG.
 * 3) Show a data table with raw counts.
 * 4) More robust color palette for statuses.
 */
function StatsBuildingsAgeStatus({ onClose }) {
    const [groupBy, setGroupBy] = useState("year");  // "year" or "decade"
    const [selectedCity, setSelectedCity] = useState(""); // Example secondary filter
    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // For demonstration, let’s assume you have an API or array of known cities
    const [cities, setCities] = useState([]);

    // Chart references
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    // 1) On mount, fetch possible city options (if needed).
    useEffect(() => {
        fetchCities();
    }, []);

    // 2) Whenever groupBy or selectedCity changes, fetch stats
    useEffect(() => {
        fetchStats();
        // Cleanup if unmount
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [groupBy, selectedCity]);

    // Example: fetch city list from server
    const fetchCities = async () => {
        try {
            // Suppose an endpoint: GET /api/v1/buildings/cities
            // returning something like ["Cape Town", "Johannesburg", "Pretoria", ...]
            const res = await axios.get("/api/v1/buildings/cities");
            setCities(res.data || []);
        } catch (err) {
            console.error("Error fetching cities:", err);
            // Not mandatory, just log
        }
    };

    // 3) Fetch building stats
    const fetchStats = async () => {
        try {
            setLoading(true);
            setError(null);

            // Pass both groupBy and city as query params if city is relevant
            const params = { groupBy };
            if (selectedCity) params.city = selectedCity;

            const res = await axios.get("/api/v1/buildings/stats/age", { params });
            const data = res.data; // e.g. { groupBy, rows: [ { group_val, status_category, count }, ... ] }
            if (!data.rows || data.rows.length === 0) {
                setChartData({ labels: [], datasets: [] }); // empty
            } else {
                setChartData(transformData(data.rows));
            }
        } catch (err) {
            console.error("Error fetching building stats:", err);
            setError("Failed to load building stats. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    // 4) Transform the array of { group_val, status_category, count }
    // into a stacked chart dataset
    const transformData = (rows) => {
        // Collect all unique group_val in ascending order
        const uniqueGroups = [...new Set(rows.map(r => r.group_val))].sort((a, b) => a - b);

        // Collect all possible statuses from the data
        const uniqueStatuses = [...new Set(rows.map(r => r.status_category))].sort();

        // We'll build a map: dataMap[group_val][status_category] = count
        const dataMap = {};
        rows.forEach(r => {
            if (!dataMap[r.group_val]) {
                dataMap[r.group_val] = {};
            }
            dataMap[r.group_val][r.status_category] = r.count;
        });

        // A color palette with more entries if you have more statuses
        const colorPalette = [
            "rgba(75,192,192,0.7)",   // Aqua
            "rgba(255,99,132,0.7)",  // Pinkish
            "rgba(54,162,235,0.7)",  // Blue
            "rgba(255,206,86,0.7)",  // Yellow
            "rgba(153,102,255,0.7)", // Purple
            "rgba(255,159,64,0.7)",  // Orange
            "rgba(99,255,132,0.7)"   // Light green
        ];

        // Build datasets array for Chart.js
        const datasets = uniqueStatuses.map((status, idx) => {
            // Each dataset is one "stack" level
            return {
                label: status,
                data: uniqueGroups.map(g => dataMap[g][status] || 0),
                backgroundColor: colorPalette[idx % colorPalette.length],
                stack: "buildingsStack"
            };
        });

        return {
            labels: uniqueGroups.map(g => g.toString()),
            datasets
        };
    };

    // 5) Create or update the chart after chartData changes
    useEffect(() => {
        if (!chartData) return;
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        chartInstance.current = new Chart(ctx, {
            type: "bar",
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                        title: { display: true, text: groupBy === "decade" ? "Decade" : "Year" }
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        title: { display: true, text: "Number of Buildings" }
                    }
                },
                plugins: {
                    legend: { position: "top" },
                    title: {
                        display: true,
                        text: `Buildings by ${groupBy === "decade" ? "Decade" : "Year"} & Status (Stacked)`
                    }
                }
            }
        });
    }, [chartData, groupBy]);

    // 6) Export chart as PNG
    const exportChartAsPNG = () => {
        if (!chartInstance.current) return;
        const base64Image = chartInstance.current.toBase64Image();
        const link = document.createElement("a");
        link.href = base64Image;
        link.download = `buildings_${groupBy}_stats.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // 7) Build a small data table to show the raw values
    // (We’ll reuse the same "chartData" to build the table.)
    const renderTable = () => {
        if (!chartData || !chartData.labels || chartData.labels.length === 0) {
            return <p>No data found.</p>;
        }
        // chartData.labels -> array of group_val (string form)
        // chartData.datasets -> each status in order, with .data array
        // We'll reconstruct a row for each group_val
        const rows = chartData.labels.map((label, rowIndex) => {
            // For each dataset, get dataset.data[rowIndex]
            const columns = chartData.datasets.map((ds, dsIndex) => ({
                status: ds.label,
                count: ds.data[rowIndex]
            }));
            return { groupVal: label, columns };
        });

        // Unique statuses from the dataset
        const statuses = chartData.datasets.map(ds => ds.label);

        return (
            <table className="age-status-table">
                <thead>
                <tr>
                    <th>{groupBy === "decade" ? "Decade" : "Year"}</th>
                    {statuses.map(st => <th key={st}>{st}</th>)}
                </tr>
                </thead>
                <tbody>
                {rows.map((r, idx) => (
                    <tr key={idx}>
                        <td>{r.groupVal}</td>
                        {r.columns.map((col, colIdx) => (
                            <td key={colIdx}>{col.count}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="stats-buildings-age-overlay">
            <div className="stats-buildings-age-content">
                <div className="stats-buildings-age-header">
                    <h2>Buildings Age/Status Statistics</h2>
                    <button className="close-button" onClick={onClose} aria-label="Close Modal">
                        &times;
                    </button>
                </div>

                <div className="stats-buildings-age-body">
                    {/* Error/loading states */}
                    {loading && <div className="loading-spinner">Loading stats...</div>}
                    {error && <div className="error-message">{error}</div>}

                    {/* If not loading/error, show filters + chart */}
                    {!loading && !error && (
                        <>
                            {/* Filters Row */}
                            <div className="filters-row">
                                <label htmlFor="groupBySelect">
                                    Group By:
                                    <select
                                        id="groupBySelect"
                                        value={groupBy}
                                        onChange={(e) => setGroupBy(e.target.value)}
                                        aria-label="Select grouping mode"
                                    >
                                        <option value="year">Year</option>
                                        <option value="decade">Decade</option>
                                    </select>
                                </label>

                                {/* Example city filter (optional) */}
                                {cities.length > 0 && (
                                    <label htmlFor="citySelect">
                                        City:
                                        <select
                                            id="citySelect"
                                            value={selectedCity}
                                            onChange={(e) => setSelectedCity(e.target.value)}
                                            aria-label="Select City"
                                        >
                                            <option value="">All Cities</option>
                                            {cities.map((city) => (
                                                <option key={city} value={city}>{city}</option>
                                            ))}
                                        </select>
                                    </label>
                                )}

                                {/* Export Button */}
                                <button onClick={exportChartAsPNG} className="export-png-button" aria-label="Export chart as PNG">
                                    Export PNG
                                </button>
                            </div>

                            <div className="chart-wrapper">
                                {chartData && chartData.labels.length > 0 ? (
                                    <canvas ref={chartRef}></canvas>
                                ) : (
                                    <p>No data found for this selection.</p>
                                )}
                            </div>

                            {/* Data table below chart */}
                            <div className="data-table-section">
                                <h3>Data Table</h3>
                                {renderTable()}
                            </div>
                        </>
                    )}
                </div>

                <div className="stats-buildings-age-footer">
                    <button className="close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default StatsBuildingsAgeStatus;
