import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "./MeetingAttendeesModal.css";

function MeetingAttendeesModal({ meetingId, onClose }) {
    const [attendees, setAttendees] = useState([]);
    const [members, setMembers] = useState([]);
    const [lodges, setLodges] = useState([]);
    const [formData, setFormData] = useState({
        member: null,
        visitor: false,
        visitorLodge: null,
        visitorName: "",
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        fetchAttendees();
        fetchMembers();
        fetchLodges();
    }, [meetingId]);

    const fetchAttendees = async () => {
        try {
            const res = await axios.get(`/api/meetings/${meetingId}/attendees`);
            setAttendees(res.data);
        } catch (error) {
            console.error("Error fetching attendees:", error);
        }
    };

    const fetchMembers = async () => {
        try {
            const res = await axios.get("/api/resources/members");
            // Map to { value, label } format
            const mappedMembers = res.data.map((m) => ({
                value: m.id,
                label: `${m.first_name} ${m.last_name}`,
                firstName: m.first_name,
                lastName: m.last_name,
            }));
            setMembers(mappedMembers);
        } catch (error) {
            console.error("Error fetching members:", error);
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            // Map to { value, label } format
            const mappedLodges = res.data.map((l) => ({
                value: l.id,
                label: l.name,
            }));
            setLodges(mappedLodges);
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const handleMemberChange = (selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            member: selectedOption,
            // Reset visitor name if member changes
            visitorName: selectedOption
                ? `${selectedOption.firstName} ${selectedOption.lastName}`
                : "",
        }));
        if (errors.member) {
            setErrors((prevErrors) => ({ ...prevErrors, member: null }));
        }
    };

    const handleVisitorChange = (e) => {
        const { checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            visitor: checked,
            visitorLodge: null,
            visitorName: checked
                ? prev.member
                    ? `${prev.member.firstName} ${prev.member.lastName}`
                    : ""
                : "",
        }));
        if (errors.visitorLodge) {
            setErrors((prevErrors) => ({ ...prevErrors, visitorLodge: null }));
        }
    };

    const handleVisitorLodgeChange = (selectedOption) => {
        setFormData((prev) => ({
            ...prev,
            visitorLodge: selectedOption,
        }));
        if (errors.visitorLodge) {
            setErrors((prevErrors) => ({ ...prevErrors, visitorLodge: null }));
        }
    };

    const validateAttendee = () => {
        const newErrors = {};
        if (!formData.member && !formData.visitor) {
            newErrors.member = "Please select a member.";
        }
        if (formData.visitor && !formData.visitorLodge) {
            newErrors.visitorLodge = "Please select a lodge for the visitor.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateAttendee()) {
            return;
        }

        setSubmitLoading(true);
        try {
            const payload = {
                member_id: formData.member ? formData.member.value : null,
                visitor: formData.visitor,
                visitor_lodge_id: formData.visitor ? formData.visitorLodge.value : null,
                visitor_name: formData.visitor
                    ? formData.member
                        ? `${formData.member.firstName} ${formData.member.lastName}`
                        : formData.visitorName
                    : null,
            };

            await axios.post(`/api/meetings/${meetingId}/attendees`, payload);
            fetchAttendees();
            // Reset form
            setFormData({
                member: null,
                visitor: false,
                visitorLodge: null,
                visitorName: "",
            });
            setErrors({});
        } catch (error) {
            console.error("Error adding attendee:", error);
            setErrors({ submit: "Failed to add attendee." });
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to remove this attendee?")) {
            try {
                await axios.delete(`/api/meetings/${meetingId}/attendees/${id}`);
                fetchAttendees();
            } catch (error) {
                console.error("Error removing attendee:", error);
                alert("Failed to remove attendee.");
            }
        }
    };

    return (
        <div className="meeting-attendees-modal-overlay">
            <div className="meeting-attendees-modal-content">
                <div className="meeting-attendees-modal-header">
                    <h2>Meeting Attendees</h2>
                    <button
                        className="meeting-attendees-modal-close-button"
                        onClick={onClose}
                        aria-label="Close Meeting Attendees Modal"
                    >
                        &times;
                    </button>
                </div>
                <div className="meeting-attendees-modal-body">
                    {/* Attendees List */}
                    <ul className="meeting-attendees-modal-attendee-list">
                        {attendees.map((att) => {
                            const member = members.find((m) => m.value === att.member_id);
                            let attendeeName = "Visitor";
                            if (!att.visitor && member) {
                                attendeeName = `${member.label}`;
                            } else if (att.visitor && member) {
                                const lodge = lodges.find((l) => l.value === att.visitor_lodge_id);
                                attendeeName = `${member.label} (Visitor, ${lodge ? lodge.label : "Unknown Lodge"})`;
                            } else if (att.visitor) {
                                const lodge = lodges.find((l) => l.value === att.visitor_lodge_id);
                                attendeeName = `${att.visitor_name || "Unknown"} (Visitor, ${lodge ? lodge.label : "Unknown Lodge"})`;
                            }
                            return (
                                <li key={att.id} className="meeting-attendees-modal-attendee-item">
                                    {attendeeName}
                                    <button
                                        className="meeting-attendees-modal-attendee-delete-button"
                                        onClick={() => handleDelete(att.id)}
                                        aria-label={`Remove ${attendeeName}`}
                                    >
                                        Remove
                                    </button>
                                </li>
                            );
                        })}
                        {!attendees.length && (
                            <li className="meeting-attendees-modal-no-attendees">No attendees found.</li>
                        )}
                    </ul>

                    {/* Add Attendee Form */}
                    <div className="meeting-attendees-modal-form">
                        <h3>Add Attendee</h3>
                        {/* Member Select */}
                        <div className="meeting-attendees-modal-form-group">
                            <label htmlFor="member-select">Member:</label>
                            <Select
                                id="member-select"
                                options={members}
                                value={formData.member}
                                onChange={handleMemberChange}
                                isDisabled={formData.visitor}
                                placeholder="Select Member..."
                                classNamePrefix="react-select"
                                aria-label="Select Member"
                            />
                            {errors.member && <span className="meeting-attendees-modal-error">{errors.member}</span>}
                        </div>

                        {/* Visitor Checkbox */}
                        <div className="meeting-attendees-modal-form-group-checkbox">
                            <label htmlFor="visitor-checkbox">
                                <input
                                    type="checkbox"
                                    id="visitor-checkbox"
                                    name="visitor"
                                    checked={formData.visitor}
                                    onChange={handleVisitorChange}
                                />
                                Visitor
                            </label>
                        </div>

                        {/* Visitor Details */}
                        {formData.visitor && (
                            <div className="visitor-details">
                                <div className="meeting-attendees-modal-form-group">
                                    <label htmlFor="visitor-lodge-select">Visitor's Lodge:</label>
                                    <Select
                                        id="visitor-lodge-select"
                                        options={lodges}
                                        value={formData.visitorLodge}
                                        onChange={handleVisitorLodgeChange}
                                        placeholder="Select Lodge..."
                                        classNamePrefix="react-select"
                                        aria-label="Select Visitor's Lodge"
                                    />
                                    {errors.visitorLodge && (
                                        <span className="meeting-attendees-modal-error">{errors.visitorLodge}</span>
                                    )}
                                </div>
                                <div className="meeting-attendees-modal-form-group">
                                    <label htmlFor="visitor-name-input">Visitor's Name:</label>
                                    <input
                                        type="text"
                                        id="visitor-name-input"
                                        name="visitorName"
                                        value={formData.visitorName}
                                        onChange={(e) =>
                                            setFormData((prev) => ({
                                                ...prev,
                                                visitorName: e.target.value,
                                            }))
                                        }
                                        placeholder="Enter Visitor's Name..."
                                    />
                                </div>
                            </div>
                        )}

                        {/* Submit Button */}
                        <div className="meeting-attendees-modal-form-group">
                            <button
                                className="meeting-attendees-modal-save-button"
                                onClick={handleSave}
                                disabled={submitLoading}
                            >
                                {submitLoading ? "Adding..." : "Add Attendee"}
                            </button>
                            {errors.submit && (
                                <span className="meeting-attendees-modal-error">{errors.submit}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="meeting-attendees-modal-footer">
                    <button
                        className="meeting-attendees-modal-close-footer-button"
                        onClick={onClose}
                        aria-label="Close Meeting Attendees Modal"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MeetingAttendeesModal;
