// src/pages/utils/generateAllMonths.js

/**
 * Generates an array of month labels between two dates.
 * @param {string} startDate - Start date in 'YYYY-MM-DD' format.
 * @param {string} endDate - End date in 'YYYY-MM-DD' format.
 * @returns {Array} - Array of month labels in 'YYYY-MM' format.
 */
export function generateAllMonths(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = [];

    const current = new Date(start.getFullYear(), start.getMonth(), 1);

    while (current <= end) {
        const year = current.getFullYear();
        const month = (`0${current.getMonth() + 1}`).slice(-2); // Zero-padded month
        months.push(`${year}-${month}`);
        current.setMonth(current.getMonth() + 1);
    }

    return months;
}
