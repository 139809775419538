import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPanel';
import DocumentManagement from './pages/DocumentManagement';
import ViewDocuments from './pages/DocumentViewer';
import ProtectedRoute from './pages/ProtectedRoute';
import LoginRegisterPage from './pages/LoginRegisterPage';
import Footer from './pages/Footer';
import Disclaimer from './pages/Disclaimer';
import Layout from './components/Layout';

import CalendarPage from './pages/CalendarPage';
import ResearchPage from './pages/ResearchPage';
import ResourcesPage from './pages/ResourcesPage';
import AboutPage from './pages/AboutPage';
import InstructionsPage from './pages/InstructionsPage';
import AnalyticsPage from './pages/AnalyticsPage'; // <-- NEW Analytics import
import '@fortawesome/fontawesome-free/css/all.min.css';

Modal.setAppElement('#root');

function App() {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user')));
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get('/api/current_user');
                const userData = response.data;
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
            } catch (error) {
                if (error.response?.status === 401) {
                    setUser(null);
                    localStorage.removeItem('user');
                } else {
                    console.error('Error fetching user:', error);
                }
            }
        };
        fetchUser();
    }, []);

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    const handleLogout = async () => {
        try {
            await axios.post('/api/logout');
            setUser(null);
            localStorage.removeItem('user');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <Router>
            <Disclaimer/>
            <div className="App">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <HomePage user={user} openLoginModal={openLoginModal}/>
                            </Layout>
                        }
                    />

                    <Route
                        path="/research"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <ResearchPage/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/instructions"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <InstructionsPage/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/resources"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <ResourcesPage user={user} openLoginModal={openLoginModal}/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/calendar"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <CalendarPage user={user} openLoginModal={openLoginModal}/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/About"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <AboutPage/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/upload-documents"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin', 'Archivist']}>
                                    <DocumentManagement user={user}/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/view-documents"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={["Admin", "Archivist", "Member", "Researcher"]}>
                                    <ViewDocuments user={user}/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    <Route
                        path="/admin"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin', 'Archivist']}>
                                    <AdminPage user={user}/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                    {/* NEW: Analytics Route */}
                    <Route
                        path="/analytics"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin', 'Archivist']}>
                                    <AnalyticsPage/>
                                </ProtectedRoute>
                            </Layout>
                        }
                    />
                </Routes>

                {/* Login/Register Modal */}
                <LoginRegisterPage
                    isOpen={isLoginModalOpen}
                    closeModal={closeLoginModal}
                    setUser={(newUser) => {
                        setUser(newUser);
                        localStorage.setItem('user', JSON.stringify(newUser));
                    }}
                />
            </div>
            <Footer/>
        </Router>
    );
}

export default App;
