import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import BuildingDetailsModal from "./BuildingDetailsModal";
import LodgeDetailsModal from "./LodgeDetailsModal";
import MemberDetailsModal from "./MemberDetailsModal";
import MeetingDetailsModal from "./MeetingDetailsModal";
import StatsMeetings from "./StatsMeetings"; // Import the new StatsMeetings
import StatisticsModal from "./StatisticsModal";
import "./ResourcesPage.css";
import PersonalAccountsModal from "./PersonalAccountsModal";
import HighlightsModal from "./HighlightsModal"; // Import HighlightsModal

function ResourcesPage({user, openLoginModal}) {
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedLodge, setSelectedLodge] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [statsData, setStatsData] = useState(null); // State for storing meeting stats
    const [currentModal, setCurrentModal] = useState(null);
    const [highlightYear, setHighlightYear] = useState(null); // State for year in highlights
    // const [personalAccounts, setPersonalAccounts] = useState(null);

    const sections = [
        {
            name: "Yearly Highlights",
            icon: "/images/highlights.png",
            allowedRoles: ["Admin", "Archivist","Researcher"],
            action: () => {
                if (user && ["Admin", "Archivist","Researcher"].includes(user.role)) {
                    const currentYear = new Date().getFullYear(); // Use current year as default
                    setHighlightYear(currentYear);
                    setCurrentModal("highlights");
                    setModalOpen(true);
                } else {
                    openLoginModal();
                }
            },
        },

        {
            name: "Temples",
            icon: "/images/buildings.png",
            route: null,
            allowedRoles: ["Admin", "Archivist","Researcher"],
            action: async () => {
                if (user && ["Admin", "Archivist","Researcher"].includes(user.role)) {
                    try {
                        const response = await axios.get("/api/buildings");
                        setSelectedBuilding(response.data);
                        setCurrentModal("building");
                        setModalOpen(true);
                    } catch (error) {
                        console.error("Error fetching buildings:", error);
                    }
                } else {
                    openLoginModal();
                }
            },
        },
        {
            name: "Lodges",
            icon: "/images/lodges.png",
            route: null,
            allowedRoles: ["Admin", "Archivist","Researcher"],
            action: async () => {
                if (user && ["Admin", "Archivist","Researcher"].includes(user.role)) {
                    try {
                        const response = await axios.get("/api/resources/lodges");
                        setSelectedLodge(response.data);
                        setCurrentModal("lodge");
                        setModalOpen(true);
                    } catch (error) {
                        console.error("Error fetching lodges:", error);
                    }
                } else {
                    openLoginModal();
                }
            },
        },
        {
            name: "Members",
            icon: "/images/members.png",
            route: null,
            allowedRoles: ["Admin", "Archivist","Researcher", "Member"],
            action: async () => {
                if (user && ["Admin", "Archivist","Researcher", "Member"].includes(user.role)) {
                    try {
                        const response = await axios.get("/api/resources/members");
                        setSelectedMember(response.data);
                        setCurrentModal("member");
                        setModalOpen(true);
                    } catch (error) {
                        console.error("Error fetching members:", error);
                    }
                } else {
                    openLoginModal();
                }
            },
        },
        // {
        //     name: "Member Stories & Communications",
        //     icon: "/images/person.png",
        //     allowedRoles: ["Admin", "Archivist","Member"], // Grant access to appropriate roles
        //     action: async () => {
        //         if (user && ["Admin", "Archivist"].includes(user.role)) {
        //             try {
        //                 const response = await axios.get("/api/personal_accounts"); // Ensure this API exists
        //                 setPersonalAccounts(response.data);
        //                 setCurrentModal("personalAccounts");
        //                 setModalOpen(true);
        //             } catch (error) {
        //                 console.error("Error fetching personal accounts:", error);
        //             }
        //         } else {
        //             openLoginModal();
        //         }
        //     },
        // },
        {
            name: "Meetings",
            icon: "/images/meetings.png",
            route: null,
            allowedRoles: ["Admin", "Archivist","Researcher"],
            action: async () => {
                if (user && ["Admin", "Archivist","Researcher"].includes(user.role)) {
                    try {
                        const response = await axios.get("/api/meetings");
                        setSelectedMeeting(response.data);
                        setCurrentModal("meeting");
                        setModalOpen(true);
                    } catch (error) {
                        console.error("Error fetching meetings:", error);
                    }
                } else {
                    openLoginModal();
                }
            },
        },
        // {
        //     name: "Statistics",
        //     icon: "/images/stats.png",
        //     route: null,
        //     allowedRoles: ["Admin", "Archivist","Member"],
        //     action: async () => {
        //         if (user && ["Admin", "Archivist","Member"].includes(user.role)) {
        //             try {
        //                 // Fetch meeting statistics
        //                 const response = await axios.get("/api/stats/meetings");
        //                 setStatsData(response.data);
        //                 setCurrentModal("stats");
        //                 setModalOpen(true);
        //             } catch (error) {
        //                 console.error("Error fetching meeting statistics:", error);
        //             }
        //         } else {
        //             openLoginModal();
        //         }
        //     },
        // },

        {
            name: "Statistics",
            icon: "/images/stats.png",
            route: null,
            allowedRoles: ["Admin", "Archivist", "Member","Researcher"],
            action: () => {
                if (user && ["Admin", "Archivist", "Member","Researcher"].includes(user.role)) {
                    setCurrentModal("statistics");
                    setModalOpen(true);
                } else {
                    openLoginModal();
                }
            },
        },
    ];

    const handleSectionClick = (section) => {
        if (section.route) {
            if (user && section.allowedRoles.includes(user.role)) {
                navigate(section.route);
            } else {
                openLoginModal();
            }
        } else if (section.action) {
            section.action();
        }
    };

    return (
        <div className="resources-page-container">
            <div className="resources-header">
                <h1>Resources</h1>
                <p>
                    Manage and explore all aspects of the Connaught Research Lodge, including temples, lodges, members,
                    meetings, and now statistics.
                </p>
            </div>

            <section className="resources-grid">
                {sections.map((section) => {
                    const hasAccess = user && section.allowedRoles.includes(user.role);
                    return (
                        <div
                            key={section.name}
                            className={`resources-card ${hasAccess ? "" : "resources-card-disabled"}`}
                            onClick={() => handleSectionClick(section)}
                            role="button"
                            tabIndex={0}
                            aria-label={`Navigate to ${section.name}`}
                        >
                            <img
                                src={section.icon}
                                alt={`${section.name} icon`}
                                className="resources-card-icon"
                            />
                            <h3 className="resources-card-title">{section.name}</h3>
                        </div>
                    );
                })}
            </section>

            {/* Conditional rendering for modals */}
            {modalOpen && currentModal === "building" && selectedBuilding && (
                <BuildingDetailsModal
                    buildingData={selectedBuilding}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedBuilding(null);
                    }}
                />
            )}

            {modalOpen && currentModal === "lodge" && selectedLodge && (
                <LodgeDetailsModal
                    lodgeData={selectedLodge}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedLodge(null);
                    }}
                />
            )}

            {modalOpen && currentModal === "member" && selectedMember && (
                <MemberDetailsModal
                    memberData={selectedMember}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedMember(null);
                    }}
                />
            )}

            {modalOpen && currentModal === "meeting" && selectedMeeting && (
                <MeetingDetailsModal
                    meetingData={selectedMeeting}
                    onClose={() => {
                        setModalOpen(false);
                        setSelectedMeeting(null);
                    }}
                />
            )}

            {modalOpen && currentModal === "highlights" && highlightYear && (
                <HighlightsModal
                    year={highlightYear}
                    onClose={() => {
                        setModalOpen(false);
                        setHighlightYear(null);
                    }}
                />
            )}


            {/*{modalOpen && currentModal === "stats" && statsData && (*/}
            {/*    <StatsMeetings*/}
            {/*        statsData={statsData}*/}
            {/*        onClose={() => {*/}
            {/*            setModalOpen(false);*/}
            {/*            setStatsData(null);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}

            {modalOpen && currentModal === "statistics" && (
                <StatisticsModal onClose={() => setModalOpen(false)} />
            )}

            {/*{modalOpen && currentModal === "personalAccounts" && personalAccounts && (*/}
            {/*    <PersonalAccountsModal*/}
            {/*        accountsData={personalAccounts} // Pass fetched data to the modal*/}
            {/*        onClose={() => {*/}
            {/*            setModalOpen(false);*/}
            {/*            setPersonalAccounts(null);*/}
            {/*        }}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
    );
}

export default ResourcesPage;
