import React, { useState, useEffect } from "react";
import axios from "axios";
import MeetingAttendeesModal from "./MeetingAttendeesModal";
import "./MeetingDetailsModal.css";
import Select from "react-select"; // Import react-select

function MeetingDetailsModal({ meetingData, onClose }) {
    const [meetings, setMeetings] = useState(meetingData || []);
    const [editingMeeting, setEditingMeeting] = useState(null);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [lodges, setLodges] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [attendeesModalOpen, setAttendeesModalOpen] = useState(false);
    const [selectedMeetingId, setSelectedMeetingId] = useState(null);

    const [selectedFilterLodge, setSelectedFilterLodge] = useState("");

    useEffect(() => {
        fetchLodges();
        fetchEventTypes();
    }, []);

    // Fetch meetings whenever selectedFilterLodge changes
    useEffect(() => {
        fetchMeetings();
    }, [selectedFilterLodge]);

    const fetchMeetings = async () => {
        try {
            const params = {};
            if (selectedFilterLodge) params.lodge_id = selectedFilterLodge;
            const res = await axios.get("/api/meetings", { params });
            setMeetings(res.data);
        } catch (error) {
            console.error("Error fetching meetings:", error);
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            setLodges(
                res.data.map((lodge) => ({
                    value: lodge.id, // Unique identifier
                    label: lodge.name, // Display name
                }))
            );
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchEventTypes = async () => {
        try {
            const res = await axios.get("/api/event-types-with-id");
            setEventTypes(
                res.data.map((eventType) => ({
                    value: eventType.id, // Unique identifier
                    label: eventType.description, // Display description
                }))
            );
        } catch (error) {
            console.error("Error fetching event types:", error);
        }
    };

    const formatToSAST = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "";
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST: UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0]; // returns YYYY-MM-DD
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: null }));
        }
    };

    const validateMeeting = () => {
        const newErrors = {};
        if (!formData.lodge_id) newErrors.lodge_id = "Lodge is required";
        if (!formData.eventtype_id) newErrors.eventtype_id = "Event type is required";
        if (!formData.meeting_date) newErrors.meeting_date = "Meeting date is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateMeeting()) return;

        // Parse document_ids from comma-separated string into an array
        let documentIdsArray = [];
        if (formData.document_ids && formData.document_ids.trim() !== "") {
            documentIdsArray = formData.document_ids.split(",").map(id => id.trim()).filter(id => id !== "");
        }

        const payload = {
            ...formData,
            document_ids: documentIdsArray
        };

        try {
            let res;
            if (editingMeeting) {
                res = await axios.put(`/api/meetings/${editingMeeting.id}`, payload);
                setMeetings((prev) => prev.map((m) => (m.id === editingMeeting.id ? res.data : m)));
            } else {
                res = await axios.post("/api/meetings", payload);
                setMeetings((prev) => [...prev, res.data]);
            }
            setEditingMeeting(null);
            setFormData({});
        } catch (error) {
            console.error("Error saving meeting:", error);
            alert("Failed to save meeting.");
        }
    };

    const handleEdit = (meeting) => {
        setEditingMeeting(meeting);

        // Convert document_ids array to a comma-separated string for editing
        let docs = "";
        if (meeting.document_ids && meeting.document_ids.length > 0) {
            docs = meeting.document_ids.join(", ");
        }

        setFormData({
            lodge_id: lodges.find((lodge) => lodge.value === meeting.lodge_id) || null,
            eventtype_id: eventTypes.find((event) => event.value === meeting.eventtype_id) || null,
            meeting_date: formatToSAST(meeting.meeting_date),
            description: meeting.description || "",
            document_ids: meeting.document_ids ? meeting.document_ids.join(", ") : "",
        });
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this meeting?")) {
            try {
                await axios.delete(`/api/meetings/${id}`);
                setMeetings((prev) => prev.filter((m) => m.id !== id));
            } catch (error) {
                console.error("Error deleting meeting:", error);
                alert("Failed to delete meeting.");
            }
        }
    };

    const handleManageAttendees = (meetingId) => {
        setSelectedMeetingId(meetingId);
        setAttendeesModalOpen(true);
    };

    return (
        <div className="md-modal-overlay">
            <div className="md-modal-content">
                <div className="md-modal-header">
                    <h2>Meetings</h2>
                    <button className="md-modal-close-btn" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="md-modal-body">
                    {/* Lodge Filter Dropdown */}
                    <div className="md-filter-group">
                        <label htmlFor="filter-lodge">Filter by Lodge:</label>
                        <Select
                            id="filter-lodge"
                            options={[{ value: "", label: "All Lodges" }, ...lodges]} // Add "All Lodges" option
                            value={lodges.find((lodge) => lodge.value === selectedFilterLodge) || { value: "", label: "All Lodges" }}
                            onChange={(selectedOption) => setSelectedFilterLodge(selectedOption ? selectedOption.value : "")}
                            placeholder="Filter by Lodge"
                            isClearable
                            isSearchable
                        />
                    </div>

                    <ul className="md-meeting-list">
                        {meetings.map((meeting) => {
                            const lodgeName = lodges.find((lodge) => lodge.value === meeting.lodge_id)?.label || "Unknown Lodge";
                            const eventDesc = eventTypes.find((event) => event.value === meeting.eventtype_id)?.label || "Unknown Type";
                            const formattedDate = formatToSAST(meeting.meeting_date);
                            return (
                                <li key={meeting.id} className="md-meeting-item">
                                    <strong>{lodgeName}</strong>
                                    <p>
                                        {eventDesc}<br/>
                                        {formattedDate}<br/>
                                        {meeting.description}
                                        {meeting.document_ids && meeting.document_ids.length > 0 && (
                                            <>
                                                <br />
                                                Linked Documents: {meeting.document_ids.join(", ")}
                                            </>
                                        )}
                                    </p>
                                    <div className="md-meeting-buttons">
                                        <button className="md-meeting-edit-btn" onClick={() => handleEdit(meeting)}>
                                            Edit
                                        </button>
                                        <button className="md-meeting-delete-btn" onClick={() => handleDelete(meeting.id)}>
                                            Delete
                                        </button>
                                        <button className="md-meeting-attendees-btn" onClick={() => handleManageAttendees(meeting.id)}>
                                            Manage Attendees
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <form className="md-meeting-form">
                        <h3>{editingMeeting ? "Edit Meeting" : "Add New Meeting"}</h3>

                        <label>Lodge</label>
                        <Select
                            name="lodge_id"
                            options={lodges} // Options already formatted
                            value={lodges.find((lodge) => lodge.value === formData.lodge_id) || null}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, lodge_id: selectedOption ? selectedOption.value : null })
                            }
                            placeholder="Select Lodge"
                            className={errors.lodge_id ? "md-error" : ""}
                            isSearchable
                        />
                        {errors.lodge_id && <p className="md-error-message">{errors.lodge_id}</p>}

                        <label>Event Type</label>
                        <Select
                            name="eventtype_id"
                            options={eventTypes} // Options already formatted
                            value={eventTypes.find((event) => event.value === formData.eventtype_id) || null}
                            onChange={(selectedOption) =>
                                setFormData({ ...formData, eventtype_id: selectedOption ? selectedOption.value : null })
                            }
                            placeholder="Select Event Type"
                            className={errors.eventtype_id ? "md-error" : ""}
                            isSearchable
                        />
                        {errors.eventtype_id && <p className="md-error-message">{errors.eventtype_id}</p>}

                        <label>Meeting Date</label>
                        <input
                            type="date"
                            name="meeting_date"
                            value={formData.meeting_date || ""}
                            onChange={handleInputChange}
                            className={errors.meeting_date ? "md-error" : ""}
                        />
                        {errors.meeting_date && <p className="md-error-message">{errors.meeting_date}</p>}

                        <label>Description</label>
                        <textarea
                            name="description"
                            value={formData.description || ""}
                            onChange={handleInputChange}
                        />

                        <label>Document IDs (Comma-separated)</label>
                        <input
                            type="text"
                            name="document_ids"
                            value={formData.document_ids || ""}
                            onChange={handleInputChange}
                            placeholder="e.g. uuid1, uuid2, uuid3"
                        />

                        <button type="button" className="md-meeting-save-btn" onClick={handleSave}>
                            {editingMeeting ? "Update" : "Add"}
                        </button>
                    </form>
                </div>
                {/*<div className="md-modal-footer">*/}
                {/*    <button className="md-modal-close-btn" onClick={onClose}>*/}
                {/*        Close*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>

            {attendeesModalOpen && selectedMeetingId && (
                <MeetingAttendeesModal
                    meetingId={selectedMeetingId}
                    onClose={() => setAttendeesModalOpen(false)}
                />
            )}
        </div>
    );
}

export default MeetingDetailsModal;
