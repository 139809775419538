import React, {useEffect, useState} from "react";
import axios from "axios";
// import "./MembershipDegreesModal.css";
import "./MembershipHistoryModal.css";
import Select from "react-select";

function MembershipDegreesModal({memberId, onClose}) {
    const [degrees, setDegrees] = useState([]);
    const [availableDegrees, setAvailableDegrees] = useState([]);
    const [membershipHistory, setMembershipHistory] = useState([]);
    const [lodges, setLodges] = useState([]);
    const [savedDegrees, setSavedDegrees] = useState([]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        if (memberId) {
            loadData();
        }
    }, [memberId]);

    const loadData = async () => {
        try {
            await fetchMembershipHistory();
            await fetchDegrees();
            await fetchAvailableDegrees();
            await fetchSavedDegrees();
        } catch (error) {
            console.error("Error loading data:", error);
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/resources/lodges");
            setLodges(res.data);
            return res.data;
        } catch (error) {
            console.error("Error fetching lodges:", error);
            return [];
        }
    };

    const fetchMembershipHistory = async () => {
        try {
            const lodgesData = await fetchLodges();
            const res = await axios.get(`/api/resources/members/${memberId}/history`);
            const formattedHistory = res.data.map((history) => {
                const lodge = lodgesData.find((lodge) => lodge.id === history.lodge_id);
                return {
                    ...history,
                    lodge_name: lodge ? lodge.name : "Unknown Lodge",
                    start_date: formatToSAST(history.start_date),
                    end_date: history.end_date ? formatToSAST(history.end_date) : "Present",
                };
            });
            setMembershipHistory(formattedHistory);
        } catch (error) {
            console.error("Error fetching membership history:", error);
        }
    };

    const fetchDegrees = async () => {
        try {
            const res = await axios.get(`/api/resources/members/${memberId}/degrees`);
            setDegrees(res.data.map((item) => ({
                ...item,
                date_obtained: formatToSAST(item.date_obtained),
            })));
        } catch (error) {
            console.error("Error fetching degrees:", error);
        }
    };

    const fetchAvailableDegrees = async () => {
        try {
            const res = await axios.get("/api/resources/degrees");
            setAvailableDegrees(res.data);
        } catch (error) {
            console.error("Error fetching available degrees:", error);
        }
    };

    const fetchSavedDegrees = async () => {
        try {
            const res = await axios.get(`/api/resources/members/${memberId}/saved-degrees`);
            setSavedDegrees(res.data.map((degree) => ({
                ...degree,
                date_obtained: formatToSAST(degree.date_obtained),
            })));
        } catch (error) {
            console.error("Error fetching saved degrees:", error);
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target || {}; // Handle both standard inputs and react-select
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors[name]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: null}));
        }
    };

    const validateForm = () => {
        const requiredFields = ["membership_history_id", "degree_id", "date_obtained"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const clearForm = () => {
        setFormData({});
        setEditingId(null);
        setErrors({});
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        const confirmSave = window.confirm(
            editingId
                ? "Are you sure you want to update this degree?"
                : "Are you sure you want to add this degree?"
        );
        if (!confirmSave) return;

        try {
            const payload = {
                ...formData,
                date_obtained: formatToSAST(formData.date_obtained),
                associated_documents: formData.associated_documents
                    ? formData.associated_documents.split(",").map((id) => id.trim())
                    : [],
            };

            if (editingId) {
                await axios.put(
                    `/api/resources/membership-history/${formData.membership_history_id}/degrees/${editingId}`,
                    payload
                );
            } else {
                await axios.post(
                    `/api/resources/membership-history/${formData.membership_history_id}/degrees`,
                    payload
                );
            }

            fetchSavedDegrees();
            clearForm();
            alert("Degree saved successfully!");
        } catch (error) {
            console.error("Error saving degree:", error);
            alert("Failed to save degree.");
        }
    };

    const handleEdit = (item) => {
        setEditingId(item.id);
        setFormData({
            membership_history_id: item.membership_history_id,
            degree_id: item.degree_id,
            date_obtained: formatToSAST(item.date_obtained),
            comments: item.comments || "",
            associated_documents: (item.associated_documents || []).join(", "),
        });
    };

    const handleDelete = async (degreeId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this degree?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/resources/members/${memberId}/saved-degrees/${degreeId}`);
            fetchSavedDegrees();
            alert("Degree deleted successfully!");
        } catch (error) {
            console.error("Error deleting degree:", error);
            alert("Failed to delete degree.");
        }
    };

    const formatToSAST = (dateString) => {
        if (!dateString) return "Present";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "Invalid Date";
        const offsetInMs = 2 * 60 * 60 * 1000;
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };
    return (
        <div className="membership-history-modal-overlay">
            <div className="membership-history-modal-content">
                <button
                    className="membership-history-modal-close-btn"
                    onClick={() => {
                        clearForm();
                        onClose();
                    }}
                >
                    &times;
                </button>
                <h2 className="membership-history-modal-title">Membership Degrees</h2>
                <div className="membership-history-scroll-container">
                    <div className="membership-history-form-group">
                        <label className="membership-degrees-label">Saved Degrees</label>
                        <ul className="membership-history-list">
                            {savedDegrees.map((degree) => (
                                <li key={degree.id} className="membership-history-item">
                                    <p>
                                        {availableDegrees.find((d) => d.id === degree.degree_id)?.name || "Unknown Degree"} -
                                        Obtained:{" "}
                                        {degree.date_obtained}
                                    </p>
                                    <div className="membership-history-buttons">
                                        <button
                                            className="membership-history-edit-btn"
                                            onClick={() => handleEdit(degree)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className="membership-history-delete-btn"
                                            onClick={() => handleDelete(degree.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <button onClick={clearForm} className="membership-history-clear-btn">
                        Add New Degree
                    </button>

                    {/* Form Fields */}
                    <div className="membership-history-form-group">
                        <label className="membership-history-label">Membership History</label>
                        <Select
                            options={membershipHistory.map((history) => ({
                                value: history.id,
                                label: `${history.lodge_name} - ${history.membership_type} (${history.start_date} to ${history.end_date})`,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({
                                    target: {
                                        name: "membership_history_id",
                                        value: selectedOption ? selectedOption.value : ""
                                    }
                                })
                            }
                            value={
                                membershipHistory.find((history) => history.id === formData.membership_history_id)
                                    ? {
                                        value: formData.membership_history_id,
                                        label: membershipHistory.find((history) => history.id === formData.membership_history_id).lodge_name,
                                    }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Select Membership History"
                            className={`membership-history-select ${
                                errors.membership_history_id ? "membership-history-error-field" : ""
                            }`}
                        />
                        {errors.membership_history_id && (
                            <p className="membership-history-error">{errors.membership_history_id}</p>
                        )}
                    </div>

                    <div className="membership-history-form-group">
                        <label className="membership-history-label">Degree</label>
                        <Select
                            options={availableDegrees.map((degree) => ({
                                value: degree.id,
                                label: degree.name,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({
                                    target: {
                                        name: "degree_id",
                                        value: selectedOption ? selectedOption.value : ""
                                    }
                                })
                            }
                            value={
                                availableDegrees.find((degree) => degree.id === formData.degree_id)
                                    ? {
                                        value: formData.degree_id,
                                        label: availableDegrees.find((degree) => degree.id === formData.degree_id).name,
                                    }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Select Degree"
                            className={`membership-history-select ${
                                errors.degree_id ? "membership-history-error-field" : ""
                            }`}
                        />
                        {errors.degree_id && <p className="membership-history-error">{errors.degree_id}</p>}
                    </div>

                    <div className="membership-history-form-group">
                        <label className="membership-history-label">Date Obtained</label>
                        <input
                            type="date"
                            name="date_obtained"
                            value={formData.date_obtained || ""}
                            onChange={handleInputChange}
                            className={`membership-history-input ${
                                errors.date_obtained ? "membership-history-error-field" : ""
                            }`}
                        />
                        {errors.date_obtained && <p className="membership-history-error">{errors.date_obtained}</p>}
                    </div>

                    <div className="membership-history-form-group">
                        <label className="membership-history-label">Comments</label>
                        <textarea
                            name="comments"
                            value={formData.comments || ""}
                            onChange={handleInputChange}
                            className="membership-history-textarea"
                        />
                    </div>
                    <div className="membership-history-form-group">
                        <label>Associated Documents (IDs comma separated)</label>
                        <input
                            type="text"
                            name="associated_documents"
                            value={formData.associated_documents || ""}
                            onChange={handleInputChange}
                            className={`membership-history-associated-documents ${
                                errors.associated_documents ? "error" : ""
                            }`}
                        />
                        {errors.associated_documents && <p className="error-message">{errors.associated_documents}</p>}
                    </div>

                    <button onClick={handleSave} className="membership-history-save-btn">
                        {editingId ? "Update" : "Add New"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MembershipDegreesModal;
