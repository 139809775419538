import React, {useEffect, useState} from "react";
import axios from "axios";
import "./MembershipHistoryModal.css";
import Select from "react-select";

function MembershipHistoryModal({memberId, onClose}) {
    const [history, setHistory] = useState([]);
    const [lodges, setLodges] = useState([]);
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [membershipStatuses, setMembershipStatuses] = useState([]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [editingId, setEditingId] = useState(null);

    useEffect(() => {
        if (memberId) {
            fetchHistory();
        }
        fetchLodges();
        fetchMembershipTypes();
        fetchMembershipStatuses();
    }, [memberId]);

    // Utility Functions for Date Handling
    const toSASTDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0]; // Return only YYYY-MM-DD
    };
    const fetchHistory = async () => {
        try {
            const res = await axios.get(`/api/resources/members/${memberId}/history`);
            const formattedHistory = res.data.map((item) => ({
                ...item,
                start_date: toSASTDate(item.start_date),
                end_date: item.end_date ? toSASTDate(item.end_date) : null,
            }));
            setHistory(formattedHistory);
        } catch (error) {
            console.error("Error fetching membership history:", error);
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/resources/lodges");
            setLodges(res.data);
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchMembershipTypes = async () => {
        try {
            const res = await axios.get("/api/membership-types");
            setMembershipTypes(res.data);
        } catch (error) {
            console.error("Error fetching membership types:", error);
        }
    };

    const fetchMembershipStatuses = async () => {
        try {
            const res = await axios.get("/api/membership-statuses");
            setMembershipStatuses(res.data);
        } catch (error) {
            console.error("Error fetching membership statuses:", error);
        }
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target || {}; // Handle both standard inputs and react-select
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors[name]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: null}));
        }
    };

    const validateForm = () => {
        const requiredFields = ["lodge_id", "membership_type_id", "membership_status_id", "start_date"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const payload = {
                ...formData,
                start_date: toSASTDate(formData.start_date),
                end_date: toSASTDate(formData.end_date),
            };

            if (editingId) {
                await axios.put(`/api/resources/members/${memberId}/history/${editingId}`, payload);
            } else {
                await axios.post(`/api/resources/members/${memberId}/history`, payload);
            }

            fetchHistory();
            setFormData({});
            setEditingId(null);
            alert("Membership history saved successfully!");
        } catch (error) {
            console.error("Error saving membership history:", error);
            alert("Failed to save membership history.");
        }
    };

    const handleEdit = (item) => {
        setEditingId(item.id);
        setFormData({
            lodge_id: item.lodge_id,
            membership_type_id: item.membership_type_id,
            membership_status_id: item.membership_status_id,
            start_date: item.start_date,
            end_date: item.end_date || "",
            comments: item.comments || "",
        });
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this record?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/resources/members/${memberId}/history/${id}`);
            fetchHistory();
            alert("Membership history deleted successfully!");
        } catch (error) {
            console.error("Error deleting membership history:", error);
            alert("Failed to delete membership history.");
        }
    };

    return (
        <div className="membership-history-modal-overlay">
            <div className="membership-history-modal-content">
                <button className="membership-history-modal-close-btn" onClick={onClose}>
                    &times;
                </button>
                <h2 className="membership-history-modal-title">Membership History</h2>
                <div className="membership-history-scroll-container">
                    <ul className="membership-history-list">
                        {history.map((item) => (
                            <li key={item.id} className="membership-history-item">
                                <strong>
                                    {lodges.find((lodge) => lodge.id === item.lodge_id)?.name || "Unknown Lodge"}
                                </strong>
                                <p>
                                    {item.membership_type_name} - {item.membership_status_name} (
                                    {item.start_date} to {item.end_date || "Present"})
                                </p>
                                <div className="membership-history-buttons">
                                    <button
                                        className="membership-history-edit-btn"
                                        onClick={() => handleEdit(item)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="membership-history-delete-btn"
                                        onClick={() => handleDelete(item.id)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>

                    {/* Form Section */}
                    <div className="membership-history-form">
                        {/* Lodge Dropdown */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">Lodge</label>
                            <Select
                                options={lodges.map((lodge) => ({
                                    value: lodge.id,
                                    label: lodge.name,
                                }))}
                                onChange={(selectedOption) =>
                                    handleInputChange({
                                        target: {
                                            name: "lodge_id",
                                            value: selectedOption ? selectedOption.value : ""
                                        }
                                    })
                                }
                                value={
                                    lodges.find((lodge) => lodge.id === formData.lodge_id)
                                        ? {
                                            value: formData.lodge_id,
                                            label: lodges.find((lodge) => lodge.id === formData.lodge_id).name,
                                        }
                                        : null
                                }
                                isSearchable
                                isClearable
                                placeholder="Select Lodge"
                                className={`membership-history-select ${errors.lodge_id ? "membership-history-error-field" : ""}`}
                            />
                            {errors.lodge_id && <p className="membership-history-error">{errors.lodge_id}</p>}
                        </div>

                        {/* Membership Type Dropdown */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">Membership Type</label>
                            <Select
                                options={membershipTypes.map((type) => ({
                                    value: type.id,
                                    label: type.name,
                                }))}
                                onChange={(selectedOption) =>
                                    handleInputChange({
                                        target: {
                                            name: "membership_type_id",
                                            value: selectedOption ? selectedOption.value : ""
                                        }
                                    })
                                }
                                value={
                                    membershipTypes.find((type) => type.id === formData.membership_type_id)
                                        ? {
                                            value: formData.membership_type_id,
                                            label: membershipTypes.find((type) => type.id === formData.membership_type_id).name,
                                        }
                                        : null
                                }
                                isSearchable
                                isClearable
                                placeholder="Select Membership Type"
                                className={`membership-history-select ${errors.membership_type_id ? "membership-history-error-field" : ""}`}
                            />
                            {errors.membership_type_id && (
                                <p className="membership-history-error">{errors.membership_type_id}</p>
                            )}
                        </div>

                        {/* Membership Status Dropdown */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">Status</label>
                            <Select
                                options={membershipStatuses.map((status) => ({
                                    value: status.id,
                                    label: status.name,
                                }))}
                                onChange={(selectedOption) =>
                                    handleInputChange({
                                        target: {
                                            name: "membership_status_id",
                                            value: selectedOption ? selectedOption.value : ""
                                        }
                                    })
                                }
                                value={
                                    membershipStatuses.find((status) => status.id === formData.membership_status_id)
                                        ? {
                                            value: formData.membership_status_id,
                                            label: membershipStatuses.find((status) => status.id === formData.membership_status_id).name,
                                        }
                                        : null
                                }
                                isSearchable
                                isClearable
                                placeholder="Select Membership Status"
                                className={`membership-history-select ${errors.membership_status_id ? "membership-history-error-field" : ""}`}
                            />
                            {errors.membership_status_id && (
                                <p className="membership-history-error">{errors.membership_status_id}</p>
                            )}
                        </div>

                        {/* Start Date */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">Start Date</label>
                            <input
                                type="date"
                                name="start_date"
                                value={formData.start_date || ""}
                                onChange={handleInputChange}
                                className={`membership-history-input ${errors.start_date ? "membership-history-error-field" : ""}`}
                            />
                            {errors.start_date && <p className="membership-history-error">{errors.start_date}</p>}
                        </div>

                        {/* End Date */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">End Date</label>
                            <input
                                type="date"
                                name="end_date"
                                value={formData.end_date || ""}
                                onChange={handleInputChange}
                                className="membership-history-input"
                            />
                        </div>

                        {/* Comments */}
                        <div className="membership-history-form-group">
                            <label className="membership-history-label">Comments</label>
                            <textarea
                                name="comments"
                                value={formData.comments || ""}
                                onChange={handleInputChange}
                                className="membership-history-textarea"
                            />
                        </div>

                        <button onClick={handleSave} className="membership-history-save-btn">
                            {editingId ? "Update" : "Add New"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MembershipHistoryModal;
