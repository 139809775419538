import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LodgeDetailsModal.css";
import Select from "react-select";

function LodgeDetailsModal({ onClose, onSave }) {
    const [lodges, setLodges] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [lodgeTypes, setLodgeTypes] = useState([]);
    const [constitutions, setConstitutions] = useState([]);

    const [selectedLodge, setSelectedLodge] = useState(null);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

    const [locationHistory, setLocationHistory] = useState([]);
    const [locationForm, setLocationForm] = useState({});
    const [editingLocationId, setEditingLocationId] = useState(null);

    // Fetch data on component mount
    useEffect(() => {
        fetchLodges();
        fetchBuildings();
        fetchLodgeTypes();
        fetchConstitutions();
    }, []);

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/resources/lodges");
            setLodges(res.data);
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchBuildings = async () => {
        try {
            const res = await axios.get("/api/buildings");
            setBuildings(res.data);
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    const fetchLodgeTypes = async () => {
        try {
            const res = await axios.get("/api/resources/lodge-types");
            setLodgeTypes(res.data);
        } catch (error) {
            console.error("Error fetching lodge types:", error);
        }
    };

    const fetchConstitutions = async () => {
        try {
            const res = await axios.get("/api/resources/constitutions");
            setConstitutions(res.data);
        } catch (error) {
            console.error("Error fetching constitutions:", error);
        }
    };

    // Convert UTC date to SAST date (YYYY-MM-DD)
    const toSAST = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000; // UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };

    const fetchLocationHistory = async (lodgeId) => {
        try {
            const res = await axios.get(`/api/resources/lodges/${lodgeId}/location-history`);
            const history = res.data.map((loc) => ({
                ...loc,
                start_date: toSAST(loc.start_date),
                end_date: loc.end_date ? toSAST(loc.end_date) : null,
            }));
            setLocationHistory(history);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.warn("No location history found for this lodge.");
                setLocationHistory([]);
            } else {
                console.error("Error fetching location history:", error);
            }
        }
    };

    // Handle lodge selection from the dropdown
    const handleLodgeSelect = async (id) => {
        if (!id) {
            setSelectedLodge(null);
            setFormData({ date_established: "", date_decommissioned: "" }); // Clear dates
            setLocationHistory([]);
            return;
        }

        try {
            const res = await axios.get(`/api/resources/lodges/${id}`);
            setSelectedLodge(res.data);

            setFormData({
                ...res.data,
                date_established: res.data.date_established
                    ? toSAST(res.data.date_established)  // Ensure correct format
                    : "",
                date_decommissioned: res.data.date_decommissioned
                    ? toSAST(res.data.date_decommissioned)  // Ensure correct format
                    : "",
                warrant_document_id: (res.data.warrant_document_id || []).join(", "),
                general_document_id: (res.data.general_document_id || []).join(", "),
            });

            fetchLocationHistory(id);
        } catch (error) {
            console.error("Error fetching lodge details:", error);
        }
    };

    // Handle text inputs and textareas
    const handleInputChange = (e) => {
        const { name, value } = e.target || {};
        setFormData({ ...formData, [name]: value });

        if (errors[name]) {
            setErrors((prev) => ({ ...prev, [name]: null }));
        }
    };

    // Validate required fields
    const validateForm = () => {
        const requiredFields = [
            "code",
            "name",
            "year_established",
            "status",
            "lodge_type_id",
            "constitution_id",
        ];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field].trim() === "") {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Save or Update Lodge
    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const payload = {
                ...formData,
                warrant_document_id: formData.warrant_document_id
                    ? formData.warrant_document_id.split(",").map((id) => id.trim())
                    : [],
                general_document_id: formData.general_document_id
                    ? formData.general_document_id.split(",").map((id) => id.trim())
                    : [],
                date_established: formData.date_established || null, // Include date_established
                date_decommissioned: formData.date_decommissioned || null, // Include date_decommissioned
            };

            if (selectedLodge) {
                await axios.put(`/api/resources/lodges/${selectedLodge.id}`, payload);
            } else {
                alert("No lodge selected.");
                return;
            }

            fetchLodges();
            alert("Lodge saved successfully!");
            if (onSave) onSave();
        } catch (error) {
            console.error("Error saving lodge:", error);
            alert("Error saving lodge. Please try again.");
        }
    };

    // Handle changes in the location form
    const handleLocationInputChange = (e) => {
        const { name, value } = e.target || {};
        setLocationForm({ ...locationForm, [name]: value });
    };

    // Save or Update a location record
    const handleSaveLocation = async () => {
        if (!selectedLodge) {
            alert("Please select a lodge first.");
            return;
        }
        try {
            const payload = {
                ...locationForm,
                start_date: locationForm.start_date
                    ? new Date(locationForm.start_date).toISOString()
                    : null,
                end_date: locationForm.end_date
                    ? new Date(locationForm.end_date).toISOString()
                    : null,
            };

            if (editingLocationId) {
                await axios.put(
                    `/api/resources/lodges/location-history/${editingLocationId}`,
                    payload
                );
            } else {
                await axios.post(
                    `/api/resources/lodges/${selectedLodge.id}/location-history`,
                    payload
                );
            }

            fetchLocationHistory(selectedLodge.id);
            setLocationForm({});
            setEditingLocationId(null);
            alert("Location history saved successfully!");
        } catch (error) {
            console.error("Error saving location history:", error);
            alert("Failed to save location history.");
        }
    };

    // Edit an existing location
    const handleEditLocation = (loc) => {
        setEditingLocationId(loc.id);
        setLocationForm({
            ...loc,
            start_date: toSAST(loc.start_date),
            end_date: loc.end_date ? toSAST(loc.end_date) : "",
        });
    };

    // Delete a location record
    const handleDeleteLocation = async (id) => {
        if (!window.confirm("Are you sure you want to delete this record?")) return;

        try {
            await axios.delete(`/api/resources/lodges/location-history/${id}`);
            fetchLocationHistory(selectedLodge.id);
            alert("Location history deleted successfully!");
        } catch (error) {
            console.error("Error deleting location history:", error);
            alert("Failed to delete location history.");
        }
    };

    return (
        <div className="lodge-modal-overlay">
            <div className="lodge-modal-content">
                <button className="lodge-close-btn" onClick={onClose}>
                    &times;
                </button>

                <h2 className="lodge-modal-title">
                    {selectedLodge ? "Edit Lodge" : "Select Lodge"}
                </h2>

                <div className="lodge-scroll-container">
                    {/* Select Lodge (No "Add New Lodge" option) */}
                    <div className="lodge-form-group">
                        <label>Select Lodge</label>
                        <Select
                            className="top-level-lodge-select"
                            classNamePrefix="top-level-lodge-select"
                            // Only existing lodges fetched from the API
                            options={lodges.map((lodge) => ({
                                value: lodge.id,
                                label: lodge.name,
                            }))}
                            onChange={(selectedOption) =>
                                handleLodgeSelect(selectedOption ? selectedOption.value : null)
                            }
                            value={
                                selectedLodge
                                    ? { value: selectedLodge.id, label: selectedLodge.name }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Choose a Lodge"
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                        />
                    </div>

                    {/* Show lodge details and location history only if a lodge is selected */}
                    {selectedLodge && (
                        <div className="lodge-scroll-container">
                            {/* Lodge Type Dropdown */}
                            <div className="lodge-form-group">
                                <label>Lodge Type</label>
                                <Select
                                    options={lodgeTypes.map((type) => ({
                                        value: type.id,
                                        label: type.description,
                                    }))}
                                    onChange={(selectedOption) =>
                                        handleInputChange({
                                            target: {
                                                name: "lodge_type_id",
                                                value: selectedOption ? selectedOption.value : "",
                                            },
                                        })
                                    }
                                    value={
                                        lodgeTypes.find((t) => t.id === formData.lodge_type_id)
                                            ? {
                                                value: formData.lodge_type_id,
                                                label: lodgeTypes.find(
                                                    (t) => t.id === formData.lodge_type_id
                                                ).description,
                                            }
                                            : null
                                    }
                                    isSearchable
                                    isClearable
                                    placeholder="Select Lodge Type"
                                />
                                {errors.lodge_type_id && (
                                    <p className="error-message">{errors.lodge_type_id}</p>
                                )}
                            </div>


                            <div className="lodge-form-group">
                                <label>Constitution</label>
                                <Select
                                    options={constitutions.map((c) => ({
                                        value: c.id,
                                        label: c.description,
                                    }))}
                                    onChange={(selectedOption) =>
                                        handleInputChange({
                                            target: {
                                                name: "constitution_id",
                                                value: selectedOption ? selectedOption.value : "",
                                            },
                                        })
                                    }
                                    value={
                                        constitutions.find((c) => c.id === formData.constitution_id)
                                            ? {
                                                value: formData.constitution_id,
                                                label: constitutions.find(
                                                    (c) => c.id === formData.constitution_id
                                                )?.description,
                                            }
                                            : null
                                    }
                                    isSearchable
                                    isClearable
                                    placeholder="Select Constitution"
                                />
                                {errors.constitution_id && (
                                    <p className="error-message">{errors.constitution_id}</p>
                                )}
                            </div>

                            <div className="lodge-form-group">
                                <label>Date Consecrated</label>
                                <input
                                    type="date"
                                    name="date_established"
                                    value={formData.date_established || ""}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="lodge-form-group">
                                <label>Date Surrendered</label>
                                <input
                                    type="date"
                                    name="date_decommissioned"
                                    value={formData.date_decommissioned || ""}
                                    onChange={handleInputChange}
                                />
                            </div>

                            {/* Location History Section */}
                            <h3 className="lodge-details-modal-location-history-title">
                                Location History
                            </h3>
                            <div className="lodge-details-modal-location-history-container">
                                <ul className="lodge-details-modal-location-list">
                                    {locationHistory.length > 0 ? (
                                        locationHistory.map((location) => {
                                            const building = buildings.find(
                                                (b) => b.id === location.building_id
                                            );
                                            return (
                                                <li
                                                    key={location.id}
                                                    className="lodge-details-modal-location-item"
                                                >
                                                    <div className="lodge-details-modal-location-details">
                                                        <strong>Building:</strong> {building?.name || "Unknown"}
                                                        <br />
                                                        <strong>Dates:</strong> {location.start_date} -{" "}
                                                        {location.end_date || "Present"}
                                                        <br />
                                                        <strong>Comments:</strong>{" "}
                                                        {location.comments || "None"}
                                                        <br />
                                                    </div>
                                                    <div className="lodge-details-modal-location-actions">
                                                        <button
                                                            className="lodge-details-modal-edit-btn"
                                                            onClick={() => handleEditLocation(location)}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button
                                                            className="lodge-details-modal-delete-btn"
                                                            onClick={() => handleDeleteLocation(location.id)}
                                                        >
                                                            Delete
                                                        </button>
                                                    </div>
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <li className="lodge-details-modal-no-history">
                                            No location history available.
                                        </li>
                                    )}
                                </ul>



                                <h3 className="lodge-details-modal-location-form-title">
                                    {editingLocationId ? "Edit Location" : "Add New Location"}
                                </h3>
                                <div className="lodge-details-modal-location-form">
                                    <div className="lodge-form-group">
                                        <label>Building</label>
                                        <Select
                                            options={buildings.map((b) => ({
                                                value: b.id,
                                                label: b.name,
                                            }))}
                                            onChange={(selected) =>
                                                setLocationForm({
                                                    ...locationForm,
                                                    building_id: selected ? selected.value : "",
                                                })
                                            }
                                            value={
                                                buildings.find((b) => b.id === locationForm.building_id)
                                                    ? {
                                                        value: locationForm.building_id,
                                                        label: buildings.find(
                                                            (b) => b.id === locationForm.building_id
                                                        )?.name,
                                                    }
                                                    : null
                                            }
                                            isSearchable
                                            isClearable
                                            placeholder="Select Building"
                                        />
                                    </div>
                                    <div className="lodge-form-group">
                                        <label>Start Date</label>
                                        <input
                                            className="lodge-details-modal-date-input"
                                            type="date"
                                            name="start_date"
                                            value={locationForm.start_date || ""}
                                            onChange={handleLocationInputChange}
                                        />
                                    </div>
                                    <div className="lodge-form-group">
                                        <label>End Date</label>
                                        <input
                                            className="lodge-details-modal-date-input"
                                            type="date"
                                            name="end_date"
                                            value={locationForm.end_date || ""}
                                            onChange={handleLocationInputChange}
                                        />
                                    </div>
                                    <div className="lodge-form-group">
                                        <label>Comments</label>
                                        <textarea
                                            className="lodge-details-modal-comments-input"
                                            name="comments"
                                            value={locationForm.comments || ""}
                                            onChange={handleLocationInputChange}
                                            placeholder="Comments"
                                        />
                                    </div>
                                    <button
                                        className="lodge-details-modal-add-location-btn"
                                        onClick={handleSaveLocation}
                                    >
                                        {editingLocationId ? "Update Location" : "Add Location"}
                                    </button>
                                </div>
                            </div>

                            {/* Constitution Dropdown */}


                            {/* Additional Fields */}
                            {[
                                { key: "code", label: "Lodge Code" },
                                { key: "name", label: "Lodge Name" },
                                { key: "year_established", label: "Year Consecrated" },
                                { key: "status", label: "Status" },
                                {
                                    key: "warrant_document_id",
                                    label: "Warrant Document IDs (comma-separated)",
                                },
                                {
                                    key: "general_document_id",
                                    label: "General Document IDs (comma-separated)",
                                },
                                { key: "year_decommissioned", label: "Year Surrendered" },
                                // This used to be "comments" — now it's "notes"
                                { key: "notes", label: "Notes" },
                            ].map(({ key, label }) => (
                                <div className="lodge-form-group" key={key}>
                                    <label>{label}</label>
                                    <input
                                        type="text"
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                    {errors[key] && <p className="error-message">{errors[key]}</p>}
                                </div>
                            ))}

                            {/* Actions */}
                            <div className="lodge-modal-actions">
                                <button className="lodge-save-btn" onClick={handleSave}>
                                    Save
                                </button>
                                <button className="lodge-cancel-btn" onClick={onClose}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default LodgeDetailsModal;
