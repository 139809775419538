import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./HomePage.css";
import {logInteraction} from "../utils/logInteraction";

function HomePage({user, openLoginModal}) {
    const navigate = useNavigate();
    const [hoveredSection, setHoveredSection] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0});

    const sections = [
        {name: "About", icon: "/images/about.png", route: "/about", allowedRoles: ["Researcher", "Admin", "Member", "Archivist"]}, // Public
        {
            name: "Research",
            icon: "/images/research.png",
            route: "/research",
            allowedRoles: ["Admin", "Archivist", "Member", "Researcher"]
        },
        {
            name: "Resources",
            icon: "/images/resources.png",
            route: "/resources",
            allowedRoles: ["Admin", "Archivist", "Member", "Researcher"]
        }, // Public
        {
            name: "Calendar",
            icon: "/images/calendar.png",
            route: "/calendar",
            allowedRoles: ["Admin", "Archivist", "Member", "Researcher"] // or as you prefer
        },
        {
            name: "View Documents",
            icon: "/images/view.png",
            route: "/view-documents",
            allowedRoles: ["Admin", "Archivist", "Member", "Researcher"]
        },
        {
            name: "Upload Documents",
            icon: "/images/upload.png",
            route: "/upload-documents",
            allowedRoles: ['Admin', "Archivist"]
        },
        {name: "Admin", icon: "/images/history.png", route: "/admin", allowedRoles: ['Admin', "Archivist"]},
        {
            name: "Instructions",
            icon: "/images/instructions.png",
            route: "/instructions",
            allowedRoles: ["Admin", "Archivist", "Member", "Researcher"]
        }, // Public
        // NEW: Analytics link
        {
            name: "Analytics",
            icon: "/images/analytics.png",
            route: "/analytics",
            allowedRoles: ["Admin", "Archivist"]
        },

    ];

    const handleMouseEnter = (e, section, hasAccess) => {
        if (!hasAccess) {
            setHoveredSection(section.name);
            const rect = e.currentTarget.getBoundingClientRect();
            setTooltipPosition({top: rect.top - 40, left: rect.left + rect.width / 2});
        }
    };

    const handleMouseLeave = () => {
        setHoveredSection(null);
    };

    const handleSectionClick = (section) => {
        if (section.allowedRoles.length === 0) {
            navigate(section.route);
            return;
        }

        if (user && section.allowedRoles.includes(user.role)) {
            navigate(section.route);
        } else {
            openLoginModal();
        }
    };

    useEffect(() => {
        logInteraction("page_view", {page: "HomePage"}, user);
    }, [user]);

    return (
        <div className="home-page">
            <div className="introduction">
                <p>
                    Connaught Research Lodge, under the Provincial Grand Lodge of South Africa Northern (Irish
                    Constitution), is dedicated to
                    advancing the principles of Masonic research and scholarship. Our lodge focuses on exploring and
                    preserving the rich history,
                    traditions, and philosophy of Freemasonry.
                </p>
                <p>
                    Most features are reserved for verified members and administrators to ensure a secure and
                    collaborative environment for
                    Masonic research. Please log in or contact us for more information.
                </p>
            </div>
            <section className="sections-grid">
                {sections.map((section) => {
                    const hasAccess =
                        section.allowedRoles.length === 0 || (user && section.allowedRoles.includes(user.role));
                    return (
                        <div
                            key={section.name}
                            className={`section-card ${hasAccess ? "" : "disabled-card"}`}
                            onClick={(e) => hasAccess && handleSectionClick(section)}
                            onMouseEnter={(e) => handleMouseEnter(e, section, hasAccess)}
                            onMouseLeave={handleMouseLeave}
                            role="button"
                            tabIndex={0}
                            aria-label={`Navigate to ${section.name}`}
                            onKeyDown={(e) => e.key === "Enter" && hasAccess && handleSectionClick(section)}
                        >
                            <img src={section.icon} alt={`${section.name} icon`} className="section-icon"/>
                            <h3 className="section-name">{section.name}</h3>
                        </div>
                    );
                })}
                {hoveredSection && (
                    <div
                        className="tooltip"
                        style={{
                            top: `${tooltipPosition.top}px`,
                            left: `${tooltipPosition.left}px`,
                        }}
                    >
                        Unauthorized: Please log in to access this section.
                    </div>
                )}
            </section>
        </div>
    );
}

export default HomePage;
