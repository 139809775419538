// src/pages/StatsMeetings.jsx

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import "./StatsMeetings.css";
import ReactECharts from "echarts-for-react"; // ECharts wrapper for React
import Select from "react-select"; // Import react-select
import { generateAllMonths } from "../utils/generateAllMonths"; // Adjust the path as needed

function StatsMeetings({ statsData: initialStatsData, onClose }) {
    const [statsData, setStatsData] = useState(initialStatsData);
    const [lodges, setLodges] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [selectedLodge, setSelectedLodge] = useState("");
    const [selectedEventType, setSelectedEventType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [attendeeModal, setAttendeeModal] = useState({ isOpen: false, attendees: [], meetingId: null });
    const [chartSize, setChartSize] = useState({ width: 800, height: 500 }); // Default chart size
    const [sankeyModal, setSankeyModal] = useState(false); // Control Sankey modal visibility
    const [sankeyData, setSankeyData] = useState({ nodes: [], links: [] }); // Sankey data
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state

    const chartRef = useRef(null); // For Chart.js
    const echartRef = useRef(null); // For ReactECharts
    let chartInstance = useRef(null);

    // Pagination State
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 24; // Display 24 months (2 years) per page

    useEffect(() => {
        // Set default date range: last 20 years to today
        const today = new Date();
        const twentyYearsAgo = new Date();
        twentyYearsAgo.setFullYear(twentyYearsAgo.getFullYear() - 20);

        const formatDate = (d) => d.toISOString().split("T")[0];

        setStartDate(formatDate(twentyYearsAgo));
        setEndDate(formatDate(today));
    }, []);

    useEffect(() => {
        fetchLodges();
        fetchEventTypesUsedInMeetings();
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchStats();
        }
    }, [selectedLodge, selectedEventType, startDate, endDate]);

    const fetchAttendeesForMeeting = async (meetingId) => {
        try {
            const res = await axios.get(`/api/meetings/${meetingId}/attendees-detailed`);
            setAttendeeModal({ isOpen: true, attendees: res.data, meetingId });
        } catch (error) {
            console.error("Error fetching attendees for meeting:", error);
        }
    };

    const closeModal = () => {
        setAttendeeModal({ isOpen: false, attendees: [], meetingId: null });
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            setLodges(
                res.data.map((lodge) => ({
                    value: lodge.id, // Unique ID
                    label: lodge.name, // Display name
                }))
            );
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchEventTypesUsedInMeetings = async () => {
        try {
            const res = await axios.get("/api/event-types/used-in-meetings");
            setEventTypes(
                res.data.map((eventType) => ({
                    value: eventType.id, // Unique ID
                    label: eventType.description, // Display description
                }))
            );
        } catch (error) {
            console.error("Error fetching event types:", error);
        }
    };

    const fetchStats = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const params = {};
            if (selectedLodge) params.lodge_id = selectedLodge; // Use lodge_id from react-select
            if (selectedEventType) params.eventtype_id = selectedEventType; // Use eventtype_id from react-select
            if (startDate) params.from = startDate;
            if (endDate) params.to = endDate;

            const res = await axios.get("/api/stats/meetings", { params });
            setStatsData(res.data);

            const totalMonths = generateAllMonths(startDate, endDate).length;
            const calculatedTotalPages = Math.ceil(totalMonths / itemsPerPage);
            setCurrentPage(calculatedTotalPages > 0 ? calculatedTotalPages : 1); // Set to last page or 1
        } catch (error) {
            console.error("Error fetching filtered stats:", error);
            setError("Failed to load meeting statistics. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const {
        total_meetings,
        average_attendance,
        monthly_breakdown = [],
        top_visitor_lodges = [],
        lodge_breakdown = [],
        eventtype_breakdown = [],
        meetings_visitor_lodges = []
    } = statsData;

    const prepareSankeyModal = () => {
        if (!statsData || !statsData.meetings_visitor_lodges) return;

        const nodes = [];
        const links = [];

        statsData.meetings_visitor_lodges.forEach((item) => {
            if (item.visitor_lodges && item.visitor_lodges !== 'None') {
                item.visitor_lodges.split(", ").forEach((visitorLodge) => {
                    // Add nodes for visitor and meeting lodges
                    if (!nodes.some((node) => node.name === visitorLodge)) {
                        nodes.push({ name: visitorLodge });
                    }
                    if (!nodes.some((node) => node.name === item.meeting_lodge)) {
                        nodes.push({ name: item.meeting_lodge });
                    }

                    // Add link between visitor and meeting lodges
                    links.push({
                        source: visitorLodge,
                        target: item.meeting_lodge,
                        value: 1, // Adjust based on real visitor counts if available
                    });
                });
            }
        });

        setSankeyData({ nodes, links });
        setSankeyModal(true); // Open the modal
    };

    const prepareChartData = () => {
        if (!startDate || !endDate) return { labels: [], memberData: [], visitorData: [], currentMonthlyBreakdown: [] };

        // Generate all months within the selected date range
        const allMonths = generateAllMonths(startDate, endDate);
        const totalMonths = allMonths.length;
        const totalPagesCalculated = Math.ceil(totalMonths / itemsPerPage);

        // Ensure currentPage does not exceed totalPages
        const safeCurrentPage = Math.min(currentPage, totalPagesCalculated) || 1;

        // Calculate the starting and ending indices to slice the latest data first
        const indexOfLastItem = safeCurrentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentMonths = allMonths.slice(indexOfFirstItem, indexOfLastItem);

        // Create maps for quick lookup of attendance and meeting data
        const memberDataMap = {};
        const visitorDataMap = {};
        const meetingsMap = {};
        const avgAttendanceMap = {};

        monthly_breakdown.forEach((m) => {
            memberDataMap[m.month] = m.member_attendance || 0;
            visitorDataMap[m.month] = m.visitor_attendance || 0;
            meetingsMap[m.month] = m.meetings || 0;
            avgAttendanceMap[m.month] = m.avg_attendance || 0;
        });

        // Prepare data for the chart
        const memberData = currentMonths.map((month) => memberDataMap[month] || 0);
        const visitorData = currentMonths.map((month) => visitorDataMap[month] || 0);

        const formattedLabels = currentMonths.map((month) => {
            const [year, monthNum] = month.split("-");
            const date = new Date(year, monthNum - 1);
            return `${date.toLocaleDateString('en-US', { month: 'short' })} ${year}`; // e.g., "Jan 2021"
        });

        // Prepare detailed data for the table
        const currentMonthlyBreakdown = currentMonths.map((month) => ({
            month,
            meetings: meetingsMap[month] || 0,
            member_attendance: memberDataMap[month] || 0,
            visitor_attendance: visitorDataMap[month] || 0,
            avg_attendance: avgAttendanceMap[month] || 0,
        }));

        return { labels: formattedLabels, memberData, visitorData, currentMonthlyBreakdown };
    };

    useEffect(() => {
        if (isLoading || error) return; // Avoid rendering chart while loading or on error

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        const { labels, memberData, visitorData, currentMonthlyBreakdown } = prepareChartData();
        if (labels.length > 0) {
            const ctx = chartRef.current.getContext("2d");
            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Member Attendance',
                            data: memberData,
                            backgroundColor: 'rgba(75, 192, 192, 0.7)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                            stack: 'Attendance', // Assign to a stack group
                            barPercentage: 0.6, // Adjust for compactness
                            categoryPercentage: 0.8
                        },
                        {
                            label: 'Visitor Attendance',
                            data: visitorData,
                            backgroundColor: 'rgba(255, 159, 64, 0.7)',
                            borderColor: 'rgba(255, 159, 64, 1)',
                            borderWidth: 1,
                            stack: 'Attendance', // Assign to the same stack group
                            barPercentage: 0.6, // Adjust for compactness
                            categoryPercentage: 0.8
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            title: { display: true, text: 'Month' },
                            stacked: true, // Enable stacking on the x-axis
                            ticks: {
                                autoSkip: false,
                                maxRotation: 45,
                                minRotation: 45
                            }
                        },
                        y: {
                            title: { display: true, text: 'Attendance Count' },
                            beginAtZero: true,
                            stacked: true // Enable stacking on the y-axis
                        }
                    },
                    plugins: {
                        tooltip: {
                            mode: 'index',
                            intersect: false,
                            callbacks: {
                                title: function(tooltipItems) {
                                    return tooltipItems[0].label;
                                },
                                label: function(tooltipItem) {
                                    const datasetLabel = tooltipItem.dataset.label || '';
                                    const value = tooltipItem.formattedValue || 0;
                                    return `${datasetLabel}: ${value}`;
                                }
                            }
                        },
                        legend: { position: 'top' },
                        title: {
                            display: true,
                            text: 'Monthly Attendance Breakdown'
                        }
                    }
                }
            });
        }
    }, [monthly_breakdown, startDate, endDate, currentPage, isLoading, error]);

    // Pagination Handlers
    const allMonths = generateAllMonths(startDate, endDate);
    const totalMonths = allMonths.length;
    const totalPages = Math.ceil(totalMonths / itemsPerPage);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
    };

    const exportTableToCSV = () => {
        const { currentMonthlyBreakdown } = prepareChartData();
        const headers = ["Month", "Meetings", "Member Attendance", "Visitor Attendance", "Avg Attendance"];
        const rows = currentMonthlyBreakdown.map(item => [
            item.month,
            item.meetings,
            item.member_attendance,
            item.visitor_attendance,
            item.avg_attendance
        ]);

        let csvContent = "data:text/csv;charset=utf-8,"
            + [headers, ...rows].map(e => e.join(",")).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.href = encodedUri;
        link.download = "monthly_attendance.csv";
        document.body.appendChild(link); // Required for FF

        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="st-modal-overlay">
            <div className="st-modal-content">
                <div className="st-modal-header">
                    <h2>Meeting Statistics</h2>
                    <button className="st-modal-close-btn" onClick={onClose} aria-label="Close Modal">
                        &times;
                    </button>
                </div>
                <div className="st-modal-body">
                    {/* Filters Section */}
                    <div className="st-filters">
                        <div className="st-filter-row">
                            <div className="st-filter-group">
                                <label htmlFor="start-date">Start Date:</label>
                                <input type="date" id="start-date" value={startDate} onChange={handleStartDateChange} />
                            </div>
                            <div className="st-filter-group">
                                <label htmlFor="end-date">End Date:</label>
                                <input type="date" id="end-date" value={endDate} onChange={handleEndDateChange} />
                            </div>
                        </div>
                        <div className="st-filter-row">
                            <div className="st-filter-group">
                                <label htmlFor="lodge-filter">Lodge Filter:</label>
                                <Select
                                    id="lodge-filter"
                                    options={[{ value: "", label: "All Lodges" }, ...lodges]}
                                    value={lodges.find((lodge) => lodge.value === selectedLodge) || { value: "", label: "All Lodges" }}
                                    onChange={(selectedOption) => setSelectedLodge(selectedOption ? selectedOption.value : "")}
                                    placeholder="Filter by Lodge"
                                    isClearable
                                    isSearchable
                                />
                            </div>
                            <div className="st-filter-group">
                                <label htmlFor="eventtype-filter">Event Type Filter:</label>
                                <Select
                                    id="eventtype-filter"
                                    options={[{ value: "", label: "All Types" }, ...eventTypes]}
                                    value={eventTypes.find((event) => event.value === selectedEventType) || { value: "", label: "All Types" }}
                                    onChange={(selectedOption) => setSelectedEventType(selectedOption ? selectedOption.value : "")}
                                    placeholder="Filter by Event Type"
                                    isClearable
                                    isSearchable
                                />
                            </div>
                            <button onClick={prepareSankeyModal} className="visitor-sankey-btn" aria-label="Open Visitor Sankey Diagram">
                                Visitor Sankey
                            </button>
                        </div>
                    </div>

                    {/* Loading and Error States */}
                    {isLoading && <div className="loading-spinner">Loading...</div>}
                    {error && <div className="error-message">{error}</div>}

                    {!isLoading && !error && (
                        <>
                            {/* Overall Stats */}
                            <div className="st-section">
                                <h3>Overall Stats</h3>
                                <div className="st-metrics">
                                    <div className="st-metric">
                                        <span className="st-metric-value">{total_meetings}</span>
                                        <span className="st-metric-label">Total Meetings</span>
                                    </div>
                                    <div className="st-metric">
                                        <span className="st-metric-value">{average_attendance}</span>
                                        <span className="st-metric-label">Avg Attendance</span>
                                    </div>
                                </div>
                            </div>

                            {/* Monthly Breakdown */}
                            <div className="st-section">
                                <h3>Monthly Breakdown (Members vs Visitors)</h3>

                                {/* Controls Above the Chart */}
                                <div className="monthly-controls">
                                    <button onClick={exportTableToCSV} className="export-csv-btn" aria-label="Export Table as CSV">
                                        Export as CSV
                                    </button>
                                    <div className="pagination-controls">
                                        <button onClick={handlePrevious} disabled={currentPage === 1} aria-label="Previous Page">
                                            Previous
                                        </button>
                                        <span> Page {currentPage} of {totalPages} </span>
                                        <button onClick={handleNext} disabled={currentPage === totalPages} aria-label="Next Page">
                                            Next
                                        </button>
                                    </div>
                                </div>

                                {/* Chart Container */}
                                <div className="st-chart-container">
                                    <canvas ref={chartRef}></canvas>
                                </div>

                                {/* Detailed Monthly Table */}
                                <table className="st-stats-table">
                                    <thead>
                                    <tr>
                                        <th>Month</th>
                                        <th>Meetings</th>
                                        <th>Member Attendance</th>
                                        <th>Visitor Attendance</th>
                                        <th>Avg Attendance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prepareChartData().currentMonthlyBreakdown.length > 0 ? (
                                        prepareChartData().currentMonthlyBreakdown.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.month}</td>
                                                <td>{item.meetings}</td>
                                                <td>{item.member_attendance}</td>
                                                <td>{item.visitor_attendance}</td>
                                                <td>{item.avg_attendance}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">No data available for the selected range.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Top Visitor Lodges */}
                            <div className="st-section">
                                <h3>Top Visitor Lodges</h3>
                                <table className="st-stats-table">
                                    <thead>
                                    <tr>
                                        <th>Lodge Name</th>
                                        <th>Visitor Count</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {top_visitor_lodges.length > 0 ? (
                                        top_visitor_lodges.map((lod, index) => (
                                            <tr key={index}>
                                                <td>{lod.name}</td>
                                                <td>{lod.visitor_count}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="2">No visitor lodges data available.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Lodge Breakdown */}
                            <div className="st-section">
                                <h3>Lodge Breakdown</h3>
                                <table className="st-stats-table">
                                    <thead>
                                    <tr>
                                        <th>Lodge Name</th>
                                        <th>Meetings</th>
                                        <th>Avg Attendance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lodge_breakdown.length > 0 ? (
                                        lodge_breakdown.map((lod, index) => (
                                            <tr key={index}>
                                                <td>{lod.name}</td>
                                                <td>{lod.meetings}</td>
                                                <td>{lod.avg_attendance}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No lodge breakdown data available.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>

                            {/* Event Type Breakdown */}
                            <div className="st-section">
                                <h3>Event Type Breakdown</h3>
                                <table className="st-stats-table">
                                    <thead>
                                    <tr>
                                        <th>Event Type</th>
                                        <th>Meetings</th>
                                        <th>Avg Attendance</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {eventtype_breakdown.length > 0 ? (
                                        eventtype_breakdown.map((evt, index) => (
                                            <tr key={index}>
                                                <td>{evt.description}</td>
                                                <td>{evt.meetings}</td>
                                                <td>{evt.avg_attendance}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No event type breakdown data available.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>

                {/* Attendee Modal */}
                {attendeeModal.isOpen && (
                    <div className="attendee-modal-overlay">
                        <div className="attendee-modal-content">
                            <div className="attendee-modal-header">
                                <h2>Attendees for Meeting</h2>
                                <button onClick={closeModal} aria-label="Close Attendee Modal">&times;</button>
                            </div>
                            <div className="attendee-modal-body">
                                <table className="st-inner-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Visitor</th>
                                        <th>Lodge</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {attendeeModal.attendees.length > 0 ? (
                                        attendeeModal.attendees.map((attendee) => (
                                            <tr key={attendee.attendee_id}>
                                                <td>{attendee.member_name || "Unknown"}</td>
                                                <td>{attendee.visitor ? "Yes" : "No"}</td>
                                                <td>{attendee.lodge_name || "Unknown Lodge"}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No attendees found for this meeting.</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="attendee-modal-footer">
                                <button onClick={closeModal} aria-label="Close Attendee Modal">Close</button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Sankey Modal */}
                {sankeyModal && (
                    <div className="sankey-modal-overlay">
                        <div className="sankey-modal-content">
                            <div className="sankey-modal-header">
                                <h2>Visitor Sankey Diagram</h2>
                                <button onClick={() => setSankeyModal(false)} aria-label="Close Sankey Modal">&times;</button>
                                <button
                                    className="sankey-save-button"
                                    onClick={() => {
                                        const chartInstance = echartRef.current.getEchartsInstance();
                                        const imgBase64 = chartInstance.getDataURL({
                                            type: "png",
                                            backgroundColor: "#ffffff", // Ensure a white background
                                        });

                                        const link = document.createElement("a");
                                        link.href = imgBase64;
                                        link.download = "sankey_chart.png";
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}
                                    aria-label="Save Sankey Chart"
                                >
                                    Save Chart
                                </button>
                            </div>
                            <div className="sankey-modal-body">
                                <div className="sankey-controls">
                                    <button
                                        onClick={() =>
                                            setChartSize((prevSize) => ({
                                                width: prevSize.width - 200,
                                                height: prevSize.height - 150,
                                            }))
                                        }
                                        aria-label="Reduce Sankey Chart Size"
                                    >
                                        Reduce Size
                                    </button>
                                    <button
                                        onClick={() =>
                                            setChartSize((prevSize) => ({
                                                width: prevSize.width + 200,
                                                height: prevSize.height + 150,
                                            }))
                                        }
                                        aria-label="Increase Sankey Chart Size"
                                    >
                                        Increase Size
                                    </button>
                                </div>
                                <ReactECharts
                                    ref={echartRef}
                                    option={{
                                        tooltip: { trigger: "item" },
                                        series: [
                                            {
                                                type: "sankey",
                                                data: sankeyData.nodes,
                                                links: sankeyData.links,
                                                emphasis: { focus: "adjacency" },
                                                lineStyle: { color: "gradient", curveness: 0.5 },
                                            },
                                        ],
                                    }}
                                    style={{ width: `${chartSize.width}px`, height: `${chartSize.height}px` }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Footer */}
                <div className="st-modal-footer">
                    <button className="st-modal-close-btn" onClick={onClose} aria-label="Close Modal">Close</button>
                </div>
            </div>
        </div>
    );
}

export default StatsMeetings;
