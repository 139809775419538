import React, {useState} from "react";
import "./HistoryOfIrishFreemasonry.css";

const HistoryOfIrishFreemasonry = () => {
    const [activeModal, setActiveModal] = useState(""); // Tracks which modal is active

    const openModal = (modal) => setActiveModal(modal);
    const closeModal = () => setActiveModal("");

    return (
        <div className="history-container">
            {/*<h1 className="history-title">Irish Freemasonry in South Africa</h1>*/}

            <section className="history-content">
                {/* Before the Grand Lodge of Ireland */}
                <h1>Introduction</h1>
                <p>
                    Freemasonry originated in medieval stonemason lodges and evolved into the modern speculative
                    fraternity through three key stages: the formation of operative mason lodges in the Middle Ages, the
                    inclusion of non-masons as “accepted” or “speculative” members, and the creation of speculative
                    lodges governed by Grand Lodges. A pivotal moment in this transformation was the founding of the
                    first Grand Lodge in London on <strong>24 June 1717</strong>, when four London lodges met at the
                    Goose and Gridiron
                    and elected Anthony Sayer as the first Grand Master (Jones & Knoop, 1947).
                </p>
                <p>
                    Grand Lodges quickly spread across Europe. Ireland established its Grand Lodge
                    in <strong>1725</strong>, introducing
                    the practice of issuing warrants to formally recognise lodges
                    by <strong>1731</strong> (Chetwode-Crawley, n.d.), a
                    system that enabled military-based lodges to spread Freemasonry to British colonies. Scotland
                    followed by establishing its own Grand Lodge in <strong>1736</strong>.
                </p>
                <p>
                    The Grand Orient of the Netherlands (Grand East of the Netherlands), established
                    in <strong>1735</strong> under
                    English jurisdiction, was particularly influential in the early history of South African
                    Freemasonry. Through Dutch influence, Freemasonry spread to the region, culminating in the founding
                    of <strong>Lodge De Goede Hoop</strong> in Cape Town in <strong>1772</strong> by Dutch East India
                    Company officials (Cooper, 1986).
                </p>

                <h1>The Early Years</h1>

                <p>
                    Lodge De Goede Hoop, founded in 1772 at the Cape of Good Hope under a warrant from Abraham van der
                    Weijden and ratified by the Grand Orient of the Netherlands, initially struggled to gain local
                    support and became dormant in 1781. Revived in 1794, the lodge expanded its influence by
                    establishing a daughter lodge, De Goede Trouw, in 1800 and commissioning a permanent temple designed
                    by architect Louis Thibault, completed in 1803 (Cooper, 1986).
                </p>
                <button onClick={() => openModal("earlyYearsModal")} className="open-modal-button">
                    Lodge De Goede Hoop
                </button>

                <p>
                    Between 1772 and 1795, the Cape of Good Hope, under Dutch East India Company (VOC) rule, experienced
                    the expansion of settler agriculture, intensified conflicts with indigenous groups, and an
                    increasing reliance on enslaved labor to sustain its economy. However, the VOC’s financial decline,
                    driven by mismanagement and global competition, weakened its grip on the region. Amid rising
                    tensions in Europe, Britain, concerned about the Cape falling into French hands, occupied the colony
                    in 1795 to secure its strategic position along the trade route to the East. The arrival of British
                    forces, accompanied by military lodges, revitalised Masonic activity at the Cape, as these lodges
                    actively practised their traditions. During the transition, Lodge De Goede Hoop maintained its
                    stability by aligning with British officials and regimental lodges, initiating officers, securing
                    protection from the new administration, and ensuring its continuity (Cooper, 1980).
                </p>
                <p>
                    Between 1795 and 1806, South Africa experienced significant political and social transitions as
                    colonial powers vied for control. The British occupied the Cape of Good Hope in 1795 to preempt
                    French ambitions during the Napoleonic Wars, briefly ceded it to the Dutch Batavian Republic in
                    1803, and reclaimed it permanently in 1806. Though still in its early stages, military and civilian
                    lodges further influenced Freemasonry in the region.
                </p>
                <p>
                    Military lodges played a key role in spreading British Masonic traditions. The 8th Dragoons (King’s
                    Royal Irish Hussars) introduced Irish Lodge No. 280 to South Africa in 1796, who conferred the Royal
                    Arch Degree on local brethren before departing in 1803. Other Irish military lodges followed,
                    including the 71st Foot (Highland Light Infantry) with Lodge No. 895 (warranted in 1801), the 20th
                    Light Dragoons with its Irish lodge, and the 38th Foot regiment with Lodge No. 441 (famed as the
                    lodge that initiated Prince Hall in America). These lodges brought distinct Irish influences to the
                    Cape’s Masonic community.
                </p>
                <p>
                    In 1811, Freemasonry took a significant step forward when a petition to the Grand Lodge of England
                    (Moderns) led to establishing British Lodge No. 629, the first English civilian lodge in South
                    Africa. A year later, the Grand Lodge of England (Ancients) issued a warrant to the 10th Battalion,
                    Royal Artillery, for Union Lodge No. 354, with 14 of its 22 petitioners being Irish Freemasons
                    (Butterfield, 1978).
                </p>
                <p>
                    The arrival of the 1820 Settlers further shaped the Cape’s colonial society. This state-sponsored
                    immigration scheme aimed to address economic distress in Britain and strengthen British control over
                    the Cape. Among these settlers was Peter Campbell, an Irish Freemason (Campbell was a member of Irish Lodge, No. 461 (Cooper, 1980)) who settled in Grahamstown,
                    where he served as district surgeon and became a prominent Freemason. In 1828, Campbell founded
                    Albany Lodge No. 389 under the English Constitution and served as its Worshipful Master (Tonkin,
                    1976).
                </p>
                <p>
                    The Cape Colony’s transition to British rule brought profound societal and economic changes. Dutch
                    settler discontent over British reforms fueled the Great Trek (1836–1840), which later led to the
                    establishment of the Boer republics of the Orange Free State and Transvaal (the South African
                    Republic). The discovery of diamonds in the 1860s and gold in the 1880s transformed South Africa’s
                    economy and intensified British imperial ambitions. These and other developments ultimately escalated tensions
                    with the Boer republics, culminating in the South African War (1899–1902).

                </p>

                <h1>The Establishment of Irish Freemasonry in South Africa: A New Chapter Begins</h1>

                {/*<h2>Irish Freemasons and the English Constitution in South Africa</h2>*/}

                {/*/!* Irish Immigration to South Africa *!/*/}
                {/*<h2>Irish Immigration to South Africa</h2>*/}
                {/*<p>*/}
                {/*    The discovery of <strong>diamonds in 1867</strong> and <strong>gold in 1886</strong> marked a*/}
                {/*    turning point*/}
                {/*    for Irish immigration to South Africa. A steady trickle of settlers turned into a significant wave,*/}
                {/*    bringing with them their culture, traditions, and Freemasonry. Irish immigrants played a pivotal*/}
                {/*    role*/}
                {/*    in shaping South Africa’s Masonic landscape, laying the groundwork for lodges that adhered to the*/}
                {/*    <strong>Irish Constitution</strong>.*/}
                {/*</p>*/}

                {/*/!* Irish Freemasonry in South Africa *!/*/}
                {/*<h2>Irish Freemasonry in South Africa</h2>*/}
                {/*<p>*/}
                {/*    Irish Freemasonry first arrived in South Africa through <strong>traveling lodges</strong> associated*/}
                {/*    with*/}
                {/*    British Army regiments in the 18th century. These lodges brought Irish Masonic traditions,*/}
                {/*    practices, and*/}
                {/*    ideals to the region, contributing significantly to the growth of Freemasonry in South Africa. Over*/}
                {/*    time,*/}
                {/*    these lodges became a foundation for many of the country’s Masonic traditions.*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    A pivotal moment came in <strong>1895</strong> with the establishment of <strong>No. 1159 Abercorn*/}
                {/*    Lodge</strong> in the Transvaal.*/}
                {/*    This was soon followed by <strong>No. 247 Ed H Croghan Lodge</strong> and <strong>No. 199 St*/}
                {/*    Patrick’s Lodge</strong>,*/}
                {/*    which further solidified Irish Freemasonry's presence in South Africa.*/}
                {/*</p>*/}

                {/*/!* Dr. Kendal Franks *!/*/}
                {/*<h3>Leadership of Dr. Kendal Franks</h3>*/}
                {/*<p>*/}
                {/*    Dr. Kendal Matthew St John Franks (1851–1920), a prominent Irish surgeon and Mason, transformed*/}
                {/*    Irish Freemasonry*/}
                {/*    in South Africa. As the first <strong>Provincial Grand Master</strong>, he expanded Irish Masonic*/}
                {/*    influence,*/}
                {/*    founding lodges such as <strong>No. 338 Shamrock</strong> and <strong>No. 361 Connaught</strong>.*/}
                {/*    His tenure*/}
                {/*    marked the institutionalization of Irish Freemasonry in the region.*/}
                {/*</p>*/}

                {/*/!* Expansion and Legacy *!/*/}
                {/*<h3>Expansion and Legacy</h3>*/}
                {/*<p>*/}
                {/*    By the early 20th century, Irish Freemasonry in South Africa had flourished, with lodges actively*/}
                {/*    contributing to*/}
                {/*    both Masonic and charitable endeavors. The <strong>Transvaal Masonic Benevolent Fund</strong> and*/}
                {/*    the decentralization*/}
                {/*    into Provincial Grand Lodges are enduring examples of this influence. Irish Freemasonry’s*/}
                {/*    adaptability ensured*/}
                {/*    its continued relevance in South Africa’s evolving society.*/}
                {/*</p>*/}


                <h3>References</h3>
                <ul>
                    <li>
                        Butterfield, P. H. (1978). <em>Centenary: The first 100 years of English Freemasonry in the
                        Transvaal, 1878-1978.</em> E. Stanton.
                    </li>
                    <li>
                        Cooper, A. A. (1980). <em>The origins and growth of freemasonry in South Africa, 1772-1876.</em>
                    </li>
                    <li>
                        Cooper, A. A. (1986). <em>The Freemasons of South Africa.</em>
                    </li>
                    <li>
                        Chetwode-Crawley, W. J. (n.d.). <em>Caementaria Hibernica: Fasciculus Primus
                        1726–1730.</em> Retrieved from
                        <a href="https://archive.org/details/CrawleyWJCCaementariaHibernicaFasciculusPrimus17261730CareOfGrandLodgeOfIreland"
                           target="_blank">
                            https://archive.org/details/CrawleyWJCCaementariaHibernicaFasciculusPrimus17261730CareOfGrandLodgeOfIreland
                        </a>
                    </li>
                    <li>
                        Jones, G. P., & Knoop, D. (1947). <em>The Genesis of Freemasonry.</em> University Press.
                    </li>
                    <li>
                        Tonkin, A. H. (1976). The doctors who came to the Cape with the 1820 settlers. South African
                        Medical Journal, 50(31), 1222–1224.
                    </li>
                </ul>
            </section>

            {/* Modals */
            }
            {/* Modal for The Early Years */}
            {activeModal === "earlyYearsModal" && (
                <div className="history-modal-overlay" onClick={closeModal}>
                    <div className="history-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="history-modal-header">
                            <h2> Lodge De Goede Hoop: The Early Years</h2>
                            <button className="history-modal-close-button" onClick={closeModal}>
                                &times;
                            </button>
                        </div>
                        <div className="history-modal-body">
                            <p>
                                Lodge De Goede Hoop was established in 1772 at the Cape of Good Hope under the authority
                                of
                                the Grand Lodge of the Netherlands. Abraham van der Weijde, a Dutch naval captain,
                                issued a
                                provisional charter after convening local Freemasons. The lodge was officially ratified
                                on 1
                                September 1772 as Lodge No. 12 under the Grand Lodge of the Netherlands.
                            </p>

                            <div className="image-gallery">
                                <img src="/images/Goedehoop.jpg" alt="Lodge De Goede Hoop - Early Years"
                                     className="modal-image"/>
                                {/*<img src="/images/FreemasonryArtifacts.jpg" alt="Freemasonry Artifacts" className="modal-image" />*/}
                                {/*<img src="/images/HistoricalDocuments.jpg" alt="Historical Documents" className="modal-image" />*/}
                            </div>
                            <small className="image-source">
                                Lodge de Goede Hoop, consecrated 7 July 1803.
                            </small>
                        </div>
                    </div>
                </div>
            )}
            {
                activeModal === "baalSquare" && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-button" onClick={closeModal}>
                                &times;
                            </button>
                            <h2>Baal’s Bridge Square</h2>
                            <p>
                                The Baal’s Bridge Square, discovered in 1830 in Limerick, is an artifact dating
                                to <strong>1507</strong>. Its inscription:
                                <em>“I will strive to live with love and care, upon the level and by the square.”</em>,
                                represents the ethical foundations
                                of Freemasonry.
                            </p>
                            <img src="/path/to/baals-bridge-square.jpg" alt="Baal's Bridge Square"
                                 className="modal-image"/>
                        </div>
                    </div>
                )
            }

            {
                activeModal === "trinityTripos" && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-button" onClick={closeModal}>
                                &times;
                            </button>
                            <h2>Trinity Tripos</h2>
                            <p>
                                The <strong>Trinity Tripos</strong> of 1688 references Freemasonry’s integration into
                                Irish
                                intellectual life
                                and highlights the early development of speculative practices.
                            </p>
                        </div>
                    </div>
                )
            }

            {
                activeModal === "travelingWarrants" && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-button" onClick={closeModal}>
                                &times;
                            </button>
                            <h2>Traveling Warrants</h2>
                            <p>
                                Traveling warrants allowed military lodges under the <strong>Irish
                                Constitution</strong> to
                                operate wherever their
                                regiments were stationed. This innovation facilitated the spread of Irish Freemasonry
                                globally, including in South Africa.
                            </p>
                        </div>
                    </div>
                )
            }

            {/* Connaught Rangers Modal */
            }
            {
                activeModal === "connaughtRangers" && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <button className="close-button" onClick={closeModal}>
                                &times;
                            </button>
                            <h2>Connaught Rangers</h2>
                            <p>
                                The Connaught Rangers, formed from Irish regiments, played a critical role in spreading
                                Irish Freemasonry.
                                During their campaigns in South Africa, they established lodges, helping to embed Irish
                                Masonic traditions
                                in the region. Their influence remains an integral part of South Africa's Masonic
                                history.
                            </p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default HistoryOfIrishFreemasonry;
