import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MemberDetailsModal.css";
import MembershipHistoryModal from "./MembershipHistoryModal";
import MembershipDegreesModal from "./MembershipDegreesModal";
import OfficerRoleModal from "./OfficerHistoryModal";
import MemberProfileModal from "./MemberProfileModal";
import Select from "react-select"; // Import react-select

function MemberDetailsModal({ onClose, onSave }) {
    const [members, setMembers] = useState([]);
    const [memberCount, setMemberCount] = useState(0); // For total member count
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({});
    const [selectedMember, setSelectedMember] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [errors, setErrors] = useState({});
    const [currentModal, setCurrentModal] = useState(null); // Track which modal is open

    useEffect(() => {
        fetchMembers();
        fetchMemberCount(); // Fetch total number of members
        fetchCountries();
    }, []);

    // Fetch all member data for the dropdown
    const fetchMembers = async () => {
        try {
            const res = await axios.get("/api/resources/members");
            // Transform the member data into options for react-select
            const memberOptions = res.data.map((member) => ({
                value: member.id,
                label: `${member.first_name} ${member.last_name}`,
            }));
            // Add the option for adding a new member
            setMembers([{ value: "new", label: "Add New Member" }, ...memberOptions]);
        } catch (error) {
            console.error("Error fetching members:", error);
        }
    };

    // Fetch only the count of members
    const fetchMemberCount = async () => {
        try {
            // Example: your new endpoint
            const res = await axios.get("/api/resources/members/count");
            setMemberCount(res.data.count); // e.g., { count: 123 }
        } catch (error) {
            console.error("Error fetching member count:", error);
        }
    };

    // Fetch countries for the dropdown
    const fetchCountries = async () => {
        try {
            const res = await axios.get("/api/resources/countries");
            setCountries(res.data);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };

    const handleMemberSelect = async (selectedOption) => {
        if (!selectedOption || selectedOption.value === "new") {
            setSelectedMember(null);
            setFormData({});
            setIsAddingNew(true);
            return;
        }

        try {
            const res = await axios.get(`/api/resources/members/${selectedOption.value}`);
            const member = res.data;

            setSelectedMember(member);
            setFormData({
                ...member,
                date_of_birth: member.date_of_birth ? formatToDateInput(member.date_of_birth) : "",
                date_of_death: member.date_of_death ? formatToDateInput(member.date_of_death) : "",
            });
            setIsAddingNew(false);
        } catch (error) {
            console.error("Error fetching member details:", error);
        }
    };

    // Utility function to format a UTC date to a YYYY-MM-DD suitable for an <input type="date" />
    // adjusting for SAST (UTC+2):
    const formatToDateInput = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000;
        const localDate = new Date(date.getTime() + offsetInMs);
        return localDate.toISOString().split("T")[0];
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        const confirmSave = window.confirm(
            selectedMember
                ? "Are you sure you want to update this member?"
                : "Are you sure you want to add this member?"
        );
        if (!confirmSave) return;

        try {
            const payload = {
                ...formData,
                date_of_birth: formData.date_of_birth || null,
                date_of_death: formData.date_of_death || null,
            };

            if (selectedMember) {
                await axios.put(`/api/resources/members/${selectedMember.id}`, payload);
            } else {
                await axios.post(`/api/resources/members`, payload);
            }

            fetchMembers(); // Refresh members in dropdown
            fetchMemberCount(); // Refresh the count
            alert("Member saved successfully!");
            if (typeof onSave === "function") onSave();
        } catch (error) {
            console.error("Error saving member:", error);
            alert("Error saving member. Please try again.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateForm = () => {
        const requiredFields = ["first_name", "last_name", "country_id"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field].toString().trim() === "") {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDelete = async () => {
        if (!selectedMember) return;

        const confirmDelete = window.confirm(
            "Are you sure you want to delete this member?"
        );
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/resources/members/${selectedMember.id}`);
            fetchMembers();
            fetchMemberCount();
            setFormData({});
            setSelectedMember(null);
            alert("Member deleted successfully!");
        } catch (error) {
            console.error("Error deleting member:", error);
            alert("Error deleting member. Please try again.");
        }
    };

    return (
        <div className="member-modal-overlay">
            <div className="member-modal-content">
                <button className="member-close-btn" onClick={onClose}>
                    &times;
                </button>
                {/* Show the member count in the heading */}
                <h2>
                    {isAddingNew ? "Add New Member" : "Edit Member"}
                </h2>

                {/* Member Selection Dropdown */}
                <div className="member-form-group">
                    <label>
                        Select Member{" "}
                        {/* Show total count here */}
                        <span style={{ fontSize: "0.9em", color: "#666" }}>
              ({memberCount} total)
            </span>
                    </label>
                    <Select
                        options={members}
                        onChange={handleMemberSelect}
                        value={
                            selectedMember
                                ? {
                                    value: selectedMember.id,
                                    label: `${selectedMember.first_name} ${selectedMember.last_name}`,
                                }
                                : { value: "new", label: "Add New Member" }
                        }
                        isSearchable
                    />
                </div>

                {/* Related Data Buttons */}
                <div className="related-data-buttons">
                    <button
                        onClick={() => setCurrentModal("history")}
                        disabled={!selectedMember}
                        className={!selectedMember ? "disabled" : ""}
                    >
                        Manage Membership History
                    </button>
                    <button
                        onClick={() => setCurrentModal("degrees")}
                        disabled={!selectedMember}
                        className={!selectedMember ? "disabled" : ""}
                    >
                        Manage Degrees
                    </button>
                    <button
                        onClick={() => setCurrentModal("officers")}
                        disabled={!selectedMember}
                        className={!selectedMember ? "disabled" : ""}
                    >
                        Manage Officer Roles and Ranks
                    </button>
                    <button
                        onClick={() => setCurrentModal("profile")}
                        disabled={!selectedMember}
                        className={!selectedMember ? "disabled" : ""}
                    >
                        View Profile
                    </button>
                </div>

                {/* Member Basic Details */}
                <div className="member-form-container">
                    {[
                        { key: "first_name", label: "First Name" },
                        { key: "last_name", label: "Last Name" },
                        { key: "date_of_birth", label: "Date of Birth", type: "date" },
                        { key: "date_of_death", label: "Date of Death", type: "date" },
                        { key: "phone_number", label: "Phone Number" },
                        { key: "email_address", label: "Email Address" },
                        { key: "occupation", label: "Occupation" },
                        { key: "address", label: "Address" },
                        { key: "city", label: "City" },
                        { key: "state", label: "State" },
                        { key: "postcode", label: "Postcode" },
                        { key: "comments", label: "Comments" },
                    ].map(({ key, label, type = "text" }) => (
                        <div className="member-form-group" key={key}>
                            <label>{label}</label>
                            <input
                                type={type}
                                name={key}
                                value={formData[key] || ""}
                                onChange={handleInputChange}
                                className={errors[key] ? "error" : ""}
                            />
                            {errors[key] && <p className="error-message">{errors[key]}</p>}
                        </div>
                    ))}

                    {/* Country Dropdown */}
                    <div className="member-form-group">
                        <label>Country</label>
                        <select
                            name="country_id"
                            value={formData.country_id || ""}
                            onChange={handleInputChange}
                            className={errors.country_id ? "error" : ""}
                        >
                            <option value="">Select Country</option>
                            {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                        {errors.country_id && (
                            <p className="error-message">{errors.country_id}</p>
                        )}
                    </div>
                </div>

                <div className="member-modal-actions">
                    <button className="member-save-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button className="member-delete-btn" onClick={handleDelete}>
                        Delete
                    </button>
                    <button className="member-cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>

            {/* Membership History Modal */}
            {currentModal === "history" && (
                <MembershipHistoryModal
                    memberId={selectedMember?.id}
                    onClose={() => setCurrentModal(null)}
                />
            )}

            {/* Degrees Modal */}
            {currentModal === "degrees" && (
                <MembershipDegreesModal
                    memberId={selectedMember?.id}
                    onClose={() => setCurrentModal(null)}
                />
            )}

            {/* Officer Roles Modal */}
            {currentModal === "officers" && (
                <OfficerRoleModal
                    memberId={selectedMember?.id}
                    onClose={() => setCurrentModal(null)}
                />
            )}

            {/* Profile Modal */}
            {currentModal === "profile" && (
                <MemberProfileModal
                    memberId={selectedMember?.id}
                    onClose={() => setCurrentModal(null)}
                />
            )}
        </div>
    );
}

export default MemberDetailsModal;
