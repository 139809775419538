// AboutPage.js

import React, { useState } from "react";
import "./AboutPage.css";
import HistoryOfIrishFreemasonry from "./HistoryOfIrishFreemasonry";
import HistoryOfConnaughtLodge from "./HistoryOfConnaughtLodge";

function AboutPage() {
    const [modalOpen, setModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");
    const [modalContent, setModalContent] = useState({ title: "", text: "", image: "" });

    // === Card Data (Truncated Example) ===
    const cardData = [
        {
            title: "Brief History of Irish Freemasonry in South Africa",
            summary:
                "Irish Freemasonry evolved from a modest presence in the 19th century into a robust network of Lodges, especially after diamonds (1867) and gold (1886) discoveries.",
            details: `Irish Freemasonry’s roots in South Africa were laid gradually ... (long text) ...`,
            imagePlaceholder: "/images/the_scribe.jpg",
            imageSource: '"The Scribe" by George Cattermole (1850). The Cooper Gallery',
            isIrishHistory: true, // Flag to trigger the Irish Freemasonry modal
        },
        {
            title: "Brief History of Connaught Lodge No. 361 I.C.",
            summary:
                "Connaught Lodge, founded in 1903, evolved into a research lodge that encourages scholarly inquiry and the preservation of Masonic heritage.",
            details: `Connaught Lodge No. 361 I.C. emerged in 1903 ... (long text) ...`,
            imagePlaceholder: "/images/IMG_4012_2.jpg",
            imageSource:
                "Photograph of the original image (taken in 1905) depicting ...",
            isConnaughtHistory: true, // Flag to trigger the Connaught Lodge modal
        },
        // ... add more entries if you wish ...
    ];

    // === Modal Logic ===
    const openIrishModal = () => {
        setActiveModal("irish");
        setModalOpen(true);
    };

    const openConnaughtModal = () => {
        setActiveModal("connaught");
        setModalOpen(true);
    };

    const openGenericModal = (title, text, image) => {
        setActiveModal("generic");
        setModalContent({ title, text, image });
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setActiveModal("");
    };

    // === Render ===
    return (
        <div className="about-container-unique">
            {/* Hero Section */}
            <section className="hero-section-unique">
                <h2>Purpose &amp; Mission</h2>
                <p>
                    Connaught Lodge aims to serve as an Irish research lodge, reaching
                    Masonic communities through digitisation and preservation of
                    historical records.
                </p>

                <p>
                    In his <em>Anabasis</em> work, the ancient Greek historian and soldier
                    Xenophon recounts a remarkable sight. While marching through ancient
                    Assyria, he and his forces encountered colossal fortifications,
                    abandoned and left in ruins:
                </p>

                {/* Xenophon Quote + Image */}
                <div className="hero-text">
                    <blockquote style={{ fontStyle: "italic", margin: "0.5rem 0" }}>
                        "…they marched one stage, six parasangs, to a great stronghold,
                        deserted and lying in ruins. The name of this city was Mespila, and
                        it was once inhabited by the Medes. The foundation of its wall was
                        made of polished stone full of shells, and was fifty feet in breadth
                        and fifty in height. Upon this foundation was built a wall of brick,
                        fifty feet in breadth and a hundred in height; and the circuit of
                        the wall was six parasangs." <br />— Xenophon, <em>Anabasis</em>
                    </blockquote>

                    <div className="image-container">
                        <img
                            src="/images/Adrien_Guignet_Retreat_of_the_ten_thousand.jpg"
                            alt="Retreat of the Ten Thousand, by Jean Adrien Guignet"
                            className="hero-image-unique"
                        />
                    </div>
                    <small className="image-source">
                        "Retreat of the Ten Thousand," by Jean Adrien Guignet, Louvre
                        Museum.
                    </small>
                    <p>
                        One cannot help but wonder how such a grand city—its walls broader and loftier than any the
                        Greeks had seen—ended up wholly forsaken. Societies, after all, frequently adapt abandoned
                        fortifications. Yet the passage of time, the perspective of victors, and shifting priorities
                        often conspire to leave places like Mespila and Larisa unoccupied for centuries. These sites
                        become layers of history, awaiting rediscovery—tangled in biased accounts or fragmentary
                        memories when later generations stumble upon them.

                        This phenomenon of <strong>loss and rediscovery</strong>  resonates across ages and continents. Records
                        vanish, knowledge disperses, and entire narratives slip from memory, only to reemerge in
                        fragments and recollections, challenging new generations to reconstruct the past with
                        partial clues.
                        Today, <strong> Connaught Lodge</strong> in Pretoria and similar bodies face a similar challenge: to preserve
                        the Irish Masonic histories in South Africa before they, too, risk becoming the next
                        Mespila—lost, overlooked, or overshadowed by new chapters. Without deliberate efforts, these
                        stories might be rediscovered only years later, riddled with inaccuracies. By gathering and
                        archiving the traditions, personal accounts, and documents of Irish Freemasons in South
                        Africa, we strive to keep this legacy alive. In doing so, we protect it from
                        misunderstanding or neglect, ensuring it remains a vibrant part of history for future
                        generations
                    </p>
                </div>
            </section>

            {/* Cards Section */}
            <section className="card-container-unique">
                {cardData.map((card, index) => (
                    <div className="card-unique" key={index}>
                        <h3>{card.title}</h3>
                        <p>{card.summary}</p>
                        <img
                            src={card.imagePlaceholder}
                            alt={card.title}
                            className="card-image-unique"
                        />
                        {card.imageSource && (
                            <small className="image-source">{card.imageSource}</small>
                        )}

                        <button
                            className="read-more-button-unique"
                            onClick={() => {
                                if (card.isIrishHistory) {
                                    openIrishModal();
                                } else if (card.isConnaughtHistory) {
                                    openConnaughtModal();
                                } else {
                                    openGenericModal(
                                        card.title,
                                        card.details,
                                        card.imagePlaceholder
                                    );
                                }
                            }}
                        >
                            Read More
                        </button>
                    </div>
                ))}
            </section>

            {/* Dedicated History Modal for Irish Freemasonry */}
            {modalOpen && activeModal === "irish" && (
                <div className="history-modal-overlay" onClick={closeModal}>
                    <div
                        className="history-modal-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="history-modal-header">
                            <h3>History of Irish Freemasonry in South Africa</h3>
                            <button className="history-modal-close-button" onClick={closeModal}>
                                &times;
                            </button>
                        </div>
                        <div className="history-modal-body">
                            <HistoryOfIrishFreemasonry />
                        </div>
                    </div>
                </div>
            )}

            {/* Dedicated History Modal for Connaught Lodge */}
            {modalOpen && activeModal === "connaught" && (
                <div className="history-modal-overlay" onClick={closeModal}>
                    <div
                        className="history-modal-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="history-modal-header">
                            <h3>History of Connaught Lodge No. 361</h3>
                            <button className="history-modal-close-button" onClick={closeModal}>
                                &times;
                            </button>
                        </div>
                        <div className="history-modal-body">
                            <HistoryOfConnaughtLodge />
                        </div>
                    </div>
                </div>
            )}

            {/* Generic Modal */}
            {modalOpen && activeModal === "generic" && (
                <div className="modal-overlay-unique" onClick={closeModal}>
                    <div
                        className="modal-content-unique"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header-unique">
                            <h3>{modalContent.title}</h3>
                            <button className="close-button-unique" onClick={closeModal}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body-unique">
                            <p>{modalContent.text}</p>
                            {modalContent.image && (
                                <img
                                    src={modalContent.image}
                                    alt={`${modalContent.title}`}
                                    className="modal-image-unique"
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AboutPage;
