import React, {useEffect, useState} from "react";
import axios from "axios";
import "./BuildingDetailsModal.css";
import Select from "react-select"; // Import react-select
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
// Fix missing marker issue in Leaflet
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

L.Marker.prototype.options.icon = defaultIcon;

function BuildingDetailsModal({onClose, onSave, onDelete}) {
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [errors, setErrors] = useState({});
    const [showMap, setShowMap] = useState(false);
    // const [imageFile, setImageFile] = useState(null); // Single image file
    // const [imageCaption, setImageCaption] = useState(""); // Single image caption
    // Arrays for existing images and captions
    const [imagePaths, setImagePaths] = useState([]);
    const [imageCaptions, setImageCaptions] = useState([]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

// States for currently uploading a new image
    const [newImageFile, setNewImageFile] = useState(null);
    const [newImageCaption, setNewImageCaption] = useState("");

    // Arrays for new images to upload
    const [newImageFiles, setNewImageFiles] = useState([]);
    const [newImageCaptions, setNewImageCaptions] = useState([]);

    // Dynamic date filtering
    const [dateFilter, setDateFilter] = useState(""); // Store a YYYY-MM-DD or year
    const [minYear, setMinYear] = useState(1900);
    const [maxYear, setMaxYear] = useState(2024);

    // Fetch buildings on component mount

    // Fetch buildings on component mount
    useEffect(() => {
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const res = await axios.get("/api/buildings");
            setBuildings(res.data);
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };
    // Once buildings are fetched, determine minYear and maxYear from establishment dates
    useEffect(() => {
        if (buildings.length > 0) {
            const years = buildings
                .filter((b) => b.established_date)
                .map((b) => new Date(b.established_date).getFullYear());

            if (years.length > 0) {
                const dynamicMinYear = Math.min(...years);
                const dynamicMaxYear = Math.max(...years);
                setMinYear(dynamicMinYear);
                setMaxYear(dynamicMaxYear);
            } else {
                // No established dates found, fallback
                setMinYear(1900);
                setMaxYear(2024);
            }
        }
    }, [buildings]);

    // Utility function to convert UTC to SAST
    const convertToSAST = (utcDate) => {
        if (!utcDate) return "";
        const date = new Date(utcDate);
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    };



    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });

        // Clear errors when the field is modified
        if (errors[name]) {
            setErrors((prevErrors) => ({...prevErrors, [name]: null}));
        }
    };

    const validateForm = () => {
        const requiredFields = [
            "name",
            "street",
            "city",
            "country",
            "latitude",
            "longitude",
        ];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field].toString().trim() === "") {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
    };


    // const handleSave = async () => {
    //     if (!validateForm()) {
    //         alert("Please fill in all required fields.");
    //         return;
    //     }
    //
    //     try {
    //         const payload = {
    //             ...formData,
    //             established_date: formData.established_date || null,
    //             decommission_date: formData.decommission_date || null,
    //             document_ids: formData.document_ids || [],
    //         };
    //
    //         if (selectedBuilding) {
    //             await axios.put(`/api/buildings/${selectedBuilding.id}`, payload);
    //         } else {
    //             await axios.post("/api/buildings", payload);
    //         }
    //
    //         fetchBuildings();
    //         alert("Building saved successfully!");
    //         if (typeof onSave === "function") onSave();
    //     } catch (error) {
    //         console.error("Error saving building:", error);
    //         alert("Error saving building. Please try again.");
    //     }
    // };

    // const handleSave = async () => {
    //     if (!validateForm()) {
    //         alert("Please fill in all required fields.");
    //         return;
    //     }
    //
    //     try {
    //         let uploadedPaths = [];
    //
    //         // Upload files to the server
    //         if (imageFiles.length > 0) {
    //             const formDataForUpload = new FormData();
    //             imageFiles.forEach((file) => formDataForUpload.append("images", file));
    //
    //             const uploadRes = await axios.post("/api/upload-images-building", formDataForUpload, {
    //                 headers: { "Content-Type": "multipart/form-data" },
    //             });
    //             uploadedPaths = uploadRes.data.paths; // Server returns image paths
    //         }
    //
    //         // Prepare payload
    //         const payload = {
    //             ...formData,
    //             established_date: formData.established_date || null,
    //             decommission_date: formData.decommission_date || null,
    //             document_ids: formData.document_ids || [],
    //             image_paths: uploadedPaths, // Uploaded image paths
    //             image_captions: imageCaptions, // Captions for the images
    //         };
    //
    //         // Save or update building
    //         if (selectedBuilding) {
    //             await axios.put(`/api/buildings/${selectedBuilding.id}`, payload);
    //         } else {
    //             await axios.post("/api/buildings", payload);
    //         }
    //
    //         fetchBuildings();
    //         alert("Building saved successfully!");
    //         if (typeof onSave === "function") onSave();
    //     } catch (error) {
    //         console.error("Error saving building:", error);
    //         alert("Error saving building. Please try again.");
    //     }
    // };

    const handleBuildingSelect = async (id) => {
        if (id === "new") {
            setSelectedBuilding(null);
            setFormData({});
            setIsAddingNew(true);
            setImagePaths([]);
            setImageCaptions([]);
            setCurrentImageIndex(0);
            setNewImageFiles([]);
            setNewImageCaptions([]);
            return;
        }

        setLoading(true);
        setIsAddingNew(false);

        try {
            const res = await axios.get(`/api/buildings/${id}`);
            const building = res.data;

            const transformedBuilding = {
                ...building,
                established_date: building.established_date ? convertToSAST(building.established_date) : null,
                decommission_date: building.decommission_date ? convertToSAST(building.decommission_date) : null,
            };

            setSelectedBuilding(transformedBuilding);
            setFormData(transformedBuilding);
            setImagePaths(building.image_paths || []);
            setImageCaptions(building.image_captions || []);
            setCurrentImageIndex(0);
            setNewImageFiles([]);
            setNewImageCaptions([]);
        } catch (error) {
            console.error("Error fetching building details:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setNewImageFile(file); // Use newImageFile state instead of imageFile
        }
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            // Convert empty dates to null
            const processedData = { ...formData };
            if (processedData.established_date === "") processedData.established_date = null;
            if (processedData.decommission_date === "") processedData.decommission_date = null;

            let buildingId;

            const payload = {
                ...processedData,
                image_paths: imagePaths,
                image_captions: imageCaptions
            };

            let res;
            if (selectedBuilding) {
                res = await axios.put(`/api/buildings/${selectedBuilding.id}`, payload);
            } else {
                res = await axios.post("/api/buildings", payload);
            }
            buildingId = res.data.id;

            // If new images are added, upload them one by one
            for (let i = 0; i < newImageFiles.length; i++) {
                const file = newImageFiles[i];
                const caption = newImageCaptions[i] || "";

                const uploadData = new FormData();
                uploadData.append("building_id", buildingId);
                uploadData.append("image", file);
                uploadData.append("caption", caption);

                const uploadRes = await axios.post("/api/upload-image-building", uploadData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                setImagePaths(uploadRes.data.imagePaths);
                setImageCaptions(uploadRes.data.imageCaptions);
            }

            // Reset new uploads
            setNewImageFiles([]);
            setNewImageCaptions([]);
            setCurrentImageIndex(imagePaths.length - 1);

            alert("Building saved successfully!");
            fetchBuildings();
        } catch (error) {
            console.error("Error saving building:", error);
            alert("Failed to save building.");
        }
    };

    const handleDelete = async () => {
        if (!selectedBuilding) return;
        if (window.confirm("Are you sure you want to delete this building?")) {
            try {
                await axios.delete(`/api/buildings/${selectedBuilding.id}`);
                fetchBuildings();
                setSelectedBuilding(null);
                setFormData({});
                alert("Building deleted successfully!");
                if (typeof onDelete === "function") onDelete();
            } catch (error) {
                console.error("Error deleting building:", error);
                alert("Error deleting building. Please try again.");
            }
        }
    };

    // Utility function to convert UTC to SAST and format as YYYY-MM-DD
    const formatToSAST = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };

    function getStatusColor(status) {
        return status === "Active" ? "green" : "red";
    }

    function createCircleIcon(color) {
        return L.divIcon({
            html: `<div style="
          width: 12px;
          height: 12px;
          background-color: ${color};
          border-radius: 50%;
          border: 2px solid white;
          box-shadow: 0 0 2px rgba(0,0,0,0.5);
        "></div>`,
            className: "",
            iconAnchor: [6, 6]
        });
    }

    // Filter buildings before rendering markers
    const filteredBuildings = buildings.filter((b) => {
        if (!dateFilter) return true;
        if (!b.established_date) return false;
        const estYear = new Date(b.established_date).getFullYear();
        return estYear >= parseInt(dateFilter, 10);
    });

    // Convert UTC->SAST + remove trailing "Z"
    function formatSASTDisplay(dateString) {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        date.setHours(date.getHours() + 2); // +2 offset
        return date.toISOString().split("T")[0];
    }

    // Create marker popup content
    function createPopupContent(building) {
        const est = building.established_date ? formatSASTDisplay(building.established_date) : "N/A";
        const dec = building.decommission_date ? formatSASTDisplay(building.decommission_date) : "N/A";

        if (!building.image_paths || building.image_paths.length === 0) {
            return `
                <strong>${building.name}</strong><br/>
                Address: ${building.street || building.city || "N/A"}<br/>
                Status: ${building.status || "N/A"}<br/>
                Established: ${est}<br/>
                Decommissioned: ${dec}<br/>
                <em>No Images</em>
            `;
        }

        const imageHtml = building.image_paths.map((path, i) => `
            <div style="margin-bottom: 5px;">
                <img src="${path}" alt="Building Image" style="width: 100px; display: block;"/>
                <small>${(building.image_captions && building.image_captions[i]) || "No caption"}</small>
            </div>
        `).join("");

        return `
            <strong>${building.name}</strong><br/>
            Address: ${building.street || building.city || "N/A"}<br/>
            Status: ${building.status || "N/A"}<br/>
            Established: ${est}<br/>
            Decommissioned: ${dec}<br/>
            ${imageHtml}
        `;
    }

    return (
        <div className="building-modal-overlay">
            <div className="building-modal-content">
                <button className="building-close-btn" onClick={onClose}>
                    &times;
                </button>
                <h2>{isAddingNew ? "Add New Building" : "Edit Building"}</h2>

                {/* Show Map Button */}
                <button
                    className="building-map-btn"
                    onClick={() => setShowMap(true)}
                >
                    Show Map
                </button>
                {/*console.log("Show Map clicked:", showMap);*/}

                {/* Dropdown to select a building */}
                <div className="building-form-group">
                    <label>Select Building</label>
                    <Select
                        options={[
                            {value: "new", label: "Add New Building"}, // "Add New" option
                            ...buildings.map((building) => ({
                                value: building.id,
                                label: building.name,
                            })),
                        ]}
                        onChange={(selectedOption) =>
                            handleBuildingSelect(selectedOption ? selectedOption.value : null)
                        }
                        value={
                            isAddingNew
                                ? {value: "new", label: "Add New Building"}
                                : selectedBuilding
                                ? {value: selectedBuilding.id, label: selectedBuilding.name}
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Choose or Add New Building"
                    />
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="building-form-container">
                        {/* Loop through all fields */}
                        {[
                            "name",
                            "primary_contact",
                            "street",
                            "phone_number",
                            "city",
                            "county",
                            "postcode",
                            "status",
                            "capacity",
                            // "architect_name",
                            "land_area",
                            "building_type",
                            "historical_status",
                            "primary_usage",
                            "ownership_status",
                            "maintenance_contact",
                            "country",
                            "latitude",
                            "longitude",
                            "description",
                            "established_date",
                            "decommission_date",
                            "builder", // New field
                            "consecrated_by", // New field
                            "architect", // New field
                        ].map((key) => (
                            <div className="building-form-group" key={key}>
                                <label>{key.replace(/_/g, " ")}</label>
                                {key === "historical_status" ? (
                                    <input
                                        type="checkbox"
                                        name={key}
                                        checked={formData[key] || false}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                ) : key === "established_date" || key === "decommission_date" ? (
                                    <input
                                        type="date"
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                ) : (
                                    <input
                                        type={
                                            key === "land_area" ||
                                            key === "capacity" ||
                                            key === "latitude" ||
                                            key === "longitude"
                                                ? "number"
                                                : "text"
                                        }
                                        step={
                                            key === "latitude" || key === "longitude"
                                                ? "0.000001"
                                                : undefined
                                        }
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                )}
                                {errors[key] && <p className="error-message">{errors[key]}</p>}
                            </div>
                        ))}

                        {/* Document IDs */}
                        <div className="building-form-group">
                            <label>Document IDs (Comma-separated)</label>
                            <input
                                type="text"
                                name="document_ids"
                                value={(formData.document_ids || []).join(", ")}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        document_ids: e.target.value
                                            .split(",")
                                            .map((id) => id.trim()),
                                    })
                                }
                            />
                        </div>
                        <div className="building-form-group">
                            <label>Upload New Images</label>
                            <input
                                type="file"
                                accept="image/*"
                                multiple
                                onChange={(e) => {
                                    const files = Array.from(e.target.files);
                                    setNewImageFiles(files); // Store all selected files
                                    // You may reset newImageCaptions here or handle separately.
                                    setNewImageCaptions(new Array(files.length).fill(""));
                                }}
                            />
                        </div>

                        <div className="building-form-group">
                            <label>Captions for New Images (one per line)</label>
                            <textarea
                                placeholder="Enter captions, one per line, matching the order of selected images"
                                value={newImageCaptions.join("\n")}
                                onChange={(e) => {
                                    const lines = e.target.value.split("\n");
                                    // Ensure captions array matches number of files
                                    const updatedCaptions = lines.slice(0, newImageFiles.length);
                                    // If fewer lines than files, fill with empty strings
                                    while (updatedCaptions.length < newImageFiles.length) {
                                        updatedCaptions.push("");
                                    }
                                    setNewImageCaptions(updatedCaptions);
                                }}
                            />
                        </div>

                        {imagePaths.length > 0 && (
                            <div className="image-preview-container">
                                <h4>Image Preview:</h4>
                                <div>
                                    <img
                                        src={imagePaths[currentImageIndex]}
                                        alt="Image Preview"
                                        style={{ width: "250px", margin: "5px" }}
                                    />
                                    <p>{imageCaptions[currentImageIndex] || "No caption"}</p>
                                </div>
                                <div className="image-navigation">
                                    <button onClick={() =>
                                        setCurrentImageIndex((prev) => prev > 0 ? prev - 1 : imagePaths.length - 1)
                                    }>
                                        &lt; Prev
                                    </button>
                                    <button onClick={() =>
                                        setCurrentImageIndex((prev) => (prev + 1) % imagePaths.length)
                                    }>
                                        Next &gt;
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                )}

                <div className="building-modal-actions">
                    {selectedBuilding && (
                        <button className="building-delete-btn" onClick={handleDelete}>
                            Delete
                        </button>
                    )}
                    <button className="building-save-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button className="building-cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>

                {/* Map Popup Modal */}
                {showMap && (
                    <div className="map-modal-overlay">
                        <div className="map-modal-content">
                            <button className="map-close-btn" onClick={() => setShowMap(false)}>
                                &times;
                            </button>
                            <h2>Building Locations</h2>

                            <div className="date-filter">
                                <label>Filter by Established Year:</label>
                                <input
                                    type="range"
                                    min={minYear}
                                    max={maxYear}
                                    value={dateFilter || minYear.toString()}
                                    onChange={(e) => setDateFilter(e.target.value)}
                                />
                                <span>{dateFilter || minYear}</span>
                            </div>

                            <MapContainer
                                center={[-25.7461, 28.1881]}
                                zoom={5}
                                style={{ height: "100%", width: "100%" }}
                                scrollWheelZoom={true}
                                zoomAnimation={true}
                                zoomControl={true}
                            >
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                />

                                {buildings.filter((b) => {
                                    if (!dateFilter) return true;
                                    if (!b.established_date) return false;
                                    const estYear = new Date(b.established_date).getFullYear();
                                    return estYear >= parseInt(dateFilter, 10);
                                }).map((building) => {
                                    if (!building.latitude || !building.longitude) return null;
                                    const color = getStatusColor(building.status);
                                    const icon = createCircleIcon(color);

                                    return (
                                        <Marker
                                            key={building.id}
                                            position={[building.latitude, building.longitude]}
                                            icon={icon}
                                        >
                                            <Tooltip>{building.name}</Tooltip>
                                            <Popup>
                                                <div dangerouslySetInnerHTML={{ __html: createPopupContent(building) }} />
                                            </Popup>
                                        </Marker>
                                    );
                                })}
                            </MapContainer>
                        </div>
                    </div>
                )}

            </div>

        </div>
    );
}

export default BuildingDetailsModal;
