import React, { useEffect, useState } from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import "./AnalyticsPage.css";

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

function AnalyticsPage() {
    const [dailyViews, setDailyViews] = useState([]);
    const [interactionTypes, setInteractionTypes] = useState([]);
    const [requestPaths, setRequestPaths] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        fetch("/api/v1/analytics/user-interactions")
            .then((res) => res.json())
            .then((data) => {
                setDailyViews(data.dailyPageViews || []);
                setInteractionTypes(data.topInteractionTypes || []);
                setRequestPaths(data.topRequestPaths || []);
            })
            .catch((err) => console.error("Error fetching analytics:", err))
            .finally(() => setLoading(false));
    }, []);

    // 1) Line chart data (Daily Page Views)
    const lineChartData = {
        labels: dailyViews.map((item) => item.date),
        datasets: [
            {
                label: "Page Views",
                data: dailyViews.map((item) => item.count),
                fill: false,
                borderColor: "rgba(75,192,192,1)",
                tension: 0.2
            },
        ],
    };

    // 2) Pie chart data (Top Interaction Types)
    const pieChartData = {
        labels: interactionTypes.map((item) => item.type),
        datasets: [
            {
                label: "Interactions",
                data: interactionTypes.map((item) => item.count),
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                    "#FF9F40",
                ],
            },
        ],
    };

    // 3) Bar chart data (Top Request Paths)
    const barChartData = {
        labels: requestPaths.map((item) => item.path),
        datasets: [
            {
                label: "Requests",
                data: requestPaths.map((item) => item.count),
                backgroundColor: "rgba(153,102,255,0.6)",
            },
        ],
    };

    // Chart Options
    // - maintainAspectRatio: false => allows resizing
    // - aspectRatio: smaller => makes chart less tall
    const lineChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: "Daily Page Views" },
        },
    };

    const pieChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        plugins: {
            legend: { position: "bottom" },
            title: { display: true, text: "Interaction Types" },
        },
    };

    const barChartOptions = {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.5,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: "Top Request Paths" },
        },
    };

    return (
        <div className="analytics-page">
            <h1>User Interactions Analytics</h1>
            {loading && <p>Loading analytics data...</p>}

            {!loading && (
                <>
                    {/* LINE CHART for daily views */}
                    <div className="chart-container line-chart">
                        <div className="chart-wrapper">
                            <Line data={lineChartData} options={lineChartOptions} />
                        </div>
                    </div>

                    {/* PIE CHART for interaction types */}
                    <div className="chart-container pie-chart">
                        <div className="chart-wrapper">
                            <Pie data={pieChartData} options={pieChartOptions} />
                        </div>
                    </div>

                    {/* BAR CHART for top request paths */}
                    <div className="chart-container bar-chart">
                        <div className="chart-wrapper">
                            <Bar data={barChartData} options={barChartOptions} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default AnalyticsPage;
