// HistoryOfConnaughtLodgeLodge.js
import React from "react";
import "./HistoryOfConnaught.css"; // (Optional) Dedicated styling

function HistoryOfConnaughtLodge() {
    return (
        <div className="history-connaught-container">
            <h2>Brief History of Connaught Lodge No. 361 I.C.</h2>

            <p>
                Connaught Lodge No. 361 I.C. emerged in 1903, at a time of renewed Irish
                Masonic vigor following the Anglo-Boer War. Influenced by figures like
                Dr. Kendal Franks, the lodge soon became a center for historical study,
                ritual analysis, and cultural understanding. Over time, it shifted from
                routine lodge activities to a research-focused approach, fostering a
                deeper engagement with Freemasonry’s values, traditions, and history.
            </p>

            <p>
                Today, Connaught Lodge serves as a hub for members who seek to connect
                Masonic practice with documented scholarship, ensuring that principles
                are well-understood and relevant in changing times.
            </p>

            <p>
                {/* Additional paragraphs, images, etc. can go here. */}
            </p>
        </div>
    );
}

export default HistoryOfConnaughtLodge;
