// src/components/StatsLodges.jsx

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import "./StatsLodges.css";

function StatsLodges({ onClose }) {
    const [statsData, setStatsData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Chart references
    const statusPieRef = useRef(null);
    const constitutionBarRef = useRef(null);
    const cumulativeLineRef = useRef(null); // New reference for cumulative chart

    // Chart instances
    const statusPieChart = useRef(null);
    const constitutionBarChart = useRef(null);
    const cumulativeLineChart = useRef(null); // New instance for cumulative chart

    useEffect(() => {
        fetchStats();
        return () => {
            // Cleanup old instances
            if (statusPieChart.current) statusPieChart.current.destroy();
            if (constitutionBarChart.current) constitutionBarChart.current.destroy();
            if (cumulativeLineChart.current) cumulativeLineChart.current.destroy(); // Cleanup cumulative chart
        };
    }, []);

    const fetchStats = async () => {
        try {
            setLoading(true);
            setError(null);

            const res = await axios.get("/api/v1/stats/lodges");
            setStatsData(res.data);
        } catch (err) {
            console.error("Error fetching lodge stats:", err);
            setError("Failed to load lodge statistics. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!statsData) return;

        buildStatusPie();
        buildConstitutionBar();
        buildCumulativeLineChart();
    }, [statsData]);

    /* -------------- BUILD STATUS PIE CHART -------------- */
    const buildStatusPie = () => {
        if (statusPieChart.current) {
            statusPieChart.current.destroy();
        }
        const { byStatus } = statsData;
        if (!byStatus || byStatus.length === 0) return;

        const labels = byStatus.map((item) => item.status || "Unknown");
        const data = byStatus.map((item) => item.count);

        statusPieChart.current = new Chart(statusPieRef.current, {
            type: "pie",
            data: {
                labels,
                datasets: [
                    {
                        label: "Lodge Status",
                        data,
                        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56", "#9966FF", "#FF9F40"]
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: { display: true, text: "Breakdown by Status" },
                    legend: { position: "bottom" },
                },
            },
        });
    };

    /* -------------- BUILD CONSTITUTION BAR -------------- */
    const buildConstitutionBar = () => {
        if (constitutionBarChart.current) {
            constitutionBarChart.current.destroy();
        }
        const { byConstitution } = statsData;
        if (!byConstitution || byConstitution.length === 0) return;

        const labels = byConstitution.map((item) => item.constitution || "Unknown");
        const data = byConstitution.map((item) => item.count);

        constitutionBarChart.current = new Chart(constitutionBarRef.current, {
            type: "bar",
            data: {
                labels,
                datasets: [
                    {
                        label: "Constitution Count",
                        data,
                        backgroundColor: "rgba(54,162,235,0.7)"
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: { display: true, text: "Constitution" },
                    },
                    y: {
                        beginAtZero: true,
                        title: { display: true, text: "Number of Lodges" },
                    },
                },
                plugins: {
                    title: {
                        display: true,
                        text: "Breakdown by Constitution",
                    },
                    legend: { display: false },
                },
            },
        });
    };

    /* -------------- BUILD CUMULATIVE LINE CHART -------------- */
    const buildCumulativeLineChart = () => {
        if (cumulativeLineChart.current) {
            cumulativeLineChart.current.destroy();
        }

        const { yearEstablished, yearDecommissioned } = statsData;
        if ((!yearEstablished || yearEstablished.length === 0) && (!yearDecommissioned || yearDecommissioned.length === 0)) return;

        // Combine both yearEstablished and yearDecommissioned to find the full range of years
        const establishedYears = yearEstablished.map(item => parseInt(item.year, 10));
        const decommissionedYears = yearDecommissioned.map(item => parseInt(item.year, 10));
        const allYears = [...establishedYears, ...decommissionedYears];
        const minYear = Math.min(...allYears);
        const maxYear = Math.max(...allYears);

        // Generate a complete list of years
        const completeYearRange = [];
        for (let yr = minYear; yr <= maxYear; yr++) {
            completeYearRange.push(yr.toString());
        }

        // Create a map for established and decommissioned counts
        const establishedMap = {};
        yearEstablished.forEach(item => {
            establishedMap[item.year] = item.count;
        });

        const decommissionedMap = {};
        yearDecommissioned.forEach(item => {
            decommissionedMap[item.year] = item.count;
        });

        // Compute cumulative counts and actual lodge count
        let cumulativeEstablished = 0;
        let cumulativeDecommissioned = 0;

        const cumulativeEstablishedData = [];
        const cumulativeDecommissionedData = [];
        const actualLodgeCountData = []; // New dataset for actual lodge count

        completeYearRange.forEach(year => {
            cumulativeEstablished = establishedMap[year] || 0;
            cumulativeDecommissioned = decommissionedMap[year] || 0;

            cumulativeEstablishedData.push(cumulativeEstablished);
            cumulativeDecommissionedData.push(cumulativeDecommissioned);

            // Calculate the actual lodge count for the year
            const actualLodgeCount = cumulativeEstablished - cumulativeDecommissioned;
            actualLodgeCountData.push(actualLodgeCount);
        });

        cumulativeLineChart.current = new Chart(cumulativeLineRef.current, {
            type: "line",
            data: {
                labels: completeYearRange,
                datasets: [
                    {
                        label: "Cumulative Lodges Established",
                        data: cumulativeEstablishedData,
                        borderColor: "rgba(255,159,64,1)",
                        backgroundColor: "rgba(255,159,64,0.2)",
                        fill: true,
                        tension: 0.2,
                    },
                    {
                        label: "Cumulative Lodges Decommissioned",
                        data: cumulativeDecommissionedData,
                        borderColor: "rgba(153,102,255,1)",
                        backgroundColor: "rgba(153,102,255,0.2)",
                        fill: true,
                        tension: 0.2,
                    },
                    {
                        label: "Actual Lodge Count",
                        data: actualLodgeCountData,
                        borderColor: "rgba(75,192,192,1)",
                        backgroundColor: "rgba(75,192,192,0.2)",
                        fill: true,
                        tension: 0.2,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: { display: true, text: "Year" },
                    },
                    y: {
                        beginAtZero: true,
                        title: { display: true, text: "Number of Lodges" },
                    },
                },
                plugins: {
                    title: { display: true, text: "Cumulative and Actual Lodge Counts" },
                    legend: { position: "top" },
                },
            },
        });
    };

    return (
        <div className="lodges-stats-overlay">
            <div className="lodges-stats-content">
                <div className="lodges-stats-header">
                    <h2>Lodge Statistics</h2>
                    <button className="close-btn" onClick={onClose} aria-label="Close Stats Modal">
                        &times;
                    </button>
                </div>

                <div className="lodges-stats-body">
                    {loading && <div className="loading-spinner">Loading lodge stats...</div>}
                    {error && <div className="error-message">{error}</div>}

                    {statsData && !loading && !error && (
                        <>
                            {/* TOP SUMMARY CARDS */}
                            <div className="stats-cards-row">
                                <div className="stats-card">
                                    <h3>Total Lodges</h3>
                                    <p>{statsData.totalLodges}</p>
                                </div>

                                <div className="stats-card">
                                    <h3>Total Location Moves</h3>
                                    <p>{statsData.totalLocationMoves}</p>
                                </div>
                            </div>

                            {/* CHART ROW 1: STATUS + CONSTITUTION */}
                            <div className="chart-row">
                                <div className="chart-container">
                                    <canvas ref={statusPieRef}></canvas>
                                </div>
                                <div className="chart-container">
                                    <canvas ref={constitutionBarRef}></canvas>
                                </div>
                            </div>

                            {/* CUMULATIVE YEAR EST + DECOM LINE CHART */}
                            <div className="chart-row">
                                <div className="chart-container-full">
                                    <canvas ref={cumulativeLineRef}></canvas>
                                </div>
                            </div>

                            {/* TABLE: Location moves by lodge */}
                            <div className="location-moves-section">
                                <h3>Location Moves by Lodge</h3>
                                {statsData.locationMovesByLodge && statsData.locationMovesByLodge.length > 0 ? (
                                    <table className="simple-table">
                                        <thead>
                                        <tr>
                                            <th>Lodge Name</th>
                                            <th>Moves</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {statsData.locationMovesByLodge.map((row, idx) => (
                                            <tr key={idx}>
                                                <td>{row.lodge_name}</td>
                                                <td>{row.moves}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No location move data available.</p>
                                )}
                            </div>
                        </>
                    )}
                </div>

                <div className="lodges-stats-footer">
                    <button className="close-btn" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default StatsLodges;
